import   React                       ,  
       { useState                    ,
	     useEffect                   }  from 'react';
		 
import { useNavigate                 }  from 'react-router-dom';
		 
import { Dispatch                    }  from 'redux';
import { useDispatch                 }  from 'react-redux';
		 
import   classNames                     from 'classnames';

import   styles                         from './connection_informations.module.scss';

import { Window                      }  from '../../../../widget/window/window';
import { WidgetFrame                 }  from '../../../../widget/widget_frame/widget_frame';

import { useTypedSelector            }  from '../../../../../datas/useTypeSelector';

import { getUserEmailResetRequest    ,
         getUserPasswordResetRequest }  from '../../../../../network/api_user';

import { initAuthentication          }  from '../../../../../datas/authenticationManagment/actionCreators';
import { initUser            }  from '../../../../../datas/userManagment/actionCreators';

import { INTERNAL_LINKS              }  from '../../../../../network/urls';

export const ConnectionInformations = () => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
    
  const [ showWindow            , setShowWindow         ] = useState ( false );
  const [ windowMessage         , setWindowMessage      ] = useState ( <></> );
  const [ expiredSession , setExpiredSession ] = useState ( false );

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  const switchWidthLimit = 870;

  const handleResize = () => {
    setIsSmallScreen ( window.innerWidth <= switchWidthLimit );
  };

  useEffect(() => {

    if ( authentication.login === "" || 
         authentication.token === "" )
     {
	  dispatch ( initUser () )

      navigate ("/connexion");
     }

  }, [isSmallScreen]); 


  useEffect(() => {

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
	
  }, []); 
  
  const handleEmailResetRequestButtonClick = () => {

    makeEmailResetRequest ();   
  }

  const handlePasswordResetRequestButtonClick = () => {

    makePasswordResetRequest ();   
  }

  const makeEmailResetRequest = async () => {
 
    const response = await getUserEmailResetRequest ( authentication.login ,
	                                                  authentication.token )
													  
	switch ( response.code )
     {	
	  case 1    : setWindowMessage ( <>La demande a été envoyée, vous allez recevoir un message contenant un lien vous permettant de modifier votre adresse email.</>);
	              setShowWindow    (true)
	     
		          break;
			   
	  case -102 : setWindowMessage ( <>Votre session a expiré, veuillez vous reconnecter.</>);
	              setShowWindow    (true)

  	              setExpiredSession (true);
	     
		          break;
				  
	  default :   setWindowMessage ( <>Une erreur réseau s'est produite lors de votre demande</> );
	              setShowWindow    (true)
	 }
   }

  const makePasswordResetRequest = async () => {
 
    const response = await getUserPasswordResetRequest ( authentication.login )
	
	switch ( response.code )
     {	
	  case 1    : setWindowMessage (<>La demande a été envoyée, vous allez recevoir un message contenant un lien vous permettant de modifier votre mot de passe.</>);
	              setShowWindow    (true)
	  
	              break;
				  
	  case -102 : setWindowMessage (<>Votre session a expiré, veuillez vous reconnecter.</>);
	              setShowWindow    (true)

  	              setExpiredSession (true);
	     
		          break;
				  
	  default :   setWindowMessage (<>Une erreur réseau s'est produite lors de votre demande</>);
	              setShowWindow    (true)

	 }
   }

  const handleCloseButtonClick = () => {

    setWindowMessage (<></>); 
	setShowWindow    (false)

    if ( expiredSession )
     {
	  dispatch ( initUser   () );
	  dispatch ( initAuthentication (true) );
      navigate ( `${INTERNAL_LINKS.Home}` );
	 }		
  }


  const renderSmallSizeComponent = (): JSX.Element => {

    return  <>
	
		     <p className = { classNames(styles.title) } >
               IDENTIFIANT DE CONNEXION
             </p>
              
			 <br/>
			  
             <div className = { classNames(styles.informations) } >
			  
               <div className = { classNames(styles.layout_2_line_1) } >

                  	 <WidgetFrame name               = "Adresse email"
			                      labelTextPosition  = "left"
								  labelBlockPosition = "top" 
								  classNameElement   = { classNames(styles.yellow_text) }>

                       { authentication.login }

                     </WidgetFrame>

               </div>
				 
               <div className={classNames(styles.layout_2_line_2)}>

				{/* 
				<button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                        onClick   = {handleEmailResetRequestButtonClick}>
                   Demander la modification de l'adresse email
                </button>
                */}

               </div>

               <div className = { classNames(styles.layout_2_line_3) } >
               
			   	 <WidgetFrame name               = "Mot de passe"
                 			  labelTextPosition  = "left"
							  labelBlockPosition = "top"  >

                   <div className = { classNames(styles.yellow_text) } >{"**********"}</div>

                 </WidgetFrame> 

               </div>

               <div className={classNames(styles.layout_2_line_4)}>

                 {/*
			     <Trigger  classNameNormal = { classNames(styles.button) }
                           name            = "Demander la modification du mot de passe"
				           onClick         = { handlePasswordResetRequestButtonClick } />
				 */}
				 
               <div className={classNames(styles.buttons_block)}>
				<button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                        onClick   = {handlePasswordResetRequestButtonClick}>
                   Demander la modification<br/>du mot de passe
                </button>
               </div>


               </div>
			  
             </div>

	        </>
  }
  
  const renderBigSizeComponent = (): JSX.Element => {

    return  <>
	
	         <p className = { classNames(styles.title) } >
               IDENTIFIANT DE CONNEXION
             </p>
              
			 <br/>
			  
             <div className = { classNames(styles.informations) } >
			  
               <div className = { classNames(styles.layout_1_line_1_1) } >

                  	 <WidgetFrame name          = "Adresse email"
			                      classNameGrid = {classNames(styles.grid_info)} >

                   { authentication.login }

                 </WidgetFrame>

               </div>
				 
               <div className={classNames(styles.layout_1_line_1_2)}>

                {/*
				<button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                        onClick   = {handleEmailResetRequestButtonClick}>
                   Demander la modification de l'adresse email
                </button>
                */}
				
               </div>

               <div className = { classNames(styles.layout_1_line_2_1) } >
               
			   	 <WidgetFrame name          = "Mot de passe"
                 			  classNameGrid = {classNames(styles.grid_info)} >

                   {"**********"}

                 </WidgetFrame>

               </div>

               <div className={classNames(styles.layout_1_line_2_2)}>

				<button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                        onClick   = {handlePasswordResetRequestButtonClick}>
                   Demander la modification du mot de passe
                </button>

               </div>
			  
             </div>
 
            </>
     
  }

  return (
            
           <div className = { classNames(styles.root) } >

             {
			    showWindow  &&
                (
				  < Window message              = { windowMessage          }
				           onClickOnCloseButton = { handleCloseButtonClick } />
				)				
		     }
			   
			 { 
			  ( isSmallScreen ? renderSmallSizeComponent() : renderBigSizeComponent () ) 
			 }
  

           </div>
           
         );
};


export default ConnectionInformations;
