import   React                    , 
       { useEffect                ,
	     useRef                   }  from 'react';

import { useNavigate              }  from 'react-router-dom';

import { Dispatch                 }  from 'redux';
import { useDispatch              }  from 'react-redux';
	   
import   ReactPlayer                 from 'react-player/lazy'

import   classNames                  from 'classnames';
import   screenfull                  from 'screenfull';

import   styles                      from './viewer.module.scss';

import { WWW_URLS                 }  from '../../../network/urls';

import { Video                    }  from '../../../datas/videoManagment/actionTypes';
import { setCurrentlyPlayingVideo }  from '../../../datas/videoManagment/actionCreators';

import { useTypedSelector         }  from '../../../datas/useTypeSelector';

import { postViewerNotification   }  from '../../../network/api_video';

import { postEvent                   }  from '../../../network/api_events_stats';


export const VideoViewer = () => {

  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();

  const { currentlyPlayingVideo } = useTypedSelector ( (state) => state.videoManagment );
  const { authentication }        = useTypedSelector((state) => state.authenticationManagment );

  let progressCount = 0;

  const playerRef = useRef<ReactPlayer>(null);

  var urlVideo: string|null = null;
  
  if ( typeof ( currentlyPlayingVideo.video ) !== 'undefined' )
   {
    if ( currentlyPlayingVideo.video !== null )
	 {
      urlVideo = `${WWW_URLS.Video}${currentlyPlayingVideo.video.videoFile}/index.m3u8`;
     }
   }
   
  useEffect(() => {

	console.log ("Viewer::useEffect : urlVideo = ",currentlyPlayingVideo,  urlVideo);
  
    if (screenfull.isEnabled) 
	      {
	       console.log ("Mode fullscreen automatique autorisé");
		   
		   const element = document.querySelector('#viewer');

           if (element) 
		         {
                  screenfull.request(element).catch((err) => {
        
		            console.error('Error attempting to enable fullscreen mode:', err);
                  });
                 } 
		    else {
                  console.error('Element #viewer not found');
                 }

          }
	 else {
	       console.log ("Mode fullscreen automatique non autorisé");
	      }
  }, []);
  
  useEffect(() => {

	postEvent ( "Ouverture de /video" , authentication.login );

  }, [] );
  
  const handleClick = () => {
   
    let currentTime: number = 0;
    let duration:    number = 0;
  
    if (playerRef.current)  
	 {
      currentTime = playerRef.current.getCurrentTime ();
      duration    = playerRef.current.getDuration    ();
     }
	
    if ( currentlyPlayingVideo !== null )
	 {
      if ( currentlyPlayingVideo.video !== null )
	   {
	    postViewerNotification ( currentlyPlayingVideo.video.id ,
		     					 "onBackButtonClick"            ,
			    				 currentTime                    ,
				    			 duration                       ,
								 authentication.login           )
       }
	 }
	 
	dispatch ( setCurrentlyPlayingVideo ( null ) );

    navigate(-1)
  }
  
  const handleStart = () => {

    let currentTime: number = 0;
    let duration:    number = 0;
  
    if (playerRef.current)  
	 {
      currentTime = playerRef.current.getCurrentTime ();
      duration    = playerRef.current.getDuration    ();
     }
	  
    if ( currentlyPlayingVideo !== null )
	 {
      if ( currentlyPlayingVideo.video !== null )
	   {
	    postViewerNotification ( currentlyPlayingVideo.video.id ,
		    					 "onStart"                      ,
			    				 currentTime                    ,
				    			 duration                       ,
								 authentication.login           )
       }
	 }
  }

  const handleEnded = () => {
  
    let currentTime: number = 0;
    let duration:    number = 0;
  
    if (playerRef.current)  
	 {
      currentTime = playerRef.current.getCurrentTime ();
      duration    = playerRef.current.getDuration    ();
     }
	  
    if ( currentlyPlayingVideo !== null )
	 {
      if ( currentlyPlayingVideo.video !== null )
	   {
	    postViewerNotification ( currentlyPlayingVideo.video.id ,
		    					 "onEnded"                      ,
			    				 currentTime                    ,
				    			 duration                       ,
								 authentication.login           )
       }
	 }
  }

  const handlePlay = () => {
  
    let currentTime: number = 0;
    let duration:    number = 0;
  
    if (playerRef.current)  
	 {
      currentTime = playerRef.current.getCurrentTime ();
      duration    = playerRef.current.getDuration    ();
     }
	  
    if ( currentlyPlayingVideo !== null )
	 {
      if ( currentlyPlayingVideo.video !== null )
	   {
    	postViewerNotification ( currentlyPlayingVideo.video.id ,
	    						 "onPlay"                       ,
		    					 currentTime                    ,
			    				 duration                       ,
								 authentication.login           )
       }
	 }
  }
  
  const handlePause = () => {
  
    let currentTime: number = 0;
    let duration:    number = 0;
  
    if (playerRef.current)  
	 {
      currentTime = playerRef.current.getCurrentTime ();
      duration    = playerRef.current.getDuration    ();
     }
	  
    if ( currentlyPlayingVideo !== null )
	 {
      if ( currentlyPlayingVideo.video !== null )
	   {
	    postViewerNotification ( currentlyPlayingVideo.video.id ,
		    					 "onPause"                      ,
			    				 currentTime                    ,
				    			 duration                       ,
								 authentication.login           )
       }
	 }
  }
  
  const handleProgress = (state: { played: number; playedSeconds: number }) => {

    let currentTime: number = 0;
    let duration:    number = 0;

    if (playerRef.current)
	 {
      currentTime = playerRef.current.getCurrentTime ();
      duration    = playerRef.current.getDuration    ();
     }

    if ( currentlyPlayingVideo !== null )
	 {
      if ( currentlyPlayingVideo.video !== null )
	   {
        if ( progressCount < 60 )
              {
               progressCount++;
              }
         else {
               progressCount = 0;

               postViewerNotification ( currentlyPlayingVideo.video.id                      ,
	   	    	   			            `onProgress,${state.playedSeconds},${state.played}` ,
                                        currentTime                                         ,
				    		            duration                                            ,
							            authentication.login                                )
              }
       }
	 }
  };

  return (
 
    <div id="viewer" className = {classNames(styles.root)} >

      <div className = {classNames(styles.back_button)} >

        <button onClick={handleClick}>Retour</button>

      </div>

      { 
	    (urlVideo !== null) 
		&&
        (
		 <ReactPlayer ref        = {playerRef}
                      url        = {urlVideo}
                      className  = {classNames(styles.player)}
                      controls   = {true}
                      playing    = {true}
                      width      = '100%'
                      height     = '100%' 
					  onStart    = {handleStart}
					  onEnded    = {handleEnded}
					  onPlay     = {handlePlay}
					  onPause    = {handlePause}
					  onProgress = {handleProgress} />
        )
      }
	  
	</div>
 
  );
  
};

export default VideoViewer;

