import   React       ,  
       { useEffect   ,
	     useState    }  from 'react';
		 
import   classNames     from 'classnames';

import   styles         from './window.module.scss';

import { Field       }  from '../field/field';
import { WidgetFrame }  from '../widget_frame/widget_frame';
import { Trigger     }  from '../trigger/trigger';

import { Validation ,
         checkEmail }  from '../../../datas/userManagment/validators';

export interface WindowWithFormProps {
  message:                 JSX.Element
  messageType?:            string
  onClickOnCancelButton?:  (() => void) | undefined;
  onClickOnValidButton?:   ((firstname: string, lastname: string, age: number, email: string, city: string) => void) | undefined;
  closeButtonName?:        string;
  cancelButtonName?:       string;
  validButtonName?:        string;
  stylesGridInfo:          string;
}

export const WindowWithForm = ({ message               ,
                                 messageType           ,
                                 onClickOnCancelButton ,
						         onClickOnValidButton  ,
						         closeButtonName       ,
                                 cancelButtonName      ,
                                 validButtonName       ,
							     stylesGridInfo        }: WindowWithFormProps) => {

  const [ firstname               , setFirstname              ] = useState ( "" );
  const [ lastname                , setLastname               ] = useState ( "" );
  const [ age                     , setAge                    ] = useState ( "" );
  const [ email                   , setEmail                  ] = useState ( "" );
  const [ city                    , setCity                   ] = useState ( "" );

  const [ firstname_errorMessage  , setFirstname_ErrorMessage ] = useState ( "" );
  const [ lastname_errorMessage   , setLastname_ErrorMessage  ] = useState ( "" );
  const [ age_errorMessage        , setAge_Message            ] = useState ( "" );
  const [ email_errorMessage      , setEmail_Message          ] = useState ( "" );
  const [ city_errorMessage       , setCity_ErrorMessage      ] = useState ( "" );

  const [ isSmallScreen           , setIsSmallScreen          ] = useState<boolean>(false);

  const handleResize = () => {
      setIsSmallScreen ( window.innerWidth <= 600 );
  };

  useEffect(() => {

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
	
  }, []);

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setEmail ( e.target.value )

    if ( emailRegex.test(e.target.value) )
          {
           setEmail_Message ("")
          }
     else {
           setEmail_Message ("La saisie est incorrecte")
          }
  };

  const handleFirstnameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    setFirstname ( e.target.value )
  }

  const handleLastnameChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    setLastname ( e.target.value )
  }

  const handleAgeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    const intValue = parseInt ( e.target.value , 10 );

    setAge ( e.target.value )

    if ( isNaN(intValue) )
          {
           setAge_Message ("La saisie est incorrecte")
          }
     else {
           setAge_Message ("")
          }
  }

  const handleCityChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    setCity ( e.target.value )
  }

  const handleCancelButtonClick = () => {

    if ( typeof (onClickOnCancelButton) !== "undefined" )
	 {
      onClickOnCancelButton ();
	 }
  };

  const handleValidButtonClick = () => {

    if ( typeof (onClickOnValidButton) !== "undefined" )
	 {
      if ( age_errorMessage === "" && email_errorMessage === "" )
       {
        const intValue = parseInt ( age , 10 );

        onClickOnValidButton ( firstname, lastname, intValue, email, city );
       }
	 }
  };

  const generateClassName = (): string => {
  
        if ( typeof ( messageType ) === "undefined" ) { return classNames(styles.WhiteMessage); }
   else if (          messageType   === "normal"    ) { return classNames(styles.WhiteMessage); }
   else if (          messageType   === "alert"     ) { return classNames(styles.RedMessage  ); }
   else if (          messageType   === "ack"       ) { return classNames(styles.GreenMessage); }
   else                                               { return classNames(styles.WhiteMessage); }
  }

  return (
            <div className= {classNames(styles.window)}>

              <div className = {classNames(generateClassName (),styles.message)}>{message}</div>

              <br/>

              <WidgetFrame name               = "Prénom"
                           error              = { firstname_errorMessage }
					       labelTextPosition  = { isSmallScreen ? "left" : "left" }
						   labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
						   classNameName      = { styles.frameLabel }
   						   classNameGrid      = { stylesGridInfo } >

			         <Field type           = "text"
                            placeholder    = ""
                            value          = {firstname}
                            onChange       = {handleFirstnameChange} />
                         
              </WidgetFrame>

              <div className={styles.interzone}>&nbsp;</div>

              <WidgetFrame name               = "Nom"
                           error              = { lastname_errorMessage }
					       labelTextPosition  = { isSmallScreen ? "left" : "left" }
						   labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
						   classNameName      = { styles.frameLabel }
   						   classNameGrid      = { stylesGridInfo } >

			         <Field type           = "text"
                            placeholder    = ""
                            value          = {lastname}
                            onChange       = {handleLastnameChange} />

              </WidgetFrame>

              <div className={styles.interzone}>&nbsp;</div>

              <WidgetFrame name               = "Age"
                           error              = { age_errorMessage }
					       labelTextPosition  = { isSmallScreen ? "left" : "left" }
						   labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
						   classNameName      = { styles.frameLabel }
   						   classNameGrid      = { stylesGridInfo } >

			         <Field type           = "text"
                            placeholder    = ""
                            value          = {age}
                            onChange       = {handleAgeChange} />

              </WidgetFrame>

              <div className={styles.interzone}>&nbsp;</div>

              <WidgetFrame name               = "Email"
                           error              = { email_errorMessage }
					       labelTextPosition  = { isSmallScreen ? "left" : "left" }
						   labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
						   classNameName      = { styles.frameLabel }
   						   classNameGrid      = { stylesGridInfo } >

			         <Field type           = "email"
                            placeholder    = ""
                            value          = {email}
                            onChange       = {handleEmailChange} />

              </WidgetFrame>

              <div className={styles.interzone}>&nbsp;</div>

              <WidgetFrame name               = "Ville"
                           error              = { city_errorMessage }
					       labelTextPosition  = { isSmallScreen ? "left" : "left" }
						   labelBlockPosition = { isSmallScreen ? "top"  : "left"  }
						   classNameName      = { styles.frameLabel }
   						   classNameGrid      = { stylesGridInfo } >

			         <Field type           = "text"
                            placeholder    = ""
                            value          = {city}
                            onChange       = {handleCityChange} />

              </WidgetFrame>

              <br/>

              <div className={classNames(styles.buttons_block)}>
               
              {
			    ( typeof (onClickOnCancelButton) !== "undefined" ) &&
                (				

                        <button className = "mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleCancelButtonClick}>
                           { typeof (cancelButtonName) === "undefined" ? "Annuler" : cancelButtonName }
                        </button>

				)
			  }

              {
			    ( typeof (onClickOnValidButton) !== "undefined" ) &&
                (				
                        <button className = "mx-5 px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleValidButtonClick}>
                          { typeof (validButtonName) === "undefined" ? "Valider" : validButtonName }
                        </button>
						
				)
			  }

			 </div>

            </div>
         );
};

export default WindowWithForm;
