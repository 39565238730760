import   React        from 'react';

import   classNames   from 'classnames';

import   styles       from './global.module.scss';

import { Header    }  from '../header/header';

import './menu_deroulant.css';
import '../../../config.module.scss';

export const GlobalMenu = () => {

/*
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  const element: HTMLElement | null = document.getElementById('globalMenu');
  const elementStyle                = element !== null ? window.getComputedStyle(element) : null;

  const menuWidthLimitStr           = elementStyle !== null ? elementStyle.getPropertyValue('--menuWidthLimit') : "10000";
  const menuWidthLimit              = 1050; // parseInt( menuWidthLimitStr );

  const handleResize = () => {
      setIsSmallScreen ( window.innerWidth <= menuWidthLimit );
    };

  useEffect(() => {

  }, [isSmallScreen]); 
 
  useEffect(() => {

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
	
  }, []); 
  
  return (
   
           <div id="globalMenu" className={classNames(styles.root)}>
            
                  { 
                    ( 
                      isSmallScreen 
                    ) 
                    ?
                    ( 
                      <>
                        <Burger />
                      </>
                      
                    )  
                    :
                    ( 
                      <>
                        <Header />
                      </>
                    ) 
                  }
            
           </div>
          
         );
*/

  return (
   
           <div id="globalMenu" className={classNames(styles.root)}>
            
             <Header />
            
           </div>
          
         );
		 
};

export default GlobalMenu;
