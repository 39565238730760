import   React                    , 
       { useEffect                , 
	     useState                 }  from 'react';

import { retrieveUserAgentInformations }  from '../../../network/utils';	
		 
import { Broadcasting             }  from './broadcasting';	
		 
import { postEvent                              }  from '../../../network/api_events_stats';
		 
import { useTypedSelector                       }  from '../../../datas/useTypeSelector';
		 
export const BroadcastingViewer = () => {
  
  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );

  const [ version , setVersion ] = useState (0)
  
  useEffect(() => {
 
 	postEvent ( "Ouverture de /diffusion" , authentication.login );
 
  }, [] )

    return (
     
          <>
		  
		   <Broadcasting/>
		  
          </>		  
	);
};

export default BroadcastingViewer;
