/*
import { createStore     , 
         applyMiddleware }  from 'redux';
import {  thunk          }  from 'redux-thunk';

import   reducers           from './generalReducer';

export const store = createStore (
  reducers                , 
  {}                      , 
  applyMiddleware (thunk)
);
*/

/*
import { createStore     ,
         applyMiddleware }  from 'redux';
import {  thunk          }  from 'redux-thunk';

import   reducers           from './generalReducer';

// Fonction pour récupérer le store depuis le localStorage
const loadState = (): any => {
  try {
    const serializedState = localStorage.getItem('reduxState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// Fonction pour sauvegarder le store dans le localStorage
const saveState = (state: any): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('reduxState', serializedState);
  } catch {
    // Gérer les erreurs de sauvegarde
  }
};

// Charger le store initial depuis le localStorage
const persistedState = loadState();

// Créer le store en utilisant l'état initial chargé depuis le localStorage
export const store = createStore(
  reducers,
  persistedState, // Utiliser l'état initial chargé depuis le localStorage
  applyMiddleware(thunk)
);

// Abonnez-vous aux changements du store et sauvegardez-les dans le localStorage
store.subscribe(() => {
  saveState(store.getState());
});

*/


import { createStore     ,
         applyMiddleware }  from 'redux';
import {  thunk          }  from 'redux-thunk';

import   reducers           from './generalReducer';

// Date limite pour charger le state depuis localStorage
const cutoffDate = new Date('2024-08-14T12:00:00');

const loadState = (): any | undefined => {
  try {
    const serializedState = localStorage.getItem('reduxState');
    const savedDate = localStorage.getItem('savedDate');

    // Si l'état ou la date ne sont pas trouvés, retourne undefined
    if (serializedState === null || savedDate === null) {
      return undefined;
    }

    const savedStateDate = new Date(savedDate);

    // Si la date est antérieure à la date limite, retourne undefined
    if (savedStateDate < cutoffDate) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    console.error("Could not load state:", err);
    return undefined;
  }
};

const saveState = (state: any): void => {
  try {
    const serializedState = JSON.stringify(state);
    const currentDate = new Date().toISOString();

    localStorage.setItem('reduxState', serializedState);
    localStorage.setItem('savedDate', currentDate);
  } catch (err) {
    console.error("Could not save state:", err);
  }
};

const persistedState = loadState();

export const store = createStore(
  reducers,
  persistedState,
  applyMiddleware(thunk)
);

store.subscribe(() => {
  saveState(store.getState());
});
