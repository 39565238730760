import   React              ,  
       { useState           }  from 'react';

import { useNavigate        }  from 'react-router-dom';
		 
import { Dispatch           }  from 'redux';
import { useDispatch        }  from 'react-redux';

import   classNames            from 'classnames';

import   styles                from './bank_card.module.scss';

import { Trigger            }  from '../../widget/trigger/trigger';
import { Window             }  from '../../widget/window/window';

import { initAuthentication }  from '../../../datas/authenticationManagment/actionCreators';
import { initUser   }  from '../../../datas/userManagment/actionCreators';

  
import { postPayment        }  from '../../../network/api_payment';
  
import { INTERNAL_LINKS     }  from '../../../network/urls';

import { postEvent          }  from '../../../network/api_events_stats';
  
export interface BankCardForPurchaseProps {
  amount:           number;
  purchaseId:       number;
  email:            string;
  token:            string;
  comment?:         string;
  logout?:          boolean | undefined;  
}


export const BankCardForPurchase = ({ amount     ,
                                      purchaseId ,
								      email      ,
				                      token      ,
						              comment    ,
						              logout     }: BankCardForPurchaseProps) => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const [ showWindow            , setShowWindow         ] = useState ( false );
  const [ windowMessage         , setWindowMessage      ] = useState ( <></> );
  const [ expiredSession        , setExpiredSession     ] = useState ( false );

  const handleValidationButtonClick = () => {

     makePayment ();
   }

  const handleCloseButtonClick = () => {

    setWindowMessage (<></>);
    setShowWindow	 (false)

    if ( expiredSession )
     {
	  dispatch ( initUser   () );
	  dispatch ( initAuthentication (true) );
      navigate ( `${INTERNAL_LINKS.Home}` );
	 }	
  }

  const makePayment = async () => {
  
	  postEvent ( `Tentative paiement par Axepta ${amount}` , email );

      const commentBis = typeof (comment) === "undefined" ? "" : comment;
  
      const response = await postPayment ( amount     , // Montant en centimes d'euros
			                               purchaseId , // Code du prix appliquée à cette vente
										   "01"       , // Code de bank "BNP - Axepta"
                                           email      , // Email du user
										   commentBis , // Commentaire
                                           token      ) // Token JWT


    switch ( response.code )
	 {
      case 1 : // Dès que le paiement a été créé, on ressort automatiquement pour ne pas rester en mode connecté
               // (le user doit valider son email avant de se connecter)

                  if ( logout )
		           {
                    dispatch ( initUser   () );
	                dispatch ( initAuthentication (true) );
                   }
			
                   window.location.href = response.url;
			   
			      break;
			   
	  case -102 : setWindowMessage (<>Votre sessions a expiré, veuillez vous reconnecter</>);
	              setShowWindow    (true)
                  setExpiredSession (true);
				  
				  break;

      default :   setWindowMessage (<>Une erreur réseau est survenue lors de la génération de la demande de paiement</>);
	              setShowWindow    (true)
	 }
  }

  const renderLogoVisa = (): JSX.Element => {
    
	return <img src       = { `${INTERNAL_LINKS.Logo_Visa}` }
                className = { classNames(styles.logo) }
                alt       = "logo"/>
  }

  const renderLogoMastercard = (): JSX.Element => {
    
	return <img src       = { `${INTERNAL_LINKS.Logo_Mastercard}` }
                className = { classNames(styles.logo) }
                alt       = "logo"/>
  }

  const renderLogoCarteBleue = (): JSX.Element => {
    
	return <img src       = { `${INTERNAL_LINKS.Logo_Carte_bleue}` }
                className = { classNames(styles.logo) }
                alt       = "logo"/>
  }
  return (
           <> 
		   
               {
			     showWindow
				 &&
                 (
				   < Window message              = { windowMessage          }
				            onClickOnCloseButton = { handleCloseButtonClick } />
				 )				
		       }
 
             <Trigger  classNameNormal = {classNames(styles.button)}
			  	       onClick         = {handleValidationButtonClick} 
					   image1          = { renderLogoVisa () }
					   image2          = { renderLogoMastercard  () }
					   image3          = { renderLogoCarteBleue () }/>
             <div className = { classNames(styles.infos) } >
			   Paiement sécurisé par BNP Axepta
             </div>			 
           </>
         );
};


export default BankCardForPurchase;
