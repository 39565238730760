import { combineReducers                }  from 'redux';

import   UserManagmentReducer              from './userManagment/reducer';
import   AuthenticationManagmentReducer    from './authenticationManagment/reducer';
import   PaymentManagmentReducer           from './paymentManagment/reducer';
import   ClientManagmentReducer            from './clientManagment/reducer';
import   VideoManagmentReducer             from './videoManagment/reducer';
import   ProductManagmentReducer           from './productManagment/reducer';
import   CartManagmentReducer              from './cartManagment/reducer';

const reducers = combineReducers ({
    userManagment:           UserManagmentReducer           ,
    authenticationManagment: AuthenticationManagmentReducer ,
    paymentManagment:        PaymentManagmentReducer        ,
    clientManagment:         ClientManagmentReducer         ,
    videoManagment:          VideoManagmentReducer          ,
    productManagment:        ProductManagmentReducer        ,
    cartManagment:           CartManagmentReducer   
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
