import   React            , 
       { useEffect        }  from 'react';

import   classNames          from 'classnames';

import   styles              from './masterclass.module.scss';

import { GlobalMenu       }  from '../../menu/global/global';
import { Footer           }  from '../../menu/footer/footer';

import { TopShifter       }  from '../../menu/top_shifter/top_shifter';
import { CookiesManager   }  from '../../cookie/manager';

import { Spot             }  from './blocks/spot/spot';

import { postEvent        }  from '../../../network/api_events_stats';

import { useTypedSelector }  from '../../../datas/useTypeSelector';



export const Masterclass = () => {

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );

  useEffect(() => {

	postEvent ( "Ouverture de /masterclass" , authentication.login );

  }); 

  return (
            <>
		      
 		      <CookiesManager />
 			  <GlobalMenu />
              <TopShifter/>
              
			  
              <div className={classNames(styles.root)} id="home">

                 <section id="spot">
				  <Spot />
				 </section>

              </div>			 

			<Footer/>

            </>
        );
};

export default Masterclass;
