export enum ActionType
 {
  INIT_PAYMENT           = 'INIT_PAYMENT'           ,
  SET_PAYMENT            = 'SET_PAYMENT'            ,
  UPDATE_DESCRIPTION     = 'UPDATE_DESCRIPTION'     ,
  UPDATE_AMOUNT          = 'UPDATE_AMOUNT'          , 
  UPDATE_DATE            = 'UPDATE_DATE'            ,
  UPDATE_MEANSOFPAYMENT  = 'UPDATE_MEANSOFPAYMENT'  ,
  UPDATE_STATUS          = 'UPDATE_STATUS'          ,
  UPDATE_TRANSACTIONID   = 'UPDATE_TRANSACTIONID'          
 }

export interface Payment
 {
  description:     string ,
  amount:          number ,
  date:            string ,
  meansOfPayment:  string ,
  status:          string ,
  transactionId:   string
 }
 
export interface actionInitPayment
 {
  type:                   ActionType.INIT_PAYMENT;
 }

export interface actionSetPayment
 {
  type:                   ActionType.SET_PAYMENT;
  newDescription:         string;
  newAmount:              number;
  newDate:                string;
  newMeansOfPayment:      string;
  newStatus:              string;
  newTransactionId:       string;
 }

export interface actionUpdateDescription
 {
  type:                   ActionType.UPDATE_DESCRIPTION;
  newDescription:         string;
 }

export interface actionUpdateAmount
 {
  type:                   ActionType.UPDATE_AMOUNT
  newAmount:              number;
 }

export interface actionUpdateDate
 {
  type:                   ActionType.UPDATE_DATE;
  newDate:                string;
 }

export interface actionUpdateMeansOfPayment
 {
  type:                   ActionType.UPDATE_MEANSOFPAYMENT;
  newMeansOfPayment:      string;
 }

export interface actionUpdateStatus
 {
  type:                   ActionType.UPDATE_STATUS;
  newStatus:              string;
 }

export interface actionUpdateTransactionId
 {
  type:                   ActionType.UPDATE_TRANSACTIONID;
  newTransactionId:       string;
 }

export type Action = actionInitPayment           |
                     actionSetPayment            | 
                     actionUpdateDescription     | 
                     actionUpdateAmount          | 
                     actionUpdateDate            |
					 actionUpdateMeansOfPayment  |
					 actionUpdateStatus          |
					 actionUpdateTransactionId;
					 
export type DispatchTypeInitPayment           = ( args: actionInitPayment           ) => actionInitPayment
export type DispatchTypeSetPayment            = ( args: actionSetPayment            ) => actionSetPayment
export type DispatchTypeUpdateDescription     = ( args: actionUpdateDescription     ) => actionUpdateDescription
export type DispatchTypeUpdateAmount          = ( args: actionUpdateAmount          ) => actionUpdateAmount
export type DispatchTypeUpdateDate            = ( args: actionUpdateDate            ) => actionUpdateDate
export type DispatchTypeUpdateMeansOfPayment  = ( args: actionUpdateMeansOfPayment  ) => actionUpdateMeansOfPayment
export type DispatchTypeUpdateStatus          = ( args: actionUpdateStatus          ) => actionUpdateStatus
export type DispatchTypeUpdateTransactionId   = ( args: actionUpdateTransactionId   ) => actionUpdateTransactionId
