import { Action     , 
         ActionType ,
		 ProductCatalog   }  from './actionTypes';


interface State 
 {
  catalog: ProductCatalog;
 }

const initialState = 
 {
  catalog: 
   {
    categories: []  
   } 
 }

const ProductManagmentReducer = (state: State = initialState, action: Action):State => 
 {
  switch(action.type) 
   {
    case ActionType.INIT_PRODUCT_CATALOG:             state.catalog.categories = []
										      
                                              return state;

    case ActionType.SET_PRODUCT_CATALOG:              console.log (action)
	
	                                          state.catalog = action.newCatalog
										      
											  console.log (state)
											  
                                              return state;


   
   /*
    case ActionType.INIT_CATALOG:
      return {
        ...state,
        catalog: { ...state.catalog, categories: [] }
      };

    case ActionType.SET_CATALOG:
      return {
        ...state,
        catalog: action.newCatalog
      };
	*/								 
									 
    default:                              return state;
   }
 }


export default ProductManagmentReducer;