import   axios                       from 'axios';

import { Product                  ,
         ProductVariant           ,
		 Characteristic           ,
		 Price                    ,
		 ProductCategory          ,
		 ProductCatalog           }  from '../datas/productManagment/actionTypes';

import { getUserAgentInformations ,
         getAppInformations       }  from './utils';	   

import { API_URLS                 }  from './urls';


export interface ProductInfos {
  code:      number ,
  comment:   string ,
  products:  Product[]
}

/*

"Tous"
"Avec inscription obligatoire"
"Offres gratuites"
"Offres payantes"

*/

export const getProductInfos = async ( rawFilter: string ) : Promise<ProductInfos>  => {

    const baseUrl = `${API_URLS.getProductInfos}?`;

    const name1  = "filter="; 
    const value1 = rawFilter !== '' ? encodeURIComponent (rawFilter) : encodeURIComponent ("Tous") ;      

    var url = `${baseUrl}${name1}${value1}`;

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
          'User-Agent-Informations': `${userAgentInformations}`,
	      'App-Informations': `${appInformations}`,
    };

    var result:ProductInfos = {
      code:     0 ,
	  comment:  "" ,
      products: []
    };

    try {

      const response = await axios.get ( url, { headers: headers } );
  
      const statut = response.status;

      if ( statut === 200  )
             {
			  const rawInfos_products = response.data
			  
			  let products: Product [] = []
			  
			  for (let i = 0; i < rawInfos_products.length; i++) 
			   {
                const rawInfos_product = rawInfos_products[i];

		        let product: Product = {
					    id:                   rawInfos_product.id   ,
                        name:                 rawInfos_product.name ,
						code:                 rawInfos_product.productCode ,
						requiredStatus:       rawInfos_product.requiredStatus, // gratuit, découverte, standard, premium
						kind:                 rawInfos_product.kind,           // PhysicalItem, VideoMasterClass..
						description:          rawInfos_product.description,
                        creationDate:         rawInfos_product.creationDate,
						variants:             [],
						characteristics:      []
				    }

			    for (let j = 0; j < rawInfos_product.productVariants.length; j++) 
			     {
                  const rawInfos_productVariant = rawInfos_product.productVariants[j];

		          let variant: ProductVariant = {
				  
				        id:                 rawInfos_productVariant.id ,
 				        name:               rawInfos_productVariant.name ,
				        complement:         rawInfos_productVariant.complement ,
						description:        rawInfos_productVariant.description ,
                        creationDate:       rawInfos_productVariant.creationDate ,
 				        businessUnitCode:   rawInfos_productVariant.businessUnitCode ,
 				        productVariantCode: rawInfos_productVariant.productVariantCode ,
 				        characteristics:    [],
						prices:             []
				    }
					
                  for (let k = 0; k < rawInfos_productVariant.characteristics.length; k++) 
			       {
                    const rawInfos_characteristic2 = rawInfos_productVariant.characteristics[k];

		            let characteristic2: Characteristic = {
				  
				        id:    rawInfos_characteristic2.id ,
 				        name:  rawInfos_characteristic2.name ,
 				        kind:  rawInfos_characteristic2.kind ,
				        unit:  rawInfos_characteristic2.unit ,
 				        value: rawInfos_characteristic2.value 
				      }
					
				    variant.characteristics.push ( characteristic2 )
                   }

                  for (let l = 0; l < rawInfos_productVariant.prices.length; l++) 
			       {
                    const rawInfos_price = rawInfos_productVariant.prices[l];

		            let price: Price = {
				  
                        id:                            rawInfos_price.id                            ,
                        name:                          rawInfos_price.name                          ,
                        requiredStatus:                rawInfos_price.requiredStatus                ,
                        comment:                       rawInfos_price.comment                       ,
                        code:                          rawInfos_price.code                          ,
                        creationDate:                  rawInfos_price.creationDate                  ,
                        basePrice:                     rawInfos_price.basePrice                     ,
                        taxRate:                       rawInfos_price.taxRate                       ,
                        discountWithStatusType:        rawInfos_price.discountWithStatusType        ,
                        discountWithStatusValue:       rawInfos_price.discountWithStatusValue       ,
                        discountWithSpecialEventType:  rawInfos_price.discountWithSpecialEventType  ,
                        discountWithSpecialEventValue: rawInfos_price.discountWithSpecialEventValue ,
                        priceToPay:                    rawInfos_price.priceToPay
				      }
					
				    variant.prices.push ( price )
                   }
				   
					
				  product.variants.push ( variant )
                 }

			    for (let j = 0; j < rawInfos_product.characteristics.length; j++) 
			     {
                  const rawInfos_characteristic = rawInfos_product.characteristics[j];

		          let characteristic: Characteristic = {
				  
				        id:    rawInfos_characteristic.id ,
 				        name:  rawInfos_characteristic.name ,
 				        kind:  rawInfos_characteristic.kind ,
				        unit:  rawInfos_characteristic.unit ,
 				        value: rawInfos_characteristic.value 
				    }
					
				  product.characteristics.push ( characteristic )
                 }
				 
                products.push ( product )
			   }
			   
			  result.products = products;
             }
        else {
		
              result = {
                code:     -101,
                comment:  `Erreur lors de la récupération des informations des abonnements : ${response.status}`,
                products: []
              };
             }


    }
    catch (error) {

     result = {
       code:     -201,
 	   comment:  "Erreur réseau",
       products: []
     };
    }
	
    return result;
  };



export interface ProductCatalogInfos {
  code:     number,
  comment:  string,
  catalog:  ProductCatalog
}

export const getProductCatalogInfos = async () : Promise<ProductCatalogInfos>  => {

    const url = `${API_URLS.getProductCatalogInfos}`;

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
          'User-Agent-Informations': `${userAgentInformations}`,
	      'App-Informations': `${appInformations}`,
    };

    var result:ProductCatalogInfos = {
      code:    0 ,
	  comment: "" ,
      catalog: {
        categories: []
      }
    };

    try {

      const response = await axios.get ( url, { headers: headers } );
	  
  
      const statut = response.status;

      if ( statut === 200  )
             {
			  const rawInfos_catalog = response.data
			  
			  let catalog: ProductCatalog = {
                    categories: []
              }
			  
			  // Ajout de la catégorie spéciale "Nouveauté"
			  
			  // let category_news: Category = {
                      // name:      'Nouveautés' ,
                      // products:  []
			  // }
			  
			  // catalog.categories.push ( category_news );
			  
			  for (let i = 0; i < rawInfos_catalog.length; i++) 
			   {
                const rawInfos_category = rawInfos_catalog[i];

			    let category: ProductCategory = {
                      name:      rawInfos_category.name ,
                      products:  []
				}

			    for (let j = 0; j < rawInfos_category.products.length; j++) 
			     {
                  const rawInfos_product = rawInfos_category.products[j];
				 
		          let product: Product = {
					    id:                   rawInfos_product.id   ,
                        name:                 rawInfos_product.name ,
						code:                 rawInfos_product.productCode ,
						requiredStatus:       rawInfos_product.requiredStatus, // gratuit, découverte, standard, premium
						kind:                 rawInfos_product.kind,           // PhysicalItem, VideoMasterClass..
                        description:          rawInfos_product.description,
                        creationDate:         rawInfos_product.creationDate,		
						variants:             [],
						characteristics:      []
				    }
					
			    for (let j = 0; j < rawInfos_product.productVariants.length; j++) 
			     {
                  const rawInfos_productVariant = rawInfos_product.productVariants[j];

		          let variant: ProductVariant = {
				  
				        id:                 rawInfos_productVariant.id ,
 				        name:               rawInfos_productVariant.name ,
				        complement:         rawInfos_productVariant.complement ,
                        description:        rawInfos_productVariant.description,
                        creationDate:       rawInfos_productVariant.creationDate,
 				        businessUnitCode:   rawInfos_productVariant.businessUnitCode ,
 				        productVariantCode: rawInfos_productVariant.productVariantCode ,
						characteristics:    [],
						prices:             []
				    }
					
                  for (let k = 0; k < rawInfos_productVariant.characteristics.length; k++) 
			       {
                    const rawInfos_characteristic2 = rawInfos_productVariant.characteristics[k];

		            let characteristic2: Characteristic = {
				  
				        id:    rawInfos_characteristic2.id ,
 				        name:  rawInfos_characteristic2.name ,
 				        kind:  rawInfos_characteristic2.kind ,
				        unit:  rawInfos_characteristic2.unit ,
 				        value: rawInfos_characteristic2.value 
				      }
					
				    variant.characteristics.push ( characteristic2 )
                   }
					
                  for (let l = 0; l < rawInfos_productVariant.prices.length; l++) 
			       {
                    const rawInfos_price = rawInfos_productVariant.prices[l];

		            let price: Price = {
				  
                        id:                            rawInfos_price.id ,
                        name:                          rawInfos_price.name ,
                        requiredStatus:                rawInfos_price.requiredStatus ,
                        comment:                       rawInfos_price.comment ,
                        code:                          rawInfos_price.code ,
                        creationDate:                  rawInfos_price.creationDate,
                        basePrice:                     rawInfos_price.basePrice ,
                        taxRate:                       rawInfos_price.taxRate ,
                        discountWithStatusType:        rawInfos_price.discountWithStatusType        ,
                        discountWithStatusValue:       rawInfos_price.discountWithStatusValue       ,
                        discountWithSpecialEventType:  rawInfos_price.discountWithSpecialEventType  ,
                        discountWithSpecialEventValue: rawInfos_price.discountWithSpecialEventValue ,
                        priceToPay:                    rawInfos_price.priceToPay
				      }
					
				    variant.prices.push ( price )
                   }
					
				  product.variants.push ( variant )
                 }

			    for (let j = 0; j < rawInfos_product.characteristics.length; j++) 
			     {
                  const rawInfos_characteristic = rawInfos_product.characteristics[j];

		          let characteristic: Characteristic = {
				  
				        id:    rawInfos_characteristic.id ,
 				        name:  rawInfos_characteristic.name ,
 				        kind:  rawInfos_characteristic.kind ,
				        unit:  rawInfos_characteristic.unit ,
 				        value: rawInfos_characteristic.value 
				    }
					
				  product.characteristics.push ( characteristic )
                 }
				 
				category.products.push (product)
				  
				  // On ajoute les vidéos au vidéo set selon un critère à définir.....
					
 				  // const now = new Date(); // Date courante
                  // const oneMonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()); // Date il y a un mois
                  // const videoDate = new Date(rawInfos_product.creationDate);
                    
				  // if ( videoDate > oneMonthAgo )
				   // {
					// let exist: boolean = false;
				  
				    // for (let vid = 0; vid < catalog.categories[0].products.length; vid++) 
			         // {
				      // const temp_video = catalog.categories[0].products[vid];
					
					  // if ( temp_video.name === rawInfos_product.name )
					   // {
					    // exist = true;
					    // break;
					   // }
				     // }
				 
				    // if ( !exist )
					 // {
					  // catalog.categories[0].products.push (product)
					 // }
				   // }
                 }
				 
				catalog.categories.push ( category );
 			   }
			   
			  result.catalog = catalog;
             }
        else {
              result = {
                code:    -101,
                comment: `Erreur lors de la récupération des informations de paiement de l'utilisateur : ${response.status}`,
                catalog: {
                  categories: []
                }
              };
             }


    }
    catch (error) {

     result = {
       code:    -201,
 	   comment: "Erreur réseau",
       catalog: {
         categories: []
       }
     };
    }

    return result;
  };

