import   React             ,
       { PropsWithChildren , 
         ReactNode         ,
          useEffect         ,   
         useState          }  from 'react';
		 
import   classNames           from 'classnames';

import   styles               from './tab.module.scss';

import { Choices           }  from '../choices/choices';


type TabProps = {
  children:               ReactNode;
  classNameNormalButton?: string; 
  classNamePushedButton?: string;
  onValidateButtonClick?: () => void;
};

export const Tab = ({ children              ,
                      classNameNormalButton ,
                      classNamePushedButton ,
                      onValidateButtonClick }:  PropsWithChildren<TabProps> ) => {

  const VIDE1: boolean[] = [];
  const VIDE2: string[]  = [];

  const [ firstRender , setFirstRender ] = useState ( true  );
  const [ actives     , setActives     ] = useState ( VIDE1 );
  const [ choices     , setChoices     ] = useState ( VIDE2 );


  useEffect(() => {

    if ( firstRender )
     {
      var temp1: boolean[] = [];
      var temp2: string[]  = [];
 
      if (children && Array.isArray(children)) 
       {
        for (let index = 0; index < children.length; index++) 
         {
          temp1.push(index === 0);
          temp2.push(children[index]['props']['name']);
         }
       }
	 
      setActives ( temp1 );
      setChoices ( temp2 );

      setFirstRender (false);
     }

  }, [firstRender, actives, children] ); 
  
  const handleChange = ( newActives: boolean[] ) => {
  
    setActives ( newActives );
  }
 
  return (

           <div className={classNames(styles.root)}>

             <div className={classNames(styles.contain)}>

               <div className={classNames(styles.left)}>

                    <Choices classNameNormal = { classNames(styles.trigger_normal, classNameNormalButton) }
                             classNamePushed = { classNames(styles.trigger_pushed, classNamePushedButton) }
                             choices         = { choices }
                             actived         = { actives }
			                 onChange        = { handleChange } 
							 vertical        = { true }   />

               </div>

               <div className={classNames(styles.right)}>

               {
	             children && 
	             Array.isArray(children) && 
	              (
                    <div>
                     {
                      children.map((item, index) => ( actives[index] && <div key={index}>{item}</div> ))
                     }
                    </div>
                  )
	           }

	           </div>

	         </div> 
			 
           </div> 
         );
};


export default Tab;
