import   axios                    ,
       { AxiosError               }  from 'axios';

import { format                   ,
         parseISO                 }  from 'date-fns';

import { Payment                  }  from '../datas/paymentManagment/actionTypes';

import { getUserAgentInformations ,
         getAppInformations       }  from './utils';

import { API_URLS                 }  from './urls';

export interface PostResult {
  code:    number,
  comment: string,
  url:     string
}

export const postSubscription = async ( amount:             number ,
										reductionAmount:    number ,
                                        businessUnitCode:   string ,
                                        productVariantCode: string ,
                                        priceCode:          string ,
                                        bankCode:           string ,
                                        userEmail:          string ,
                                        comment:            string ,
                                        token:              string ): Promise<PostResult> => {

    const userAgentInformations = getUserAgentInformations ();
    const appInformations       = getAppInformations ();

    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
        'Authorization': `Bearer ${token}`,
        'User-Agent-Informations': `${userAgentInformations}`,
        'App-Informations': `${appInformations}`,
    };

    const amountStr = `${amount}`;
    const quantityStr = '1';

    var result:PostResult = {
      code:    0,
      comment: "",
      url:     ""
    };

	console.log ("postSubscription 1")

    try
     {
      const response = await axios.post (
        
                                          `${API_URLS.postPurchase}`,
                              
                                          {
                                            quantityStr        ,
                                            businessUnitCode   ,
                                            productVariantCode ,
                                            priceCode          ,
                                            userEmail          ,
                                            comment
                                          },
                                            
                                          {
                                            headers: headers
                                          }
                                        );

	  console.log ("postSubscription 2" , response)

      switch ( response.status )
       {
        case 201 : const purchaseId = response.data.id;

		           if ( reductionAmount > 0 )
					{
	                 console.log ("postSubscription 3" , response)

					 const baseUrl =  `${API_URLS.patchAddSpecialItemToUserCart}`;

                     const url = baseUrl.replace("{id}", `${purchaseId}`);

					 const headers2 = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${token}`,
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

					 const reductionAmount2 : number = -reductionAmount / 1.2

                     const description   : string = "Réduction prorata abonnement Standard"   // ça ne concerne que l'upgrade Standard vers Premium'
                     const quantityStr   : string = "1"
                     const basePriceStr  : string = reductionAmount2.toFixed();
                     const taxRateStr    : string = "2000"
                     const priceToPayStr : string = `${-reductionAmount}`
                     const totalPriceStr : string = `${-reductionAmount}`

	                 console.log ("postSubscription 4" , url)

					 try
					  {
					   const response3 = await axios.patch (

                                                            url,

                                                            {
                                                               description   ,
                                                               quantityStr   ,
                                                               basePriceStr  ,
                                                               taxRateStr    ,
                                                               priceToPayStr ,
                                                               totalPriceStr
                                                            },

                                                            {
                                                               headers: headers2
                                                            }
                                                          );

	                   console.log ("postSubscription 5 " , response3)
					  }
                     catch ( err )
					  {
                       const error = err as AxiosError;

					   console.log ("postSubscription 5b " , error)

                       result = {
                                  code:    -201,
                                  comment: "Erreur réseau",
								  url:     ""
                                };

					   return result;
                      }
					}

	               console.log ("postSubscription 6")

                   try
                    {
                     const response2 = await axios.post (
           
                                                          `${API_URLS.postPayment}`,
                              
                                                          {
                                                             amountStr  ,
                                                             purchaseId ,
                                                             bankCode   ,
                                                             userEmail  ,
                                                             comment
                                                          },
                                            
                                                          {
                                                             headers: headers
                                                          }
                                                        );

	               console.log ("postSubscription 7 ", response2)

                     switch ( response2.status )
                      {
                       case 201 : result = {
                                             code:    1 ,
                                             comment: "Informations d'achat et de paiement créées" ,
                                             url:     response2.data.url
                                           };

                                  break;
 
                       default :  result = {
                                             code:     -102 ,
                                             comment: `Erreur lors de la création des informations de l'achat (souscription) ${response2.status}`,
                                             url:      ""
                                           };
                      }
                    }
                   catch ( err2 )
				    {
                     const error2 = err2 as AxiosError;

                     if ( error2.response && error2.response.status )
                           {
                            switch ( error2.response.status )
                             {
                              case 401 : result = {
                                                    code:    -102,
                                                    comment: "Session expirée",
											        url:     ""
                                                  };

								         break;

                              default  : result = {
                                                    code:    -201,
                                                    comment: "Erreur réseau",
                                                    url:     ""
                                                  };
                            }
                          }
                     else {
                           result = {
                                      code:    -201,
                                      comment: "Erreur réseau",
                                       url:     ""
                                    };
                          }
                     }

                    break;

          default : result = {
                                code:     -103 ,
                                comment: `Erreur lors de la création des informations de l'achat (souscription) ${response.status}`,
								url:      ""
                              };
         }

    }  
    catch ( err ) {

        const error = err as AxiosError;


        if ( error.response && error.response.status )
              {
               switch ( error.response.status )
                {
                 case 401 : result = {
                                       code:    -102,
                                       comment: "Session expirée",
                                        url:     ""
                                     };

                            break;

                 default :  result = {
                                       code:    -201,
                                       comment: "Erreur réseau",
                                        url:     ""
                                     };
                }
              }
         else {
               result = {
                          code:    -201,
                          comment: "Erreur réseau",
                           url:     ""
                        };
              }
    }


    return result;
};


export const postPayment = async ( amount:             number ,
                                   idOfPurchase:       number ,
                                   bankCode:           string ,
                                   userEmail:          string ,
                                   comment:            string ,
                                   token:              string ): Promise<PostResult> => {

    const userAgentInformations = getUserAgentInformations ();
    const appInformations       = getAppInformations ();

    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
        'Authorization': `Bearer ${token}`,
        'User-Agent-Informations': `${userAgentInformations}`,
        'App-Informations': `${appInformations}`,
    };

    const amountStr = `${amount}`;
    const quantityStr = '1';

    var result:PostResult = {
      code:    0,
      comment: "",
      url:     ""
    };

    const purchaseId: string = `${idOfPurchase}`;

    try {

        const response2 = await axios.post (
           
                                                            `${API_URLS.postPayment}`,
                              
                                                            { 
                                                              amountStr  ,
                                                               purchaseId ,
                                                                 bankCode   ,
                                                                 userEmail  ,
                                                                 comment
                                                            },
                                            
                                                            {
                                                              headers: headers
                                                            }
                                                          );

        console.log ( "postPayment",response2);

        switch ( response2.status )
         {
         case 201 : result = {
                                               code:    1 ,
                                               comment: "Informations d'achat et de paiement créées" ,
                                                   url:     response2.data.url
                                             };

                                    break;
 
         default :  result = {
                                               code:     -102 ,
                                               comment: `Erreur lors de la création des informations de l'achat (souscription) ${response2.status}`,
                                                url:      ""
                                             };
        }
    }
    catch ( err2 ) {

                     const error2 = err2 as AxiosError;

                         console.log ( "postSubscription",error2);

                    if ( error2.response && error2.response.status )
                     {
                      switch ( error2.response.status )
                       {
                        case 401 : result = {
                                              code:    -102,
                                              comment: "Session expirée",
                                               url:     ""
                                            };

                                      break;

                        default :  result = {
                                              code:    -201,
                                              comment: "Erreur réseau",
                                               url:     ""
                                            };
                       }
                     }
                     else {
                           result = {
                                      code:    -201,
                                      comment: "Erreur réseau",
                                       url:     ""
                                    };
                          }
                     }


                             console.log ( "postPayment",result);

    return result;
};

export interface PaymentInfos {
  code:     number,
  comment:  string,
  payments: Payment []
}

export const getPaymentInfos = async ( email: string ,
                                       token: string ) : Promise<PaymentInfos>  => {

    const baseUrl  = `${API_URLS.getPaymentInfosByEmail}?`;
    const nomInfo1 = "email=";
    const valInfo1 = encodeURIComponent (email);

    const url = `${baseUrl}${nomInfo1}${valInfo1}`;

    const userAgentInformations = getUserAgentInformations ();
    const appInformations       = getAppInformations ();

    const headers = {
          'Authorization': `Bearer ${token}`,
          'User-Agent-Informations': `${userAgentInformations}`,
          'App-Informations': `${appInformations}`,
    };

    var result:PaymentInfos = {
      code:      0 ,
      comment:  "" ,
      payments: []
    };

          console.log ( "getPaymentInfos",url);

    try {

      const response = await axios.get ( url, { headers: headers } );

      console.log ( "getPaymentInfos",response);

      const statut = response.status;

      switch ( statut )
       {
        case 200 : const rawInfos = response.data['hydra:member']

                   let payments: Payment[] = [];

                   if ( Array.isArray (rawInfos) ) 
                    {
                     payments = rawInfos.map ( (info: any) => {

                       return {
                                description:     info.description,
                                amount:          info.amount,
                                date:            format ( parseISO (info.creationDate), 'dd/MM/yyyy à HH:MM:ss' )  ,
                                meansOfPayment:  info.meansOfPayment,
                                status:          info.paymentStatus,
                                transactionId:   info.transactionId
                              };
                       } );
                    }

                   result = {
                              code:    1,
                              comment: "Récupération des informations de paiement de l'utilisateur",
                              payments: payments
                            }

                   break;


        default :  result = {
                              code:    -102,
                              comment: `Erreur lors de la récupération des informations de paiement de l'utilisateur : ${response.status}`,
                              payments: []
                            };
       }
    }
    catch ( err ) {

        const error = err as AxiosError;

              console.log ( "getPaymentInfos",error);

        if ( error.response && error.response.status )
              {
               switch ( error.response.status )
                {
                 case 401 : result = {
                                       code:    -102,
                                       comment: "Session expirée",
                                        payments: []
                                     };

                            break;

                 default :  result = {
                                       code:    -201,
                                       comment: "Erreur réseau",
                                        payments: []
                                     };
                }
              }
         else {
               result = {
                          code:    -201,
                          comment: "Erreur réseau",
                           payments: []
                        };
              }
    }

    return result;
  };
