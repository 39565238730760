import   React                                  ,
       { useState                               ,
	     useEffect                              }  from 'react';

import   classNames                                from 'classnames';

import { Link                                   ,
         useNavigate                            ,
         useLocation                            }  from 'react-router-dom';

import { Dispatch                               }  from 'redux';
import { useDispatch                            }  from 'react-redux';
	 
/* https://szhsin.github.io/react-menu/
   https://codesandbox.io/p/sandbox/react-menu-react-router-example-dw4ku?file=%2Findex.js */		 
		 
import   styles                                    from './header.module.scss';

import { initAuthentication                     }  from '../../../datas/authenticationManagment/actionCreators';
import { initUser                               }  from '../../../datas/userManagment/actionCreators';
import { initCart                               }  from '../../../datas/cartManagment/actionCreators';

import { useTypedSelector                       }  from '../../../datas/useTypeSelector';

import { INTERNAL_LINKS                         }  from '../../../network/urls';

import { Window                                 }  from '../../widget/window/window';

import { getCatalogInfos                        ,
         CatalogInfos                           ,
         BroadcastingChannelPackageInfos        ,
         getBroadcastingChannelPackageInfos     }  from '../../../network/api_video';

import { setCatalog                             ,
         setBroadcastingChannelPackage          ,
         setCurrentlyPlayingBroadcastingChannel }  from '../../../datas/videoManagment/actionCreators';

export const Header = () => {

  const { authentication } = useTypedSelector((state) => state.authenticationManagment );
  const { user           } = useTypedSelector((state) => state.userManagment );

  const location                = useLocation();
  const dispatch: Dispatch<any> = useDispatch();
  const navigate                = useNavigate();

  const currentPathWithoutHash = location.pathname;
  
  const [ submenuVisible         , setSubmenuVisible         ] = useState(false);
  const [ accountMenuItemVisible , setAccountMenuItemVisible ] = useState(false);
  const [ isSmallScreen          , setIsSmallScreen          ] = useState<boolean>(false);

  const [ alertWindowVisible , setAlertWindowVisible ] = useState ( false );

  const switchWidthLimit = 870;

  const handleResize = () => {
    setIsSmallScreen ( window.innerWidth <= switchWidthLimit );
  };

  useEffect(() => {

  }, [isSmallScreen]); 


  useEffect(() => {

    console.log (user)

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
	
  }, []); 
  
  
  const handleLogoutClick = () => {
    
	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );///////////////////////////////////
	setSubmenuVisible ( false );
	reinit ();
	
//	window.location.href  = `${WWW_URLS.Home}`

    navigate ( `${INTERNAL_LINKS.Home}` );
  }

  const reinit = async () => {
  
    var catalogInfos: CatalogInfos = {
      code:    0 ,
	  comment: "" ,
      catalog: {
        categories: []
      }
    };  

  var broadcastingChannelPackageInfos: BroadcastingChannelPackageInfos = {
      code:    0 ,
	  comment: "" ,
      broadcastingChannelPackage: {
        channels: []
      }
    };

    catalogInfos = await getCatalogInfos ( -1 ); // Déconnecté

	dispatch ( setCatalog ( catalogInfos.catalog ) );

    broadcastingChannelPackageInfos = await getBroadcastingChannelPackageInfos ( ( authentication.login !== ""   &&
                                                                                   authentication.token !== "" ) &&
                                                                                 ( user.status  === "Premium" ) );

	dispatch ( setBroadcastingChannelPackage ( broadcastingChannelPackageInfos.broadcastingChannelPackage ) );

	dispatch ( setCurrentlyPlayingBroadcastingChannel ( broadcastingChannelPackageInfos.broadcastingChannelPackage.channels[0] ) )
  }

  const handleButtonClick = () => {
  
	if ( !submenuVisible )
	      {
	       if ( authentication.login !== "" )
	             {
	              if ( isSmallScreen )
	                    {
	                     setAccountMenuItemVisible ( true )
	                    }
		           else {
	                     setAccountMenuItemVisible ( false )
	                    }
	             }
		    else {
	              setAccountMenuItemVisible ( false )
	             }
	      }
	 else {
	       setAccountMenuItemVisible ( false )
	      }
	
    setSubmenuVisible(!submenuVisible); 
	
	
  }


  const renderSubMenu0 = (): JSX.Element => {

 		return (

				  <>

 					<Link to = {INTERNAL_LINKS.Masterclass} >
                       	<button className = {classNames(styles.button)} >MASTERCLASS</button>
                    </Link>

 					<Link to = {INTERNAL_LINKS.Shop} >
                       	<button className = {classNames(styles.button)} >BOUTIQUE</button>
                    </Link>

 					<Link to = {INTERNAL_LINKS.Replay} >
                       	<button className = {classNames(styles.button)} >LE REPLAY</button>
                    </Link>

                       <button className = {classNames(styles.button_yellow)}
					           onClick = {handleButtonClickOnStreaming} >LE LIVE</button>

                  </>
              )
  }

  const renderSubMenu3 = (): JSX.Element => {
  
 			 return (
                          <>
                            <Link to = {INTERNAL_LINKS.Cart} >
                              <button className = {classNames(styles.button)}
							          onClick   = {handleButtonClick} >PANIER</button>
                            </Link>

                           </>
                    )
  }

  const renderSubMenu1 = (): JSX.Element => {

    if ( currentPathWithoutHash !== `${INTERNAL_LINKS.Subscription}` &&
		 currentPathWithoutHash !== `${INTERNAL_LINKS.Login}`        &&
		 currentPathWithoutHash !== `${INTERNAL_LINKS.AccountManagment}`      )
	 {
	  if ( authentication.login === "" )
            {
 			 return (
                          <>
                            <Link to = {INTERNAL_LINKS.Login} >
                              <button className = {classNames(styles.button)} 
							          onClick   = {handleButtonClick} >ME CONNECTER</button>
                            </Link>
                            <br/>
                            <Link to = {INTERNAL_LINKS.Subscription} >
                              <button className = {classNames(styles.button)}  
							          onClick   = {handleButtonClick} >CRÉER MON COMPTE</button>
                            </Link>
                          </>
							
                    )
			}
	   else {
             return (
                          <>
                          </>
                    )
            }
			/*
       else {
             return (
                          <>
						  
                            <Link to = {INTERNAL_LINKS.AccountManagment} >
                              <button className = {classNames(styles.button)}  
							          onClick   = {handleButtonClick} >{user.firstname}</button>
                            </Link>
                            <br/>
                            <button className = {classNames(styles.button)} 
							        onClick   = {handleLogoutClick}  >ME DÉCONNECTER</button>

                          </>
                    )
            }

            */
     }
    
    else if ( currentPathWithoutHash === `${INTERNAL_LINKS.Subscription}` )
	 {
	  if ( authentication.login === "" )
            {
 			 return (
                          <>
                            <Link to = {INTERNAL_LINKS.Login} >
                              <button className = {classNames(styles.button)}  
							          onClick   = {handleButtonClick} >ME CONNECTER</button>
                            </Link>
                          </>
							
                    )
			}
	   else {
             return (
                          <>
                          </>
                    )
            }
			/*
	   else {
             return (
                          <>
						  
                            <Link to = {INTERNAL_LINKS.AccountManagment} >
                              <button className = {classNames(styles.button)}  
							          onClick   = {handleButtonClick} >{user.firstname}</button>
                            </Link>
                            <br/>
                            <button className = {classNames(styles.button)} 
							        onClick   = {handleLogoutClick}   >ME DÉCONNECTER</button>

                          </>
                    )
            }

            */
	 }
	 
	else if ( currentPathWithoutHash === `${INTERNAL_LINKS.Login}` ) 
	 {
	  if ( authentication.login === "" )
            {
 			 return (
                          <>
                            <Link to = {INTERNAL_LINKS.Subscription} >
                              <button className = {classNames(styles.button)}  
							          onClick   = {handleButtonClick} >CRÉER MON COMPTE</button>
                            </Link>
                          </>
                    )
			}
	   else {
             return (
                          <>
                          </>
                    )
            }
     }
	 
	 /*

    else if ( currentPathWithoutHash === `${INTERNAL_LINKS.AccountManagment}` )
     {
 	  return (
                          <>
                        <button className = {classNames(styles.button)} 
						        onClick   = {handleLogoutClick}   >ME DÉCONNECTER</button>
                          </>
             )
     }
	 */
	else
	 {
      return (
                          <>
                           
                          </>
             )
     }

  }

  const renderSubMenu1bis = (): JSX.Element => {

    if ( currentPathWithoutHash !== `${INTERNAL_LINKS.Subscription}` &&
		 currentPathWithoutHash !== `${INTERNAL_LINKS.Login}`        &&
		 currentPathWithoutHash !== `${INTERNAL_LINKS.AccountManagment}`      )
	 {
	  if ( authentication.login === "" )
            {
             return (
                          <>
                          </>
                    )
			}
       else {
             return (
                          <>


						  <button className = {classNames(styles.button)}
							        onClick   = {handleLogoutClick}  >ME DÉCONNECTER</button>

                          </>
                    )
            }


     }

    else if ( currentPathWithoutHash === `${INTERNAL_LINKS.Subscription}` )
	 {
       /*
	  if ( authentication.login === "" )
            {
             return (
                          <>
                          </>
                    )
			}
	   else {
             return (
                          <>

                            <button className = {classNames(styles.button)}
							        onClick   = {handleLogoutClick}   >ME DÉCONNECTER</button>

                          </>
                    )
            }

        */

       return ( <></> )
	 }

	else if ( currentPathWithoutHash === `${INTERNAL_LINKS.Login}` )
	 {
             return (
                          <>
                          </>
                    )
     }

	 /*

    else if ( currentPathWithoutHash === `${INTERNAL_LINKS.AccountManagment}` )
     {
 	  return (
                          <>
                        <button className = {classNames(styles.button)}
						        onClick   = {handleLogoutClick}   >ME DÉCONNECTER</button>
                          </>
             )
     }
	 */
	else
	 {
      return (
                          <>

                          </>
             )
     }

  }


  const renderSubMenu2_1 = (active: boolean): JSX.Element => {
  
	if ( active ) //	( identifiant === "mon-compte" )
	      {
		   return (
                          <>
                                <button className = {classNames(styles.button_yellow_2)} >MON COMPTE</button>
                          </>
				  )
		  }
	 else {
		   return (
                          <>
		                      <Link to = {INTERNAL_LINKS.AccountManagment_MyAccount} >
                                <button className = {classNames(styles.button)}  
							          onClick   = {handleButtonClick} >MON COMPTE</button>
                              </Link>
                          </>
				  )
		  }
  }

  const renderSubMenu2_2 = (active: boolean): JSX.Element => {
  
  	if ( active ) //	( identifiant === "abonnements-et-services" )
	      {
		   return (
                          <>
                              <button className = {classNames(styles.button_yellow_2)} >ABONNEMENTS ET SERVICES</button>
                          </>
                  )
		  }
	 else {
		   return (
                          <>
			                   <Link to = {INTERNAL_LINKS.AccountManagment_Subscription} >
                                 <button className = {classNames(styles.button)} 
							          onClick   = {handleButtonClick}  >ABONNEMENTS ET SERVICE</button>
                               </Link>
                          </>
                  )
		  }
  }
  
  const renderSubMenu2_3 = (active: boolean): JSX.Element => {
  
	if ( active ) //	( identifiant === "informations-de-paiement" )
	      { 
		   return (
                          <>
                                <button className = {classNames(styles.button_yellow_2)} >INFORMATIONS DE PAIEMENT</button>
                          </>
                  )
		  }
	 else {
		   return (
                          <>
				              <Link to = {INTERNAL_LINKS.AccountManagment_Payments} >
                                <button className = {classNames(styles.button)}  
							          onClick   = {handleButtonClick} >INFORMATIONS DE PAIEMENT</button>
                              </Link>

                          </>
			      )
		  }
  }
  
  const renderSubMenu2 = (): JSX.Element => {

	if ( accountMenuItemVisible )
	      {
           const identifiant = location.hash.substring(1);

		   return (
			        <>
				

			          { renderSubMenu2_1 ( identifiant === "mon-compte" ) }

			          <br/>

			          { renderSubMenu2_2 ( identifiant === "abonnements-et-services" ) }


			          <br/>

					  { renderSubMenu2_3 ( identifiant === "informations-de-paiement" ) }

			          <br/>
					  
				    </>
			   )
		  }
	 else {
		   return (
			    <>
				</>
			   )
		  }
  }
  
  const handleButtonClickOnStreaming = () => {
  
     // if ( authentication.login === "" || authentication.token === "" )
	       // {
		    // setAlertWindowVisible (true)
		   // }
	  // else {
	        navigate ( `${INTERNAL_LINKS.Streaming}` )
	       // }
  }


  const closeAlertWindowWithCancelButton = () => {
  
    setAlertWindowVisible ( false );
  }

  const closeAlertWindowWithValidButton = () => {
  
    setAlertWindowVisible ( false );
	
	navigate ( `${INTERNAL_LINKS.Subscription}`)
  }
  
  const renderSmallVersion = (): JSX.Element => {

    return (
  
              <div className={classNames(styles.header)}>

	

                <div className={classNames(styles.left)}>
                    <Link to = {INTERNAL_LINKS.Home} >
                       <img src       = "/images/Lovelight-TV.png" 
					        className = {classNames(styles.logo)}
							alt       = "logo"/>
                    </Link>
                </div>


                <div className={classNames(styles.center)}>
				
				  <div className={classNames(styles.block_buttons)}>
				  
 					<Link to = {INTERNAL_LINKS.Masterclass} >
                       	<button className = {classNames(styles.button)} >MASTERCLASS</button>
                    </Link>

 					<Link to = {INTERNAL_LINKS.Shop} >
                       	<button className = {classNames(styles.button)} >BOUTIQUE</button>
                    </Link>

 					<Link to = {INTERNAL_LINKS.Replay} >
                       	<button className = {classNames(styles.button)} >LE REPLAY</button>
                    </Link>
					
                       <button className = {classNames(styles.button_yellow)} 
					           onClick = {handleButtonClickOnStreaming} >LE LIVE</button>
					


                  </div>
				  
                </div>



                <div className={classNames(styles.right)}>

                  {
                    ( currentPathWithoutHash !== `${INTERNAL_LINKS.Subscription}` ) &&
                    (
                      <>

                        <button onClick   = {handleButtonClick} >

                          {
                            ( authentication.login !== ""  && authentication.token !== "" )
					        ?
                            (
					          <img src       = "/images/icons/avatar_connected.png"
					               className = {classNames(styles.avatar)}
							       alt       = "avatar connected"/>
					        )
						    :
                            (
					          <img src       = "/images/icons/avatar_unconnected.png"
					               className = {classNames(styles.avatar)}
							       alt       = "avatar unconnected"/>
					        )
					      }
					   
   					    </button>
					
					    {
					      submenuVisible &&
					      (
                           <div className={classNames(styles.submenu) }>

                             {/* renderSubMenu0 () */}
                             { renderSubMenu1 () }
                             { renderSubMenu2 () }
                             { renderSubMenu1bis () }

                             <hr className={classNames(styles.ligne)}/>

                             { renderSubMenu3 () }

                           </div>
                          )
					    }
                      </>
                    )
                  }

                </div>

              </div>

          
         );
		 
	}	 
		 
  const renderBigVersion = (): JSX.Element => {
		 

  return (
  
              <div className={classNames(styles.header)}>

                <div className={classNames(styles.left)}>
                    <Link to = {INTERNAL_LINKS.Home} >
                       <img src       = "/images/Lovelight-TV.png" 
					        className = {classNames(styles.logo)}
							alt       = "logo"/>
                    </Link>
                </div>

                <div className={classNames(styles.center)}>
				
				  <div className={classNames(styles.block_buttons)}>
				  
					
                   

                    

                    {/*
                    <Link to="/">
                       <button className = {classNames(styles.button)} >LES OFFRES</button>
                    </Link>
                    */}
					
					{/*
				    <Menu menuButton={<MenuButton className={styles.button}>LES OFFRES</MenuButton>}>
                      <MenuItem onClick = { () => { navigate ( `${INTERNAL_LINKS.Bloc01}` ); } } >
                             Offre Découverte
                      </MenuItem>
                      <MenuItem onClick = { () => { navigate ( `${INTERNAL_LINKS.Bloc02}` ); } } >
                             Offre Standard
                      </MenuItem>
                      <MenuItem onClick = { () => { navigate ( `${INTERNAL_LINKS.Bloc03}` ); } } >
                             Offre Premium
                      </MenuItem>
                    </Menu>
					
						<Link to = {INTERNAL_LINKS.Download} >
                          <button className = {classNames(styles.button)} >LES APPS</button>
                        </Link>
					*/}	

 					<Link to = {INTERNAL_LINKS.Masterclass} >
                       	<button className = {classNames(styles.button)} >MASTERCLASS</button>
                    </Link>

 					<Link to = {INTERNAL_LINKS.Shop} >
                       	<button className = {classNames(styles.button)} >BOUTIQUE</button>
                    </Link>
						
 						<Link to = {INTERNAL_LINKS.Replay} >
                       	<button className = {classNames(styles.button)} >LE REPLAY</button>
                    	</Link>
					
                       <button className = {classNames(styles.button_yellow)} 
					           onClick = {handleButtonClickOnStreaming} >LE LIVE</button>
							   
					
                  </div>
				  
                </div>

                <div className={classNames(styles.separation)}>
 					
                 <span className={classNames(styles.separation_symbole)}>|</span>

                </div>

                <div className={classNames(styles.right)}>

  				  {
                    ( 
					  ( currentPathWithoutHash !== `${INTERNAL_LINKS.Subscription}` &&
					    currentPathWithoutHash !== `${INTERNAL_LINKS.Login}`        &&
						currentPathWithoutHash !== `${INTERNAL_LINKS.AccountManagment}`      ) &&
                      (
                        ( authentication.login === "" )
                        ?
                        (
                          <>
                            <Link to = {INTERNAL_LINKS.Cart} >
                              <button className = {classNames(styles.button)} >PANIER</button>
                            </Link>

                            <Link to = {INTERNAL_LINKS.Login} >
                              <button className = {classNames(styles.button)} >ME CONNECTER</button>
                            </Link>

                            <Link to = {INTERNAL_LINKS.Subscription} >
                              <button className = {classNames(styles.button)} >CRÉER MON COMPTE</button>
                            </Link>
                          </>
							
                        )
                        :
                        (
                          <>
						  
                            <Link to = {INTERNAL_LINKS.Cart} >
                              <button className = {classNames(styles.button)} >PANIER</button>
                            </Link>

                            <Link to = {INTERNAL_LINKS.AccountManagment} >
                              <button className = {classNames(styles.button)} >{user.firstname}</button>
                            </Link>

                            <button className = {classNames(styles.button)} 
							        onClick   = {handleLogoutClick} >ME DÉCONNECTER</button>

                          </>
                        )
                      )
                    )
                  }

                  {/*
                    ( 
					  ( currentPathWithoutHash === `${INTERNAL_LINKS.Subscription}` ) &&
                      (
                        ( authentication.login === "" )
                        ?
                        (
						   <>
                            <Link to = {INTERNAL_LINKS.Cart} >
                              <button className = {classNames(styles.button)} >PANIER</button>
                            </Link>

                            <Link to = {INTERNAL_LINKS.Login} >
                              <button className = {classNames(styles.button)} >ME CONNECTER</button>
                            </Link>
						   </>	
                        )
                        :
                        (
                          <>
						  
                            <Link to = {INTERNAL_LINKS.Cart} >
                              <button className = {classNames(styles.button)} >PANIER</button>
                            </Link>

                            <Link to = {INTERNAL_LINKS.AccountManagment} >
                              <button className = {classNames(styles.button)} >{user.firstname}</button>
                            </Link>

                            <button className = {classNames(styles.button)} 
							        onClick   = {handleLogoutClick} >ME DÉCONNECTER</button>

                          </>
                        )
                      )
                    )
                  */}

                  {
                    (
					  ( currentPathWithoutHash === `${INTERNAL_LINKS.Subscription}` ) &&
                      (
                      <></>
                      )
                    )
                  }

                  {
                    ( 
					  ( currentPathWithoutHash === `${INTERNAL_LINKS.Login}` ) &&
                      (
                        ( authentication.login === "" )
                        ?
                        (
						<>
                            <Link to = {INTERNAL_LINKS.Cart} >
                              <button className = {classNames(styles.button)} >PANIER</button>
                            </Link>

                            <Link to = {INTERNAL_LINKS.Subscription} >
                              <button className = {classNames(styles.button)} >CRÉER MON COMPTE</button>
                            </Link>
						</>	
                        )
                        :
                        (
                          <>
                           { /* S'il est connecté, on va automatiquement sur le compte */ }
                          </>
                        )
                      )
                    )
                  }

                  {
                    ( ( currentPathWithoutHash === `${INTERNAL_LINKS.AccountManagment}` ) &&
                      (
					  <>
                             <Link to = {INTERNAL_LINKS.Cart} >
                              <button className = {classNames(styles.button)} >PANIER</button>
                            </Link>

                       <button className = {classNames(styles.button)} 
						        onClick   = {handleLogoutClick} >ME DÉCONNECTER</button>
								
								</>
                      )
                    )
                  }
				  
                </div>

              </div>

          
         );
    } 
		

  return (
  
           <>
		   
		     { ( isSmallScreen ) ? ( renderSmallVersion() ) : ( renderBigVersion() ) }
			 
			   {
	    alertWindowVisible &&
	    ( < Window message               = { <><span className = {classNames(styles.bold_text)}>Vous devez être inscrit pour accéder au live.</span><br/><br/>Vous inscrire ?<br/></> }
                   messageType           = "normal"
                   onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                   onClickOnValidButton  = {closeAlertWindowWithValidButton}
                   cancelButtonName      = "NON"
                   validButtonName       = "OUI"   /> )
	  }

		   </>
   
         )   
		
};

export default Header;






				  
