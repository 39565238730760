export enum ActionType
 {
  INIT_PRODUCT_CATALOG         = 'INIT_PRODUCT_CATALOG'         ,
  SET_PRODUCT_CATALOG          = 'SET_PRODUCT_CATALOG'          ,
 }

export interface Characteristic {
  id:                 number ,
  name:               string ,
  kind:               string ,
  unit:               string ,
  value:              string 
}

export interface Price {

  id:                            number ,
  name:                          string ,
  requiredStatus:                string ,
  comment:                       string ,
  code:                          string ,
  creationDate:                  string ,
  basePrice:                     number ,
  taxRate:                       number ,
  discountWithStatusType:        string ,
  discountWithStatusValue:       number ,
  discountWithSpecialEventType:  string ,
  discountWithSpecialEventValue: number ,
  priceToPay:                    number
}

export interface ProductVariant {

  id:                 number ,
  name:               string ,
  complement:         string ,
  description:        string ,  
  creationDate:       string ,
  businessUnitCode:   string ,
  productVariantCode: string ,
  characteristics:    Characteristic [],
  prices:             Price []
}

export interface Product {

  id:                   number ,
  name:                 string ,
  code:                 string ,
  requiredStatus:       string ,
  kind:                 string ,   // Pour PhysicalItem, VideoMasterClass...
  description:          string ,  
  creationDate:         string ,
  variants:             ProductVariant [] ,
  characteristics:      Characteristic []
}

export interface ProductCategory
 {
  name:     string ,
  products: Product[]
 }

export interface ProductCatalog
 {
  categories: ProductCategory[]
 }

export interface actionInitProductCatalog
 {
  type:                   ActionType.INIT_PRODUCT_CATALOG;
 }

export interface actionSetProductCatalog
 {
  type:                   ActionType.SET_PRODUCT_CATALOG;
  newCatalog:             ProductCatalog;
 }

export type Action = actionInitProductCatalog        |
                     actionSetProductCatalog;

export type DispatchTypeInitProductCatalog = ( args: actionInitProductCatalog ) => actionInitProductCatalog
export type DispatchTypeSetProductCatalog  = ( args: actionSetProductCatalog  ) => actionSetProductCatalog
					 
