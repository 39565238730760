import   axios                         from 'axios';

import { format                     ,
         parse                      ,
         startOfWeek                , 
		 addDays                    }  from 'date-fns';
		 
import { Catalog                    ,
         Category                   ,
		 VideoSet                   ,
		 Product                    ,
		 Video                      ,
         BroadcastingChannelPackage ,
         BroadcastingChannel        ,
         BroadcastedVideo           }  from '../datas/videoManagment/actionTypes';

import { getUserAgentInformations   ,
         getAppInformations         }  from './utils';	   

import { API_URLS                   }  from './urls';


export interface CatalogInfos {
  code:     number,
  comment:  string,
  catalog:  Catalog
}

export const getCatalogInfos = async ( userStatus: number ) : Promise<CatalogInfos>  => {

    const url = `${API_URLS.getCatalogInfos}`;

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
          'User-Agent-Informations': `${userAgentInformations}`,
	      'App-Informations': `${appInformations}`,
    };

    var result:CatalogInfos = {
      code:    0 ,
	  comment: "" ,
      catalog: {
        categories: []
      }
    };

    try {

      const response = await axios.get ( url, { headers: headers } );
	  
      const statut = response.status;

      if ( statut === 200  )
             {
			  const rawInfos_catalog = response.data
			  
			  let catalog: Catalog = {
                    categories: []
              }
			  
			  // Ajout de la catégorie spéciale "Nouveauté"
			  
			  let category_news: Category = {
                      name:      'Nouveautés' ,
                      videoSets: []
			  }
			  
			  catalog.categories.push ( category_news );
			  
			  for (let i = 0; i < rawInfos_catalog.length; i++) 
			   {
                const rawInfos_category = rawInfos_catalog[i];

			    let category: Category = {
                      name:      rawInfos_category.name ,
                      videoSets: []
				}

			    for (let j = 0; j < rawInfos_category.videoSet.length; j++) 
			     {
                  const rawInfos_videoSet = rawInfos_category.videoSet[j];
				 
				  // On ajoute un vidéo set fictif spécial
				  
		          let videoSetNews: VideoSet = {
                      name:     'Nouveauté' ,
                      products: [] ,
                      videos:   []
				  }

                  catalog.categories[0].videoSets.push ( videoSetNews );

                  ///////////////////////////////////
				 
			      let videoSet: VideoSet = {
                        name:     rawInfos_videoSet.name ,
                        products: [] ,
                        videos:   []
				  }
				  
			      for (let k = 0; k < rawInfos_videoSet.products.length; k++) 
			       {
                    const rawInfos_product = rawInfos_videoSet.products[k];

			        let product: Product = {
					      id:            rawInfos_product.id,
                          name:          rawInfos_product.name
				    }
					
					videoSet.products.push (product)
					
					// Dans le videoSet associé aux nouveautés, on regarde s'il y a déjà un produit
					// Sinon on l'affecte => à priori, pas utile
					
					// if ( catalog.categories[0].videoSets[videoSetNewsNumber].products.length === 0 )
					 // {
					  // catalog.categories[0].videoSets[videoSetNewsNumber].products.push (product)
					 // }
					 
					/////////////////////////
				   }
				  
				  
				  /*
				  
				  MariaDB [crowfunding]> select * from Product;
+----+-------------------------------------------------+------+---------------------+--------------+--------+
| id | name                                            | code | creationDate        | productType  | active |
+----+-------------------------------------------------+------+---------------------+--------------+--------+
|  1 | Offre gratuite sans inscription                 | 001  | 2024-03-07 20:10:36 | freeProduct  |      1 |
|  2 | Offre Lovelight.TV découverte avec inscription  | 002  | 2024-03-07 20:10:36 | freeProduct  |      1 |
|  3 | Offre Lovelight.TV standard                     | 003  | 2024-04-30 11:30:00 | subscription |      1 |
|  4 | Offre Lovelight.TV premium                      | 004  | 2024-04-30 11:30:00 | subscription |      1 |
+----+-------------------------------------------------+------+---------------------+--------------+--------+
				  
				  */
				  
			      for (let l = 0; l < rawInfos_videoSet.videos.length; l++) 
			       {
                    const rawInfos_video = rawInfos_videoSet.videos[l];

                    let toBeLocked:boolean = true;

                    switch ( userStatus )
					 {
					  case -1 : // Personne non connectée
					  
					            if ( videoSet.products[0].id === 1 )  // Que les vidéos associées au produit 1
							          {
								       toBeLocked = false;
								      }
								 else {
								       toBeLocked = true;
								      }
					  
					           break;
					 
					  case 0 : // Personne connectée mais sans abonnement
					  
					           if ( videoSet.products[0].id === 1 || 
							        videoSet.products[0].id === 2 )   // Que les vidéos associées aux produits 1 et 2
							          {
								       toBeLocked = false;
								      }
								 else {
								       toBeLocked = true;
								      }
					  
					           break;
					  
					  case 1 : // Personne connectée mais avec abonnement standard

                               if ( videoSet.products[0].id === 1 || 
							        videoSet.products[0].id === 2 || 
							        videoSet.products[0].id === 3 )   // Que les vidéos associées aux produits 1, 2 et 3
							          {
								       toBeLocked = false;
								      }
								 else {
								       toBeLocked = true;
								      }
					  
					           break;

					  case 2 : // Personne connectée mais avec abonnement standard

                               if ( videoSet.products[0].id === 1 || 
							        videoSet.products[0].id === 2 || 
							        videoSet.products[0].id === 3 || 
							        videoSet.products[0].id === 4 )   // Que les vidéos associées aux produits 1, 2, 3 et 4
							          {
								       toBeLocked = false;
								      }
								 else {
								       toBeLocked = true;
								      }
					  
					 }

			        let video: Video = {
					      id:                   rawInfos_video.id ,
                          title:                rawInfos_video.title ,
                          shortTitle:           rawInfos_video.shortTitle ,
                          complementToTheTitle: rawInfos_video.complementToTheTitle ,
                          description:          rawInfos_video.description ,
                          duration:             rawInfos_video.duration ,
                          distribution:         rawInfos_video.distribution ,
                          production:           rawInfos_video.production ,
                          copyright:            rawInfos_video.copyright ,
                          releaseDate:          rawInfos_video.releaseDate ,
                          ranking:              rawInfos_video.ranking ,
                          numberOfViews:        rawInfos_video.numberOfViews ,
                          imageFile:            rawInfos_video.imageFile,
                          videoFile:            rawInfos_video.videoFile,
                          visible:              rawInfos_video.visible,
						  locked:               toBeLocked,
						  computedDuration:     convertTimeToSeconds (rawInfos_video.duration)
				    }

                    if ( rawInfos_video.visible )
					 {
					  videoSet.videos.push (video)
					
					  // On ajoute les vidéos au vidéo set selon un critère à définir.....
					
  					  const now = new Date(); // Date courante
                      const oneMonthAgo = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()); // Date il y a un mois
                      const videoDate = new Date(rawInfos_video.releaseDate);
                    
					  if ( videoDate > oneMonthAgo )
					   {
					    let exist: boolean = false;
				  
				        for (let vid = 0; vid < catalog.categories[0].videoSets[0].videos.length; vid++) 
			             {
				          const temp_video = catalog.categories[0].videoSets[0].videos[vid];
					
					      if ( temp_video.title === rawInfos_video.title )
					       {
					        exist = true;
					        break;
					       }
				         }
				 
				        if ( !exist )
					     {
					      catalog.categories[0].videoSets[0].videos.push (video)
					     }
					   }
					
					  //////
					 }
				   }
				  
				  category.videoSets.push (videoSet);
                 }
				 
				catalog.categories.push ( category );
 			   }
			   
			  result.catalog = catalog;
             }
        else {
              result = {
                code:    -101,
                comment: `Erreur lors de la récupération des informations de paiement de l'utilisateur : ${response.status}`,
                catalog: {
                  categories: []
                }
              };
             }


    }
    catch (error) {

     result = {
       code:    -201,
 	   comment: "Erreur réseau",
       catalog: {
         categories: []
       }
     };
    }

    return result;
  };

export const sortVideosByDate = (videos: Video[]): Video[] => {
  
  return videos.sort((a, b) => {
    
    const dateA = new Date(a.releaseDate);
    const dateB = new Date(b.releaseDate);

    return dateB.getTime() - dateA.getTime();
  });
};

export interface BroadcastingChannelPackageInfos {
  code:     number,
  comment:  string,
  broadcastingChannelPackage:  BroadcastingChannelPackage
}

export const getBroadcastingChannelPackageInfos = async ( premiumAccess: boolean ) : Promise<BroadcastingChannelPackageInfos>  => {

    const now      = new Date();
    const sendDate = now; // add(now, { hours: 2 });
	
    const dateFormat = "yyyy/MM/dd HH:mm:ss";

	const baseUrl  =  `${API_URLS.getBroadcastingChannelPackageInfos}?`;

    const name1  = "batchBeginningDate="; 
    const name2  = "batchDuration=";
    const value1 = encodeURIComponent ( format ( sendDate, dateFormat ) );  // encodeURIComponent ("now");      
    const value2 = encodeURIComponent ( "12:00:00"                       ); 

    var url = `${baseUrl}${name1}${value1}&${name2}${value2}`;
	

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
          'User-Agent-Informations': `${userAgentInformations}`,
	      'App-Informations': `${appInformations}`,
    };

    var result:BroadcastingChannelPackageInfos = {
      code:    0 ,
	  comment: "" ,
      broadcastingChannelPackage: {
        channels: []
      }
    };


    try {

      const response = await axios.get ( url, { headers: headers } );

      const statut = response.status;

      if ( statut === 200  )
             {
			  const rawInfos_broadcastingChannels = response.data

			  let broadcastingChannelPackage: BroadcastingChannelPackage = {
                channels: []
              }


			  for (let i = 0; i < rawInfos_broadcastingChannels.length; i++)
			   {
                const rawInfos_broadcastingChannel = rawInfos_broadcastingChannels[i];

			    let broadcastingChannel: BroadcastingChannel = {
                      name:               rawInfos_broadcastingChannel.name ,
                      broadcastedVideos:  []
				}

			  
			    for (let j = 0; j < rawInfos_broadcastingChannel.broadcastedVideos.length; j++)
			     {
                  const rawInfos_broadcastedVideo = rawInfos_broadcastingChannel.broadcastedVideos[j];

				
			      let broadcastedVideo: BroadcastedVideo = {
                    video:     {
					  id:                   rawInfos_broadcastedVideo.video.id,
                      title:                rawInfos_broadcastedVideo.video.title ,
                      shortTitle:           rawInfos_broadcastedVideo.video.shortTitle ,
                      complementToTheTitle: rawInfos_broadcastedVideo.video.complementToTheTitle ,
                      description:          rawInfos_broadcastedVideo.video.description ,
                      duration:             rawInfos_broadcastedVideo.video.duration ,
                      distribution:         rawInfos_broadcastedVideo.video.distribution ,
                      production:           rawInfos_broadcastedVideo.video.production ,
                      copyright:            rawInfos_broadcastedVideo.video.copyright ,
                      releaseDate:          rawInfos_broadcastedVideo.video.releaseDate ,
                      ranking:              rawInfos_broadcastedVideo.video.ranking ,
                      numberOfViews:        rawInfos_broadcastedVideo.video.numberOfViews ,
                      imageFile:            rawInfos_broadcastedVideo.video.imageFile,
                      videoFile:            rawInfos_broadcastedVideo.video.videoFile,
                      visible:              true,
					  locked:               false,
					  computedDuration:     convertTimeToSeconds (rawInfos_broadcastedVideo.video.duration)
                    },
                    broadcastDate: parse ( rawInfos_broadcastedVideo.broadcastDate, dateFormat, new Date())
				  }


				  broadcastingChannel.broadcastedVideos.push (broadcastedVideo);
                 }

				broadcastingChannelPackage.channels.push ( broadcastingChannel );
 			   }

			  result.broadcastingChannelPackage = broadcastingChannelPackage;
             }
        else {
              result = {
                code:    -101,
                comment: `Erreur lors de la récupération des informations concernant les chaînes de diffusion : ${response.status}`,
                broadcastingChannelPackage: {
                  channels: []
                }
              };
             }
    }
    catch (error) {


     result = {
       code:    -201,
 	   comment: "Erreur réseau",
       broadcastingChannelPackage: {
         channels: []
       }
     };
    }

    return result;
  };


export interface PostViewerNotificationResult {
  code:    number,
  comment: string
}

export const postViewerNotification = async ( videoId:     number ,
							                  eventName:   string ,
							                  currentTime: number ,
							                  duration:    number ,
											  login:       string ): Promise<PostViewerNotificationResult> => {

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json',
	  'User-Agent-Informations': `${userAgentInformations}`,
	  'App-Informations': `${appInformations}`,
    };
	
	var result:PostViewerNotificationResult = {
      code:    0,
      comment: ""
    };

    const eventDateStr: string = format( new Date () , "yyyy-MM-dd HH:mm:ss" );

    try {

        const response = await axios.post   (
        
                                               `${API_URLS.postViewerNotification}`,
                              
                                               { 
                                                 videoId      ,
                                                 eventDateStr ,
                                                 eventName    ,
                                                 currentTime  ,
                                                 duration     ,
                                                 login												 
                                               },
                                            
                                               {
                                                 headers: headers
                                               }
                                            );
											
		if ( response.status === 201 )
		      { 
			   result = {
		         code:    1,
		         comment: "Event envoyé"
		       };
			  }
		 else {
			   result = {
		         code:    -101,
		         comment: `Erreur lors de la création : ${response.status}`
		       };
			  }

    } 
    catch ( error ) {

        result = {
		  code:    -201,
		  comment: "Erreur réseau"
		};    
		
		
	}

	return result;
};




const getDateForDayAndTime = (day: string, time: string): Date | null => {

    const daysOfWeek = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];
    const dayIndex = daysOfWeek.indexOf(day.toLowerCase());

    if (dayIndex === -1) {
        console.error("Jour invalide.");
        return null;
    }

    const currentDate = new Date();
    const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 }); // Commence la semaine le lundi
    const targetDay = addDays(startOfCurrentWeek, dayIndex);

    const [hours, minutes] = time.split(':').map(Number);
    targetDay.setHours(hours);
    targetDay.setMinutes(minutes);

    return targetDay;
}


const convertTimeToSeconds = (time: string): number => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
};



