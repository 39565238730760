import   React                                        ,
       { useState                                     ,
         useEffect                                    }  from 'react';

import { useNavigate                                  }  from 'react-router-dom';

import { Dispatch                                     }  from 'redux';
import { useDispatch                                  }  from 'react-redux';

import   classNames                                      from 'classnames';

import { formatInTimeZone                             }  from 'date-fns-tz'

import   styles                                          from './subscriptions_and_services.module.scss';

import { useTypedSelector                             }  from '../../../../../datas/useTypeSelector';

import { Product                                      }  from '../../../../../datas/productManagment/actionTypes';

import { getProductInfos                              }  from '../../../../../network/api_product';
import { postSubscription                             }  from '../../../../../network/api_payment';
import { patchUserCurrentOfferTermination             ,
         patchUserCurrentOfferTerminationCancellation }  from '../../../../../network/api_user';

import { ProductChoice4                               }  from '../../../../block/product/product_choice4';

import { Window                                       }  from '../../../../widget/window/window';

import { initAuthentication                           }  from '../../../../../datas/authenticationManagment/actionCreators';
import { initUser                                     ,
         setUser                                      }  from '../../../../../datas/userManagment/actionCreators';

import { INTERNAL_LINKS                               }  from '../../../../../network/urls';

import { postEvent                                    }  from '../../../../../network/api_events_stats';


export const SubscriptionsAndServices = () => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate = useNavigate();

  const { user   } = useTypedSelector ( (state) => state.userManagment   );
  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  
  const [ action , setAction ] = useState(0);
  const [ price  , setPrice  ] = useState(0);

  const [ showWindow            , setShowWindow         ] = useState ( false );
  const [ windowMessage         , setWindowMessage      ] = useState ( <></> );
  const [ expiredSession        , setExpiredSession     ] = useState ( false );

  useEffect(() => {
  
    if ( authentication.login === "" || 
         authentication.token === "" )
     {
      dispatch ( initUser () )

      navigate ("/connexion");
     }

  }, []);
  
  const handleActionChange = (id: number, price: number) => {

    setAction (id)
    setPrice  (price)
  }

  const handleValidButtonClick = () => {

    makeStatusUpdate ()
  }

  const handleCloseButtonClick = () => {

    setWindowMessage (<></>);
    setShowWindow	 (false)

    if ( expiredSession )
     {
	  dispatch ( initUser   () );
	  dispatch ( initAuthentication (true) );
      navigate ( `${INTERNAL_LINKS.Home}` );
	 }
  }

  const handleOfferTerminationCancellation = () => {

    makeOfferTerminationCancellation ()
  }

  const makeStatusUpdate = async () => {

         if ( action === 1 || action < 0 )
          {
           // action  1 : On a choisi "Offre découverte"
           // action -2 : On résilie "Offre standard"
           // action -3 : On résilie "Offre premium"

           const response = await patchUserCurrentOfferTermination ( authentication.token ,
                                                                     user.id              )

           switch ( response.code )
	            {
                 case 1    : // L'offre découverte sera activée à la fin de votre offre actuel le **'

                             dispatch ( setUser   ( user.id                                   ,
                                                    user.email                                ,
                                                    user.newEmail                             ,
								                    user.firstname                            ,
								                    user.lastname                             ,
								                    user.sex                                  ,
								                    user.birthday                             ,
								                    user.address                              ,
								                    user.address2                             ,
								                    ""                                        ,
								                    user.city                                 ,
								                    user.country                              ,
								                    user.phoneNumber                          ,
								                    response.status                as string  ,
								                    response.statusDeadline        as Date    ,
								                    response.nextStatus            as string  ,
								                    response.nextStatusRequestDate as Date    ,
                                                    user.state                                ) )

                             setWindowMessage (<>La demande de résilitation est enregistrée</>);
                             setShowWindow    (true)

                             break;

	             case -102 : setWindowMessage (<>Votre sessions a expiré, veuillez vous reconnecter</>);
	                         setShowWindow    (true)
                             setExpiredSession (true);

				             break;

                 default :   setWindowMessage (<>Une erreur réseau est survenue lors de la génération de la demande de paiement</>);
                             setShowWindow    (true)
	             }


          }
    else if ( action === 2 || action === 3 )
          {
           // Les processeurs de ces 2 offres font la distinction entre achat et modif en fonction du status actuel (discover ou non)

           const response = await postSubscription (
                                                     price                                    , // Montant en centimes d'euros
                                                     ( action === 2 ? 6900 : 11900 ) - price  , // Montant de la réduction
                                                     "TV"                                     , // Code du business unit "Lovelight TV"
                                                     action === 2 ? "1" : "3"                 , // Code des product vatiant "Offre standard annuel" / "ffre premium annuel"
                                                     "001"                                    , // Code du prix appliquée à cette vente
                                                     "01"                                     , // Code de bank "BNP - Axepta"
                                                     authentication.login                     , // Email du user
                                                     "Changement de status"                   , // Commentaire
                                                     authentication.token                       // Token JWT
                                                   )

           switch ( response.code )
	            {
                 case 1    : window.location.href = response.url;  // Amène à la page de paiement

			                 break;

	             case -102 : setWindowMessage (<>Votre sessions a expiré, veuillez vous reconnecter</>);
	                         setShowWindow    (true)
                             setExpiredSession (true);

				             break;

                 default :   setWindowMessage (<>Une erreur réseau est survenue lors de la génération de la demande de paiement</>);
                             setShowWindow    (true)
	            }
          }

  }

  const makeOfferTerminationCancellation = async () => {

    const response = await patchUserCurrentOfferTerminationCancellation ( authentication.token ,
                                                                          user.id      )
    switch ( response.code )
	 {
      case 1    : // L'offre découverte sera activée à la fin de votre offre actuel le **'

                             dispatch ( setUser   ( user.id                                   ,
                                                    user.email                                ,
                                                    user.newEmail                             ,
								                    user.firstname                            ,
								                    user.lastname                             ,
								                    user.sex                                  ,
								                    user.birthday                             ,
								                    user.address                              ,
								                    user.address2                             ,
								                    ""                                        ,
								                    user.city                                 ,
								                    user.country                              ,
								                    user.phoneNumber                          ,
								                    response.status                as string  ,
								                    response.statusDeadline        as Date    ,
								                    response.nextStatus            as string  ,
								                    response.nextStatusRequestDate as Date    ,
                                                    user.state                                ) )

                  setWindowMessage (<>La demande d'annulation de la demande de résilitation est enregistrée</>);
                  setShowWindow    (true)

                  break;

	  case -102 : setWindowMessage (<>Votre sessions a expiré, veuillez vous reconnecter</>);
	              setShowWindow    (true)
                  setExpiredSession (true);

				  break;

      default :   setWindowMessage (<>Une erreur réseau est survenue lors de la demande d'annulation de la demande de résiliation de votre offre courante</>);
                  setShowWindow    (true)
	 }
  }

  const getCurrentOfferNumber = (): number =>
   {
         if ( user.status === "Standard" ) { return 1 }
	else if ( user.status === "Premium"  ) { return 2 }
    else                                           { return 0 }	
   }

  const getNextOfferNumber = (): number =>
   {
         if ( user.nextStatus === "Discover" ) { return  0 }
	else if ( user.nextStatus === "Standard" ) { return  1 }
    else if ( user.nextStatus === "Premium"  ) { return  2 }
	else                                               { return -1 }
   }

  return (
            
           <div className={classNames(styles.root)}>

               <p className={classNames(styles.title)}>
                 ABONNEMENTS ET SERVICES
               </p>
              
               <br/>

               <ProductChoice4 onActionChange                 = { handleActionChange }
                               onValidButtonClick             = { handleValidButtonClick }
                               onOfferTerminationCancellation = { handleOfferTerminationCancellation }
                               currentOfferNumber             = { getCurrentOfferNumber() }
							   currentOfferDeadline           = { user.statusDeadline }
							   nextOfferNumber                = { getNextOfferNumber () }
							   offerModificationRequestDate   = { user.nextStatusRequestDate } />

               {
			     showWindow
				 &&
                 (
				   < Window message               = { windowMessage          }
				            onClickOnCloseButton  = { handleCloseButtonClick }
				            opaqueBackgroundColor = "#333333"/>
				 )
		       }

           </div>
           
       );
};


export default SubscriptionsAndServices;
