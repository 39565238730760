import   React                 ,  
       { useState              ,
	     useEffect             }  from 'react';

import { useNavigate           }  from 'react-router-dom';

import { Dispatch              }  from 'redux';

import { useDispatch           }  from 'react-redux';

import   classNames               from 'classnames';

import   styles                   from './personal_informations.module.scss';

import { Field                 }  from '../../../../widget/field/field';
import { Select                }  from '../../../../widget/select/select';
import { DateSelector          }  from '../../../../widget/date_selector/date_selector';
import { WidgetFrame           }  from '../../../../widget/widget_frame/widget_frame';
import { Window                }  from '../../../../widget/window/window';

import { updateFirstname       , 
         updateLastname        , 
         updateSex             , 
         updateBirthday        , 
         updateAddress         , 
         updateAddress2        , 
         updateZipCode         , 
         updateCity            , 
         updateCountry         ,
         updatePhoneNumber     }  from '../../../../../datas/userManagment/actionCreators';
		 
import { Validation            ,
         checkFirstname        ,
         checkLastname         ,
         checkSex              ,
         checkBirthday         ,
         checkAddress          ,
         checkAddress2         ,
         checkZipCode          ,
         checkCity             ,
         checkCountry          ,
         checkPhoneNumber      }  from '../../../../../datas/userManagment/validators';	
		 
import { useTypedSelector      }  from '../../../../../datas/useTypeSelector';

import { initAuthentication    }  from '../../../../../datas/authenticationManagment/actionCreators';
import { initUser      }  from '../../../../../datas/userManagment/actionCreators';

import { INTERNAL_LINKS        }  from '../../../../../network/urls';

import { patchUserInformations }  from '../../../../../network/api_user';

export const PersonalInformations = () => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );
  const { user   } = useTypedSelector ( (state) => state.userManagment   );

  const [ firstname             , setFirstname          ] = useState ( user.firstname   );
  const [ lastname              , setLastname           ] = useState ( user.lastname    );
  const [ sex                   , setSex                ] = useState ( user.sex === "" ? "Aucune réponse" : user.sex );
  const [ birthday              , setBirthday           ] = useState ( user.birthday    );
  const [ address               , setAddress            ] = useState ( user.address     );
  const [ address2              , setAddress2           ] = useState ( user.address2    );
  const [ zipCode               , setZipCode            ] = useState ( user.zipCode     );
  const [ city                  , setCity               ] = useState ( user.city        );
  const [ country               , setCountry            ] = useState ( user.country === "" ? "France" : user.country );
  const [ phoneNumber           , setPhoneNumber        ] = useState ( user.phoneNumber );
  
  const [ messageFirstname      , setMessageFirstname   ] = useState ('');
  const [ messageLastname       , setMessageLastname    ] = useState ('');
  const [ messageSex            , setMessageSex         ] = useState ('');
  const [ messageBirthday       , setMessageBirthday    ] = useState ('');
  const [ messageAddress        , setMessageAddress     ] = useState ('');
  const [ messageAddress2       , setMessageAddress2    ] = useState ('');
  const [ messageZipCode        , setMessageZipCode     ] = useState ('');
  const [ messageCity           , setMessageCity        ] = useState ('');
  const [ messageCountry        , setMessageCountry     ] = useState ('');
  const [ messagePhoneNumber    , setMessagePhoneNumber ] = useState ('');
    
  const [ editMode              , setEditMode           ] = useState ( false );
  
  const [ showWindow            , setShowWindow         ] = useState ( false );
  const [ windowMessage         , setWindowMessage      ] = useState ( <></> );
  const [ expiredSession        , setExpiredSession     ] = useState ( false );


  const [ isSmallScreen         , setIsSmallScreen      ] = useState<boolean>(false);

  const switchWidthLimit = 870;

  const handleResize = () => {
    setIsSmallScreen ( window.innerWidth <= switchWidthLimit );
  };

  useEffect(() => {

    if ( authentication.login === "" || 
         authentication.token === "" )
     {
	  dispatch ( initUser () )
	  
      navigate ("/connexion");
     }

  }, [isSmallScreen]); 


  useEffect(() => {

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
	
  }, []); 

  const handleFirstnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setFirstname    ( e.target.value ) 
    dispatch ( updateFirstname ( e.target.value ) )
    
	const retour: Validation = checkFirstname  ( e.target.value ) 
	setMessageFirstname ( retour.comment );
  };

  const handleLastnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setLastname    ( e.target.value ) 
    dispatch ( updateLastname ( e.target.value ) )
    
	const retour: Validation = checkLastname  ( e.target.value ) 
	setMessageLastname ( retour.comment );
  };

  const handleSexChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setSex    ( e.target.value ) 
    dispatch ( updateSex ( e.target.value ) )
    
	const retour: Validation = checkSex  ( e.target.value ) 
	setMessageSex ( retour.comment );
  };

  const handleBirthdayChange = ( newBirthday: string ) => {

    setBirthday    ( newBirthday ) 
    dispatch ( updateBirthday ( newBirthday ) )
    
	const retour: Validation = checkBirthday  ( newBirthday ) 
	setMessageBirthday ( retour.comment );
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setAddress    ( e.target.value ) 
    dispatch ( updateAddress ( e.target.value ) )
    
	const retour: Validation = checkAddress  ( e.target.value ) 
	setMessageAddress ( retour.comment );
  };

  const handleAddress2Change = (e: React.ChangeEvent<HTMLInputElement>) => {

    setAddress2    ( e.target.value ) 
    dispatch ( updateAddress2 ( e.target.value ) )
    
	const retour: Validation = checkAddress2  ( e.target.value ) 
	setMessageAddress2 ( retour.comment );
  };
    
  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setZipCode    ( e.target.value ) 
    dispatch   ( updateZipCode ( e.target.value ) )
    
	const retour: Validation = checkZipCode  ( e.target.value ) 
	setMessageZipCode ( retour.comment );
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setCity    ( e.target.value ) 
    dispatch ( updateCity ( e.target.value ) )
    
	const retour: Validation = checkCity  ( e.target.value ) 
	setMessageCity ( retour.comment );
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setCountry    ( e.target.value )
    dispatch ( updateCountry ( e.target.value ) )
    
	const retour: Validation = checkCountry  ( e.target.value ) 
	setMessageCountry ( retour.comment );
  };

  const handlePhoneNumberChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    setPhoneNumber    ( e.target.value ) 
    dispatch ( updatePhoneNumber ( e.target.value ) )
    
	const retour: Validation = await checkPhoneNumber  ( e.target.value , user.email )
	setMessagePhoneNumber ( retour.comment );
  };

  const handleEditButtonClick = () => {

    if (editMode)
	 {
      setMessageFirstname   ('');
      setMessageLastname    ('');
      setMessageSex         ('');
      setMessageBirthday    ('');
      setMessageAddress     ('');
      setMessageAddress2    ('');
      setMessageZipCode     ('');
      setMessageCity        ('');
      setMessageCountry     ('');
      setMessagePhoneNumber ('');
	 }
	 
    setEditMode (!editMode);   
  }

  const handleValidateButtonClick = () => {

    makeUpdate ();   
  }

  const checkAll = async (): Promise<boolean> => {
  
	const retour04 =       checkFirstname   ( firstname ) 
	const retour05 =       checkLastname    ( lastname ) 
	const retour06 =       checkSex         ( sex ) 
	const retour07 =       checkBirthday    ( birthday ) 
	const retour08 =       checkAddress     ( address ) 
	const retour09 =       checkAddress2    ( address2 ) 
	const retour10 =       checkZipCode     ( zipCode ) 
	const retour11 =       checkCity        ( city ) 
	const retour12 =       checkCountry     ( country ) 
	const retour13 = await checkPhoneNumber ( phoneNumber , user.email )

	setMessageFirstname   ( retour04.comment );
	setMessageLastname    ( retour05.comment );
	setMessageSex         ( retour06.comment );
	setMessageBirthday    ( retour07.comment );
	setMessageAddress     ( retour08.comment );
	setMessageAddress2    ( retour09.comment );
	setMessageZipCode     ( retour10.comment );
	setMessageCity        ( retour11.comment );
	setMessageCountry     ( retour12.comment );
	setMessagePhoneNumber ( retour13.comment );
	
	const code:number = retour04.code + 
	                    retour05.code + 
	                    retour06.code + 
	                    retour07.code + 
	                    retour08.code + 
	                    retour09.code + 
	                    retour10.code + 
	                    retour11.code + 
	                    retour12.code + 
	                    retour13.code;

    return ( code === 10 );						

  }

  const handleCloseButtonClick = () => {

    setEditMode   (false);
	setWindowMessage ( <></>);
    setShowWindow ( false );

    if ( expiredSession )
     {
	  dispatch ( initUser   () );
	  dispatch ( initAuthentication (true) );
      navigate ( `${INTERNAL_LINKS.Home}` );
	 }	
  }

  const makeUpdate = async () => {
 
    const check = await checkAll ()
 
    if ( check )
	 {
      const response = await patchUserInformations ( authentication.token      ,
	                                                 user.id           ,
	                                                 user.firstname    ,
                                                     user.lastname     ,
                                                     user.sex          ,
                                                     user.birthday     ,
                                                     user.address      ,
                                                     user.address2     ,
                                                     user.zipCode      ,
                                                     user.city         ,
                                                     user.country      ,
                                                     user.phoneNumber  )
									
	  switch ( response.code )
	   {
	    case 1    : setWindowMessage ( <>Le compte a été modifié</> );
                    setShowWindow ( true );
		
		            break;
				 
		case -102 : setWindowMessage ( <>Votre session a expiré, veuillez vous reconnecter.</> );
                    setShowWindow ( true );

		            setExpiredSession (true);
					
		            break;
		
		default   : setWindowMessage ( <>Le compte n'a pas pu être modifié car une erreur réseau est survenue</> );
                    setShowWindow ( true );

	   }
     }	 
   }

  const renderSmallSizeComponent = (): JSX.Element => {

    return  <>
	
	           <p className={classNames(styles.title)}>
                 INFORMATIONS PERSONNELLES
               </p>
              
			   <br/>
			  
               <div className={classNames(styles.form)}>

                 <div className={classNames(styles.layout_2_line_1)}>

			       <WidgetFrame name               = "Prénom"
                                error              = {messageFirstname}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top"   >

                    {
                      (
					    editMode
				  	  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
								value         = {firstname}
                                onChange      = {handleFirstnameChange} />
					  )
					  :
					  (
					    <>{firstname}</>
					  )
                    }
				  
				  </WidgetFrame>
				  
                 </div>

                 <div className={classNames(styles.layout_2_line_2)}>

			       <WidgetFrame name               = "Nom"
                                error              = {messageLastname}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top" >

                    {
                      (
					    editMode
				  	  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
								value         = {lastname}
                                onChange      = {handleLastnameChange} />
					  )
					  :
					  (
					    <>{lastname}</>
					  )
                    }

				  </WidgetFrame>

                 </div>


                 <div className={classNames(styles.layout_2_line_3)}>

			       <WidgetFrame name               = "Genre"
                                error              = {messageSex}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Select choices       = { [
                                                    "Aucune réponse",
                                                    "Homme",
                                                    "Femme"
                                                  ] }
                                value         = {sex}
                                onChange      = {handleSexChange}	/>
					  )
					  :
					  (
					    <>{sex}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_4)}>

                   <WidgetFrame name               = "Date de naissance"
                                error              = {messageBirthday}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <DateSelector value    = {birthday}
						              onChange = {handleBirthdayChange} />
					  )
					  :
					  (
					    <>{birthday}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_5)}>

                   <WidgetFrame name               = "Adresse"
                                error              = {messageAddress}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {address}
                                onChange      = {handleAddressChange} />
					  )
					  :
					  (
					    <>{address}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_6)}>

                   <WidgetFrame name               = "Complément d'adresse"
                                error              = {messageAddress2}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {address2}
                                onChange      = {handleAddress2Change} />
					  )
					  :
					  (
					    <>{address2}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_7)}>

                   <WidgetFrame name               = "Code postal"
                                error              = {messageZipCode}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {zipCode}
                                onChange      = {handleZipCodeChange} />
					  )
					  :
					  (
					    <>{zipCode}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_8)}>

                   <WidgetFrame name               = "Ville"
                                error              = {messageCity}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {city}
                                onChange      = {handleCityChange} />
					  )
					  :
					  (
					    <>{city}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_9)}>

			       <WidgetFrame name               = "Pays"
                                error              = {messageCountry}
   							    labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Select choices       = { [
                                                    "France",
                                                    "Belgique",
                                                    "Suisse"
                                                  ] }
                                value         = {country}
                                onChange      = {handleCountryChange}	/>
					  )
					  :
					  (
					    <>{country}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_10)}>

                   <WidgetFrame name               = "Numéro de téléphone"
                                error              = {messagePhoneNumber}
	    						labelTextPosition  = "left"
							    labelBlockPosition = "top" >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {phoneNumber}
                                onChange      = {handlePhoneNumberChange} />
					  )
					  :
					  (
					    <>{phoneNumber}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_2_line_11)}>

                  {
                    (
					  editMode
					)
					?
					(
					  <>
					
                       <div className={classNames(styles.buttons_block)}>
                        <br/>
					    <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleEditButtonClick}>
                           Annuler
                        </button>

                        <br/>
                        <br/>

                        <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleValidateButtonClick}>
                           Enregistrer
                        </button>
					   </div>
                       <br/>
								  
					  </>
					)
					:
					(
					  <>
                       <br/>
                       <div className={classNames(styles.buttons_block)}>
                        <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleEditButtonClick}>
                           Modifier
                        </button>
					   </div>
                       <br/>
								  
					  </>
					)
				  }

                 </div>

                            
               </div>

	
	        </>
  }


  const renderBigSizeComponent = (): JSX.Element => {

    return  <>
	
	           <p className={classNames(styles.title)}>
                 INFORMATIONS PERSONNELLES
               </p>
              
			   <br/>
			  
               <div className={classNames(styles.form)}>

                 <div className={classNames(styles.layout_1_line_1_left)}>

			       <WidgetFrame name          = "Prénom"
                                error         = {messageFirstname}
   							    classNameGrid = {classNames(styles.grid_info)} >

                    {
                      (
					    editMode
				  	  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
								value         = {firstname}
                                onChange      = {handleFirstnameChange} />
					  )
					  :
					  (
					    <>{firstname}</>
					  )
                    }
				  
				  </WidgetFrame>
				  
                 </div>

                 <div className={classNames(styles.layout_1_line_1_right)}>

			       <WidgetFrame name          = "Nom"
                                error         = {messageLastname}
   							    classNameGrid = {classNames(styles.grid_info)} >

                    {
                      (
					    editMode
				  	  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
								value         = {lastname}
                                onChange      = {handleLastnameChange} />
					  )
					  :
					  (
					    <>{lastname}</>
					  )
                    }

				  </WidgetFrame>

                 </div>


                 <div className={classNames(styles.layout_1_line_2_left)}>

			       <WidgetFrame name          = "Sexe"
                                error         = {messageSex}
   							    classNameGrid = {classNames(styles.grid_info)} >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Select choices       = { [
                                                    "Aucune réponse",
                                                    "Homme",
                                                    "Femme"
                                                  ] }
                                value         = {sex}
                                onChange      = {handleSexChange}	/>
					  )
					  :
					  (
					    <>{sex}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_2_right)}>

                   <WidgetFrame name          = "Date de naissance"
                                error         = {messageBirthday}
	    						classNameGrid = {classNames(styles.grid_info)} >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <DateSelector value    = {birthday}
						              onChange = {handleBirthdayChange} />
					  )
					  :
					  (
					    <>{birthday}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_3)}>

                   <WidgetFrame name          = "Adresse"
                                error         = {messageAddress}
	    						classNameGrid = {classNames(styles.grid_info)} >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {address}
                                onChange      = {handleAddressChange} />
					  )
					  :
					  (
					    <>{address}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_4)}>

                   <WidgetFrame name          = "Complément d'adresse"
                                error         = {messageAddress2}
	    						classNameGrid = {classNames(styles.grid_info)} >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {address2}
                                onChange      = {handleAddress2Change} />
					  )
					  :
					  (
					    <>{address2}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_5_left)}>

                   <WidgetFrame name          = "Code postal"
                                error         = {messageZipCode}
	    						classNameGrid = {classNames(styles.grid_info)} >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {zipCode}
                                onChange      = {handleZipCodeChange} />
					  )
					  :
					  (
					    <>{zipCode}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_5_right)}>

                   <WidgetFrame name          = "Ville"
                                error         = {messageCity}
	    						classNameGrid = {classNames(styles.grid_info)} >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {city}
                                onChange      = {handleCityChange} />
					  )
					  :
					  (
					    <>{city}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_6_left)}>

			       <WidgetFrame name          = "Pays"
                                error         = {messageCountry}
   							    classNameGrid = {classNames(styles.grid_info)} >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Select choices       = { [
                                                    "France",
                                                    "Belgique",
                                                    "Suisse"
                                                  ] }
                                value         = {country}
                                onChange      = {handleCountryChange}	/>
					  )
					  :
					  (
					    <>{country}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_6_right)}>

                   <WidgetFrame name          = "Numéro de téléphone"
                                error         = {messagePhoneNumber}
	    						classNameGrid = {classNames(styles.grid_info)} >
								
                    {
                      (
					    editMode
					  )
					  ?
					  (
                        <Field  type          = "text"
                                placeholder   = ""
                                value         = {phoneNumber}
                                onChange      = {handlePhoneNumberChange} />
					  )
					  :
					  (
					    <>{phoneNumber}</>
					  )
                    }

				  </WidgetFrame>

                 </div>

                 <div className={classNames(styles.layout_1_line_7)}>

                  <div className={classNames(styles.buttons)}>
                  {
                    (
					  editMode
					)
					?
					(
					  <>
					
					    <button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleEditButtonClick}>
                           Annuler
                        </button>

                        &nbsp;&nbsp;&nbsp;

                        <button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleValidateButtonClick}>
                           Enregistrer
                        </button>
								  
					  </>
					)
					:
					(
                        <button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                onClick   = {handleEditButtonClick}>
                           Modifier
                        </button>
					)
				  }

                  </div>

                 </div>

                            
               </div>

	
	        </>
  }

  return (
            
           <div className={classNames(styles.root)}>

               {
			    showWindow  &&
                (
				  < Window message              = {windowMessage}
				           onClickOnCloseButton = {handleCloseButtonClick} />
				)				
			   }

			 { 
			  ( isSmallScreen ? renderSmallSizeComponent() : renderBigSizeComponent () ) 
			 }


           </div>
           
         );
};


export default PersonalInformations;
