import   React                       ,
       { useEffect                   }  from 'react';
		
	
import { useNavigate                 }  from 'react-router-dom';
		 
import   classNames                     from 'classnames';


import { Dispatch                    }  from 'redux';
import { useDispatch                 }  from 'react-redux';

import   styles                         from './success.module.scss';

import { TypewriterEffectSmooth      }  from "../../ui/typewriter-effect";

import { GlobalMenu                  }  from '../../menu/global/global';
import { CookiesManager              }  from '../../cookie/manager';
import { Trigger                     }  from '../../widget/trigger/trigger';
import { Footer                      }  from '../../menu/footer/footer';

import { WWW_URLS                    ,
         INTERNAL_LINKS              }  from '../../../network/urls';

import { useTypedSelector            }  from '../../../datas/useTypeSelector';

import { initAuthentication          }  from '../../../datas/authenticationManagment/actionCreators';
		 
import { initUser                    ,
         setUser                     }  from '../../../datas/userManagment/actionCreators';

import { updateStatus                ,
         updateStatusDeadline        ,
         updateNextStatus            ,
         updateNextStatusRequestDate ,
         initPaymentsSet             ,
         addPayment                  }  from '../../../datas/userManagment/actionCreators';

import { getUserInfos                }  from '../../../network/api_user';

import { getPaymentInfos             }  from '../../../network/api_payment';

import { postEvent                   }  from '../../../network/api_events_stats';

export const PaymentSuccess = () => {

  const   dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { user           } = useTypedSelector ((state) => state.userManagment );

  useEffect(() => {

    console.log (authentication)
    console.log (user)

	postEvent ( "Ouverture de /confirmation-paiement" , authentication.login );

  }, []); 

  const handleClick = () => {
  
    updateInformations ();
  }

  const updateInformations = async () => {
 
    const userInfos = await getUserInfos ( authentication.login ,
                                           authentication.token )
                   
	// On ne teste pas l'âge à cet endroit là car :

    //   - soit c'est pendant le processus d'inscription (login automatique)
    //   - soit depuis le compte du user (l'âge a été testé au moment du choix de l'abonnement	
				   
    if ( userInfos.code === 1 )
          {
           dispatch ( updateStatus                ( userInfos.infos.status               ) )
           dispatch ( updateStatusDeadline        ( userInfos.infos.statusDeadline       ) )
           dispatch ( updateNextStatus            ( userInfos.infos.nextStatus            ) )
           dispatch ( updateNextStatusRequestDate ( userInfos.infos.nextStatusRequestDate ) )

           dispatch ( initPaymentsSet () )

           const paymentInfos = await getPaymentInfos ( authentication.login ,
                                                        authentication.token ) 
                                                            
           for ( let p = 0 ; p < paymentInfos.payments.length ; p++ )
            {
             const payment = paymentInfos.payments[p];
                 
             dispatch ( addPayment ( payment ) ) 
            }
                     
           window.location.href  = `${WWW_URLS.AccountManagment}`
					 
           // navigate ("/compte");
          }
     else {
           dispatch ( initAuthentication(true) );
          }
   }

  const logout = () => {

    dispatch ( initUser   () );
	dispatch ( initAuthentication (true) );
    navigate ( `${INTERNAL_LINKS.Login}` );
  }

  const words = [
    {
      text: "Votre",
      className: "text-green-500 dark:text-green-500",
    },
    {
      text: "paiement",
      className: "text-green-500 dark:text-green-500",
    },
    {
      text: "a",
      className: "text-green-500 dark:text-green-500",
    },
    {
      text: "bien",
      className: "text-green-500 dark:text-green-500",
    },
    {
      text: "été",
      className: "text-green-500 dark:text-green-500",
    },
    {
      text: "accepté.",
      className: "text-green-500 dark:text-green-500",
    },
  ];
  
  
  return (
 
    <>

      <GlobalMenu />
      <CookiesManager />

  	  <div className={classNames(styles.root)}>

    	<div className={classNames(styles.contain)}>

     	 <div className={classNames(styles.message)}>
          <TypewriterEffectSmooth words={words} />
         </div>

     	 <div className={classNames(styles.message2)}>
		   <br/>
		    L'enregistrement de votre paiement et la mise à jour de votre compte, dans votre espace abonné, 
		    peut prendre quelques minutes.<br/>
		   {/*
           <br/>
            Nous sommes heureux de vous compter parmi nos nouveaux abonnés.<br/>
	       <br/>
            Votre abonnement est bien actif.
			*/}
		   <br/>
		   <br/>
		   
		   { 
		     (
			   authentication.login === "" || authentication.token === "" || user.state === "New" || user.state === "new"
			 )
			 ?
			 (
               <Trigger onClick         = {logout}
		                name            = "Page de connexion"
                        classNameNormal = {classNames(styles.button)} />
			 )
			 :
			 (
               <Trigger onClick         = {handleClick}
		                name            = "Retour au compte utilisateur"
                        classNameNormal = {classNames(styles.button)} />
			 )
		   }
		   
  	     </div>
		 
        </div>

	  </div>

    <Footer />
 
    </>
	
  );
};

export default PaymentSuccess;

