import   React                            , 
       { useState                         ,
	     useEffect                        }  from 'react';
import   classNames                          from 'classnames';
import { useLocation                      ,
         useNavigate                      }  from 'react-router-dom';

import   styles                              from './account_managment.module.scss';

import { GlobalMenu                       }  from '../../menu/global/global';
import { CookiesManager                   }  from '../../cookie/manager';
import { TopShifter                       }  from '../../menu/top_shifter/top_shifter';
import { Footer                           }  from '../../menu/footer/footer';

import { PersonalInformations             }  from './blocs/personal_informations/personal_informations';
import { ConnectionInformations           }  from './blocs/connection_informations/connection_informations';
import { SubscriptionsAndServices         }  from './blocs/subscriptions_and_services/subscriptions_and_services';
import { PaymentInformations              }  from './blocs/payment_informations/payment_informations';
import { AccountClosure                   }  from './blocs/account_closure/account_closure';
import { SubscriptionTermination          }  from './blocs/subscription_termination/subscription_termination';

import { LeafletCookiesInformation        }  from '../mandatory_informations/blocks/leaflet_cookies_information/leaflet_cookies_information';
import { LeafletPersonnalDatasInformation }  from '../mandatory_informations/blocks/leaflet_personnal_datas_information/leaflet_personnal_datas_information';
import { LeafletTermsOfService            }  from '../mandatory_informations/blocks/leaflet_terms_of_service/leaflet_terms_of_service';
import { LegalNoticePanel                 }  from '../mandatory_informations/blocks/legal_notice_panel/legal_notice_panel';

import { Tab                              }  from '../../widget/tab/tab';
import { Panel                            }  from '../../widget/tab/panel';
 
import { useTypedSelector                 }  from '../../../datas/useTypeSelector';
 
// import { checkScreenSize                  }  from '../../../utils/screen_size_tester';
 
import { postEvent                   }  from '../../../network/api_events_stats';
 
export const AccountManagment = () => {

  const location = useLocation();
  const navigate = useNavigate();
  
  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { user           } = useTypedSelector ((state) => state.userManagment );

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  const switchWidthLimit = 870;

  const handleResize = () => {
    setIsSmallScreen ( window.innerWidth <= switchWidthLimit );
  };

  useEffect(() => {

  }, [isSmallScreen]); 


  useEffect(() => {

	postEvent ( "Ouverture de /compte" , authentication.login );

    if ( authentication.login === "" || 
         authentication.token === "" )
          {
           navigate ("/connexion");
          }
	 else {
           window.addEventListener('resize', handleResize);

           handleResize();

           return () => {
             window.removeEventListener('resize', handleResize);
           };
          }
	
  }, []); 

/*  
  const handlePopState = (event: PopStateEvent) => {
	
      if ( authentication.login === "" || 
           authentication.token === "" )
       {
        navigate ("/connexion");
       }
    };
*/

  const renderSmallSizeComponent = (): JSX.Element => {
 
    console.log ("Render small")
 
    const identifiant = location.hash.substring(1);
 
         if ( identifiant === "mon-compte" )
		  {
	       return <div className={classNames(styles.frame)}>
		  			<ConnectionInformations/>
					<br/>
					<hr/>
					<br/>
				    <PersonalInformations/>
					<br/>
					<hr/>
					<br/>
					<SubscriptionTermination/>
					<br/>
					<hr/>
					<br/>
					<AccountClosure/>
	              </div>
		  
		  }
		  
    else if ( identifiant === "abonnements-et-services" )
	      {
	       return <div className={classNames(styles.frame)}>
		   		   	<SubscriptionsAndServices/>
	              </div>
		  }
		  
    else if ( identifiant === "informations-de-paiement" )
	      {
	       return <div className={classNames(styles.frame)}>
				   <PaymentInformations/>
	              </div>
		  
		  }
		  
    else  {
	       return <div className={classNames(styles.frame)}>
		  			<ConnectionInformations/>
					<br/>
					<hr/>
					<br/>
				    <PersonalInformations/>
	              </div> 
	      }
	
 

  }
  
  const renderBigSizeComponent = (): JSX.Element => {
  
    return  <>
             
			   <br/>

			   <Tab classNameNormalButton = { classNames(styles.trigger_normal) }
			        classNamePushedButton = { classNames(styles.trigger_pushed) } >
					
				  <Panel name="Mon compte">
				   <>
					<ConnectionInformations/>
					<br/>
					<hr/>
					<br/>
				    <PersonalInformations/>
					<br/>
					<hr/>
					<br/>

					{
					 ( user.status === "Standard" || user.status === "Premium" )
					 &&
					 ( user.nextStatus !== "Standard" )
					 &&
					 (
					  <>
					   <SubscriptionTermination/>
					   <br/>
					   <hr/>
					   <br/>
					  </>
					 )
					}

					<AccountClosure/>
				   </>
				  </Panel>
				  
				  <Panel name="Abonnements et services">
				   <SubscriptionsAndServices/>
				  </Panel>
				  
				  <Panel name="Informations de paiement">
				   <PaymentInformations/>
				  </Panel>

				  <Panel name="Mentions légales">
				   <LegalNoticePanel/>
				  </Panel>

				  <Panel name="CGU">
				   <LeafletTermsOfService/>
				  </Panel>

				  <Panel name="Confidentialité">
				   <LeafletPersonnalDatasInformation/>
				  </Panel>
				  
				  <Panel name="Cookies">
				   <LeafletCookiesInformation/>
				  </Panel>

			   </Tab>
			
            </>			

  }


  return (

        <>
		  
          <CookiesManager />
          <GlobalMenu />
          <TopShifter />

          <div className={classNames(styles.root)}>

            <div className={classNames(styles.contain)}>

            { 
			  ( isSmallScreen ? renderSmallSizeComponent() : renderBigSizeComponent () ) 
			}

            </div>
            
          </div>

          <Footer />
        
        </>

           
         );


};

export default AccountManagment;
