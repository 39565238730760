import   React          from 'react';

import { Video       }  from '../../../../../datas/videoManagment/actionTypes';

import   classNames     from 'classnames';

import { useTypedSelector }  from '../../../../../datas/useTypeSelector';

import   styles         from './window_vote.module.scss';

import { postEvent   }  from '../../../../../network/api_events_stats';


interface WindowVoteProps {
  
  video:      Video  ,
  top:        number ,
  left:       number , 
  hideWindow: ()=>void
}



export const WindowVote = ( { video      ,
                              top        ,
                              left       ,
							  hideWindow }: WindowVoteProps ) => {
			
  const { authentication }        = useTypedSelector((state) => state.authenticationManagment );
			
			
  const handleMinusButtonClick = () => {
  
    const eventMessage: string = `Vote,-1,${video.id}`
  
   		   postEvent ( eventMessage , authentication.login );

  
   hideWindow ();
  }

  const handlePlusButtonClick = () => {

    const eventMessage: string = `Vote,+1,${video.id}`
  
   		   postEvent ( eventMessage , authentication.login );

   hideWindow ();
  }
			
  const handlePlusPlusButtonClick = () => {

    const eventMessage: string = `Vote,+2,${video.id}`
  
   		   postEvent ( eventMessage , authentication.login );

   hideWindow ();
  }
			
  return (
    
	<div style        = { { top:             `${top}px`    ,
                            left:            `${left}px`   ,
                            width:           'fit-content' ,
                            height:          'fit-content' ,
                            backgroundColor: '#00000000'   ,
                            zIndex:          60            ,
		                    position:        'absolute'    } }  >
							
	  <div className  = "px-2 py-1 rounded-full bg-[#FFFFFF] border border-black">
	    
		{/*
		<div className = { classNames(styles.buttons)} >
		  <button className = "px-2 py-1 rounded-full bg-[#FFFFFF] font-bold text-xl text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                  onClick   = {handleMinusButtonClick}>
            &#x1F61E;
          </button>
		  &nbsp;&nbsp;
		  <button className = "px-2 py-1 rounded-full bg-[#FFFFFF] font-bold text-xl text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                  onClick   = {handlePlusButtonClick}>
            &#x1F600;
          </button>
		  &nbsp;&nbsp;
		  <button className = "px-2 py-1 rounded-full bg-[#FFFFFF] font-bold text-xl text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                  onClick   = {handlePlusPlusButtonClick}>
            &#x1F601;
          </button>
		</div>
		*/}

		<div className = { classNames(styles.buttons)} >
		  <button className = "px-2 py-1 rounded-full bg-[#FFFFFF] font-bold text-xl text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                  onClick   = {handleMinusButtonClick}>
            &#128078;
          </button>
		  &nbsp;&nbsp;
		  <button className = "px-2 py-1 rounded-full bg-[#FFFFFF] font-bold text-xl text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                  onClick   = {handlePlusButtonClick}>
            &#128077;
          </button>
		  &nbsp;&nbsp;
		  <button className = "px-2 py-1 rounded-full bg-[#FFFFFF] tracking-tighter font-bold text-xl text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                  onClick   = {handlePlusPlusButtonClick}>
            &#128077;&#128077;
          </button>
		</div>
		
	  </div>
	</div>
	
   )
};

export default WindowVote;