import { Action     , 
         ActionType , 
		 Catalog    ,
         Category   ,
         Video      ,
		 CurrentlyPlayingVideo ,
         BroadcastingChannelPackage ,
         CurrentlyPlayingBroadcastingChannel } from './actionTypes';


interface State 
 {
  catalog: Catalog;
  currentlyPlayingVideo: CurrentlyPlayingVideo;
  broadcastingChannelPackage: BroadcastingChannelPackage;
  currentlyPlayingBroadcastingChannel: CurrentlyPlayingBroadcastingChannel;
 }

const initialState = 
 {
  currentlyPlayingVideo: 
   {
    video: null
   },
  catalog: 
   {
	categories: []
   },
  currentlyPlayingBroadcastingChannel:
   {
    broadcastingChannel: null
   },
  broadcastingChannelPackage:
   {
	channels: []
   }
 }

/*

const VideoManagmentReducer = (state: State = initialState, action: Action):State => 
 {
  switch(action.type) 
   {
    case ActionType.INIT_CATALOG:             state.catalog.categories = []
										      
                                              return state;

    case ActionType.SET_CATALOG:              state.catalog = action.newCatalog
										      
                                              return state;

    case ActionType.ADD_CATEGORY:             state.catalog.categories = 
	                                                [ ...state.catalog.categories , 
													  action.newCategory          ]
										      
                                              return state;

    case ActionType.ADD_VIDEOSET:             state.catalog.categories[action.categoryNumber].videoSets = 
	                                                [ ...state.catalog.categories[action.categoryNumber].videoSets , 
													  action.newVideoSet                                           ]
										      
                                              return state;

    case ActionType.ADD_PRODUCT:              state.catalog.categories[action.categoryNumber].videoSets[action.videoSetNumber].products = 
	                                               [ ...state.catalog.categories[action.categoryNumber].videoSets[action.videoSetNumber].products , 
												     action.newProduct                                                                            ]

                                              return state;

    case ActionType.ADD_VIDEO:                state.catalog.categories[action.categoryNumber].videoSets[action.videoSetNumber].videos = 
	                                               [ ...state.catalog.categories[action.categoryNumber].videoSets[action.videoSetNumber].videos , 
												     action.newVideo                                                                            ]

                                              return state;

    case ActionType.SET_LOCKED:               state.catalog.categories[action.categoryNumber].videoSets[action.videoSetNumber].videos[action.videoNumber].locked = action.newLocked;

                                              return state;

    case ActionType.SET_LOCKED:               state.catalog.categories[action.categoryNumber].videoSets[action.videoSetNumber].videos[action.videoNumber].locked = action.newLocked;

                                              return state;

    case ActionType.SET_CURRENTLY_PLAYING_VIDEO:  
	                                          console.log ("reducer " , action.newVideo );
	                                          console.log ("reducer " , state );
	
	                                          state.currentlyPlayingVideo.video = action.newVideo;

                                              return state;

    default:                                  return state;
   }
 }
*/

const VideoManagmentReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.INIT_CATALOG:
      return {
        ...state,
        catalog: { ...state.catalog, categories: [] }
      };

    case ActionType.SET_CATALOG:
      return {
        ...state,
        catalog: action.newCatalog
      };

    case ActionType.ADD_CATEGORY:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          categories: [...state.catalog.categories, action.newCategory]
        }
      };

    case ActionType.ADD_VIDEOSET:
      const updatedCategoriesWithVideoSet = state.catalog.categories.map((category, index) => {
        if (index === action.categoryNumber) {
          return {
            ...category,
            videoSets: [...category.videoSets, action.newVideoSet]
          };
        }
        return category;
      });

      return {
        ...state,
        catalog: {
          ...state.catalog,
          categories: updatedCategoriesWithVideoSet
        }
      };

    case ActionType.ADD_PRODUCT:
      const updatedCategoriesWithProduct = state.catalog.categories.map((category, index) => {
        if (index === action.categoryNumber) {
          return {
            ...category,
            videoSets: category.videoSets.map((videoSet, vsIndex) => {
              if (vsIndex === action.videoSetNumber) {
                return {
                  ...videoSet,
                  products: [...videoSet.products, action.newProduct]
                };
              }
              return videoSet;
            })
          };
        }
        return category;
      });

      return {
        ...state,
        catalog: {
          ...state.catalog,
          categories: updatedCategoriesWithProduct
        }
      };

    case ActionType.ADD_VIDEO:
      const updatedCategoriesWithVideo = state.catalog.categories.map((category, index) => {
        if (index === action.categoryNumber) {
          return {
            ...category,
            videoSets: category.videoSets.map((videoSet, vsIndex) => {
              if (vsIndex === action.videoSetNumber) {
                return {
                  ...videoSet,
                  videos: [...videoSet.videos, action.newVideo]
                };
              }
              return videoSet;
            })
          };
        }
        return category;
      });

      return {
        ...state,
        catalog: {
          ...state.catalog,
          categories: updatedCategoriesWithVideo
        }
      };

    case ActionType.SET_LOCKED:
      const updatedCategoriesWithLocked = state.catalog.categories.map((category, index) => {
        if (index === action.categoryNumber) {
          return {
            ...category,
            videoSets: category.videoSets.map((videoSet, vsIndex) => {
              if (vsIndex === action.videoSetNumber) {
                return {
                  ...videoSet,
                  videos: videoSet.videos.map((video, vIndex) => {
                    if (vIndex === action.videoNumber) {
                      return {
                        ...video,
                        locked: action.newLocked
                      };
                    }
                    return video;
                  })
                };
              }
              return videoSet;
            })
          };
        }
        return category;
      });

      return {
        ...state,
        catalog: {
          ...state.catalog,
          categories: updatedCategoriesWithLocked
        }
      };

    case ActionType.SET_CURRENTLY_PLAYING_VIDEO:
      return {
        ...state,
        currentlyPlayingVideo: { ...state.currentlyPlayingVideo, video: action.newVideo }
      };

    case ActionType.SET_BROADCASTING_CHANNEL_PACKAGE:
      return {
        ...state,
        broadcastingChannelPackage: action.newBroadcastingChannelPackage
      };

    case ActionType.SET_CURRENTLY_PLAYING_BROADCASTING_CHANNEL:
      return {
        ...state,
        currentlyPlayingBroadcastingChannel: { ...state.currentlyPlayingBroadcastingChannel, broadcastingChannel: action.newBroadcastingChannel }
      };



    default:
      return state;
  }
};


export default VideoManagmentReducer;
