import   React                  ,  
       { useEffect              }  from 'react';

import { Dispatch                                     }  from 'redux';
import { useDispatch                                  }  from 'react-redux';

import { useNavigate                 }  from 'react-router-dom';

import   classNames                from 'classnames';

import { TypewriterEffectSmooth }  from "../../ui/typewriter-effect";

import   styles                    from './fail.module.scss';

import { GlobalMenu             }  from '../../menu/global/global';
import { CookiesManager         }  from '../../cookie/manager';
import { Trigger                }  from '../../widget/trigger/trigger';
import { Footer                 }  from '../../menu/footer/footer';

import { INTERNAL_LINKS         }  from '../../../network/urls';

import { initAuthentication     }  from '../../../datas/authenticationManagment/actionCreators';
import { initUser               ,
         setUser                }  from '../../../datas/userManagment/actionCreators';

import { useTypedSelector       }  from '../../../datas/useTypeSelector';

import { postEvent              }  from '../../../network/api_events_stats';
 
export const PaymentFail = () => {

  const   dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { user           } = useTypedSelector ((state) => state.userManagment );

  const words = [
    {
      text: "Votre",
      className: "text-red-500 dark:text-red-500",
    },
    {
      text: "paiement",
      className: "text-red-500 dark:text-red-500",
    },
    {
      text: "a",
      className: "text-red-500 dark:text-red-500",
    },
    {
      text: "échoué.",
      className: "text-red-500 dark:text-red-500",
    },
  ];
  
  useEffect(() => {

	postEvent ( "Ouverture de /echec-paiement" , authentication.login );

  }, []); 
  
  const logout = () => {

    dispatch ( initUser   () );
	dispatch ( initAuthentication (true) );
    navigate ( `${INTERNAL_LINKS.Login}` );
  }
  
  return (
 
    <>

      <GlobalMenu />
      <CookiesManager />

  	  <div className={classNames(styles.root)}>

    	<div className={classNames(styles.contain)}>

     	  <div className={classNames(styles.message)}>
           <TypewriterEffectSmooth words={words} />
          </div>

          
      	  <div className={classNames(styles.message2)}>
		  {/*
		    <br/>
             Vous pouvez cliquer sur le bouton ci-dessous pour vous rendre sur la section abonnements et services de votre compte et faire une nouvelle demande d'abonnement.
		  */}
            <br/>
		    <br/>
			
		   {
		     (
			   authentication.login === "" || authentication.token === "" || user.state === "New" || user.state === "new"
			 )
			 ?
			 (
               <Trigger onClick         = {logout}
		                name            = "Page de connexion"
                        classNameNormal = {classNames(styles.button)} />
			 )
			 :
			 (
               <Trigger link            = {INTERNAL_LINKS.AccountManagment}
		                name            = "Retour au compte utilisateur"
                        classNameNormal = {classNames(styles.button)} />
			 )
		   }

 	      </div>
		  
		  
        </div>

	  </div>

    <Footer />
 
    </>
	
  );
};

export default PaymentFail;

