import   React                               , 
       { useEffect                           , 
	     useState                            , 
		 useRef                              }  from 'react';

import { format                              ,
         parse                               ,
         parseISO                            ,
         startOfWeek                         ,
		 addDays                             }  from 'date-fns';

import { fr                                  }  from 'date-fns/locale';

import   classNames                             from 'classnames';

import   styles                                 from './playlist_viewer.module.scss';

import { WWW_URLS                            }  from '../../../network/urls';

import { Video ,
         BroadcastedVideo,
		 BroadcastingChannel,
		 BroadcastingChannelPackage,
		 CurrentlyPlayingBroadcastingChannel }  from '../../../datas/videoManagment/actionTypes';

import { useTypedSelector                    }  from '../../../datas/useTypeSelector';

import { postEvent                           }  from '../../../network/api_events_stats';


export interface PlaylistViewerProps {

  channel:     BroadcastingChannel;
  closeButton: () => void ;      
}

export const PlaylistViewer = ({ channel     ,
                                 closeButton }: PlaylistViewerProps) => {

  useEffect(() => {

  }, [] );


  const handleCloseButtonClick = () => {
    closeButton ();
  }

  const formatDuration = (durationInSeconds: number): string => {
  
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.round((durationInSeconds % 3600) / 60);

    if (hours > 0) 
	      {
           return `${hours}h${minutes.toString().padStart(2, '0')}`;
          } 
	 else {
           return `${minutes} min`;
          }
  };

  const extractDateInfo = (date: Date): string => {
    
	const dayName = format(date, 'EEEE', { locale: fr }); // Nom complet du jour en français
    const day = format(date, 'd', { locale: fr }); // Jour
    const month = format(date, 'MMMM', { locale: fr }); // Nom complet du mois en français
    const year = format(date, 'yyyy', { locale: fr }); // Année

    return `${dayName} ${day} ${month} ${year}`;
  };

  const renderBroadcastedVideoLine = (broadcastedVideo: BroadcastedVideo): JSX.Element => {

    const urlImage =  `${WWW_URLS.Thumbnail}${broadcastedVideo.video.imageFile}.webp`;

    let durationStr: string = formatDuration ( broadcastedVideo.video.computedDuration )

    return (
      <div className={classNames(styles.video_row)}>
        <div className={classNames(styles.video_date)}>{  format ( broadcastedVideo.broadcastDate , 'HH:mm' )  }</div>
        <div className={classNames(styles.video_title)}>{broadcastedVideo.video.title} ({durationStr})</div>
        <div className={classNames(styles.video_image)}><img className={classNames(styles.thumbnail)} src={urlImage} alt={broadcastedVideo.video.title} /></div>
      </div>
    );
  }

  const renderBroadcastedVideoList = (): JSX.Element => {

    let currentDay: string = "";

    if  ( channel.broadcastedVideos.length > 0 )
          {
	       const tab: JSX.Element[] = [];

		   for ( let v=0; v<channel.broadcastedVideos.length; v++ )
		    {
			 if ( channel.broadcastedVideos[v].video.title != "Jingle 2024")
			  {
			   const dayStr: string = extractDateInfo ( channel.broadcastedVideos[v].broadcastDate )
			   
			   if ( currentDay !== dayStr )
			    {
				 currentDay = dayStr
				 
				 tab.push (
				            <div className={classNames(styles.day)}>
							 {currentDay}
							</div>
				          )
				}
			  
			  
			   tab.push (	renderBroadcastedVideoLine ( channel.broadcastedVideos[v] ) )
			  }
			}

		   return (
					<>
					  <div className={classNames(styles.playlist_block)} >
					  {tab}
					  </div>
                    </>
	              )
	      }
     else {
           return (
		            <>
					  <div className={classNames(styles.infos)} >Aucune émission à diffuser</div>
		            </>
	              )
	      }

  }

    return (
    
		<div className={classNames(styles.root)}>
		
		  <div className={classNames(styles.close_button)} >
			      
		    <img src       = '/images/icons/close.png' 
			     alt       = 'fermer programme' 
			     onClick   = {handleCloseButtonClick}
				 className = {classNames(styles.icon)}/>
						  
		  </div> 

  		  <div className={classNames(styles.title)}>
		    LE PROGRAMME
		  </div>
          <br/>
		  {renderBroadcastedVideoList ()}

		</div>
  
	);
};

export default PlaylistViewer;

