import   React       ,  
       { useState    ,
	     useEffect   }  from 'react';

import { useNavigate }  from 'react-router-dom';

import { Dispatch    }  from 'redux';
import { useDispatch }  from 'react-redux';

import { Rating      }  from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'


import { LongText    }  from '../../../../widget/long_text/long_text';

import   classNames     from 'classnames';

import   styles         from './video_sheet.module.scss';

import { Video       }  from '../../../../../datas/videoManagment/actionTypes';

import { setCurrentlyPlayingVideo }  from '../../../../../datas/videoManagment/actionCreators';

import { useTypedSelector }  from '../../../../../datas/useTypeSelector';

import { Padlock     }  from './padlock';
 
import { WWW_URLS    }  from '../../../../../network/urls';
 
 import { postEvent   }  from '../../../../../network/api_events_stats';

export interface VideoSheetProps {
  video: Video;
  hideSheet: () => void;
  showAlertWindow: () => void
  alertWindowVisible: boolean
}

export const VideoSheet = ({ video           ,
                             hideSheet       ,
							 showAlertWindow ,
							 alertWindowVisible }: VideoSheetProps) => {

  const navigate  = useNavigate();
   const dispatch: Dispatch<any> = useDispatch();

  const { authentication }        = useTypedSelector((state) => state.authenticationManagment );

  let timeoutId: NodeJS.Timeout;

  const urlImage = `${WWW_URLS.Thumbnail}${video.imageFile}_BIG.webp`;

  const handleMouseLeave = () => {

    if ( !alertWindowVisible )
	 {
      hideSheet ();
	 }
  };

  const handleClosebutton = () => {
  
    if ( !alertWindowVisible )
	 {
      hideSheet ();
	 }
  }
 
  const handleReadButtonClick = () => {
  
    if ( !alertWindowVisible )
	 {
  	  if (video.locked === false)
	        {
	         dispatch ( setCurrentlyPlayingVideo ( video ) );
	   
             navigate ("/video")
  	        }
	   else {
	         showAlertWindow ()
	        }
     }			
  }

  const handleMinusButtonClick = () => {
  
    if ( !alertWindowVisible )
	 {
      const eventMessage: string = `Vote,-1,${video.id}`
  
   	  postEvent ( eventMessage , authentication.login );  
	 }
  }

  const handlePlusButtonClick = () => {
  
    if ( !alertWindowVisible )
	 {
      const eventMessage: string = `Vote,+1,${video.id}`
  
      postEvent ( eventMessage , authentication.login );  }
     }
	 
  const handlePlusPlusButtonClick = () => {
  
    if ( !alertWindowVisible )
	 {
      const eventMessage: string = `Vote,+2,${video.id}`
  
      postEvent ( eventMessage , authentication.login );  }
     }

  return (
            
    <div className    = { classNames(styles.root)}
	     onMouseLeave = { handleMouseLeave } >

      <button
        style={{
          position:   'absolute',
          top:        '10px',
          right:      '10px',
          zIndex:     50, // Une valeur supérieure à celle de l'image
          background: 'transparent',
          border:     'none',
          cursor:     'pointer',
		  color:      'white'
        }}
        onClick={handleClosebutton}
      >
        <img src="https://lovelightfactory.fr/images/icons/close.png"
		     alt="croix" />
      </button>


				
	   <div className = { classNames(styles.grid_1)} >
			
	      <div className = { classNames(styles.grid_1_line_1)} >
		  
		    <div className = { classNames(styles.block_image)} >
		  
		     <img src       = { urlImage }  
			      className = { classNames(styles.image)} />
				  
             {
	             video.locked &&
		         (
                  <img style = {{ position: 'absolute' ,
				                  bottom:   '0px'    ,
                                  left:     '0px'      ,
								  zIndex:  52}}
			  	       src   = "https://lovelightfactory.fr/images/icons/locked.svg"
 		               alt   = "locked" />
                 )
	         }

            </div>
				  
          </div>
			 
		  <div className = { classNames(styles.grid_1_line_2,styles.bg_gradient)} >
			 
		     <div className = { classNames(styles.grid_2)} >
			 
			    <div className = { classNames(styles.grid_2_line_1)} >

			       <div className = { classNames(styles.grid_3)} >
				   
			          <div className = { classNames(styles.grid_3_col_1)} >
				  
				         <div className = { classNames(styles.button_read)} >
						 
						 {
	               video.locked 
				   ?
		           (
				           <button className = "px-6 py-2 rounded-full bg-[#777777] font-bold text-sm text-black tracking-widest uppercase transform border border-black"
						           onClick   = {handleReadButtonClick}>
                               ► Lecture
                           </button>
						 )
                         :
                         (
				           <button className = "px-6 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                   onClick   = {handleReadButtonClick}>
                               ► Lecture
                           </button>
						 )
                         }						 
				         </div>
						 
			          </div>
					  
			          <div className = { classNames(styles.grid_3_col_2)} >
				  
				         <div className = { classNames(styles.button_vote)} >
				           <button className = "px-2 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                   onClick   = {handleMinusButtonClick}>
                               &#128078;
                           </button>
						   &nbsp;
				           <button className = "px-2 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                   onClick   = {handlePlusButtonClick}>
                               &#128077;
                           </button>
						   &nbsp;
				           <button className = "px-2 py-2 rounded-full bg-[#FFFFFF] font-bold text-sm text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                   onClick   = {handlePlusPlusButtonClick}>
                               &#128077;&#128077;
                           </button>
						 </div>
						 
			          </div>
					  
			          <div className = { classNames(styles.grid_3_col_3)} >
					     <p className = { classNames(styles.numberOfViews)} >
				            {video.numberOfViews}
							<br/>
							vues
						 </p>
			          </div>
					  
			          <div className = { classNames(styles.grid_3_col_4)} >
					     <div className = { classNames(styles.ranking)} >
						    {/*
				            <Rating allowFraction
							        size         = {10}
									initialValue = {video.ranking}
                                    readonly />
							 */}
							 
							
							 <Rating
      style={{ maxWidth: 160 }}
      value={video.ranking / 20.0 * 5}
      readOnly
    />
	
	{/*
	
	
	<Ratings
        rating={video.ranking}
        starDimension="40px"
        starSpacing="15px"
      />
	
	*/}
						 </div>
			          </div>
					  
			          <div className = { classNames(styles.grid_3_col_5)} >
					     <p className = { classNames(styles.duration)} >
  				            {video.duration}
						 </p>
			          </div>
					  
			       </div>
			  
			    </div>
			   
			    <div className = { classNames(styles.grid_2_line_2)} >

  			       <div className = { classNames(styles.grid_4)} >
			     
				      <div className = { classNames(styles.grid_4_col_1)} >

  			             <div className = { classNames(styles.grid_5)} >
			        
					        <div className = { classNames(styles.grid_5_line_1)} >
					           <p className = { classNames(styles.title)} >
							      {video.title}
						       </p>
 
                               {
							     video.complementToTheTitle !== "undefined" &&
								 (
					               <p className = { classNames(styles.complementToTheTitle)} >
							         {video.complementToTheTitle} 
						           </p>
								 )
                               }
							   
                               <br/>

					           <p className = { classNames(styles.description)} >
							     
								 <LongText text  = {video.description} 
								           limit = {450} />
								 
						       </p>
			                </div>

				         </div>
				 
			          </div>
				  
			          <div className = { classNames(styles.grid_4_col_2)} >
					  
  			             <div className = { classNames(styles.grid_6)} >
			        
					        <div className = { classNames(styles.grid_6_line_1)} >
							   <div className = { classNames(styles.distribution)} >
					              <span className = { classNames(styles.label_distribution)} >
							         Distribution :
						          </span>
							      <br/>
					              <span className = { classNames(styles.value_distribution)} >
							         {video.distribution}
						          </span>
						       </div>
 
							   <div className = { classNames(styles.production)} >
					              <span className = { classNames(styles.label_production)} >
							         Production :
						          </span>
							      <br/>
					              <span className = { classNames(styles.value_production)} >
							         {video.production}
						          </span>
			                   </div>
							   
							   <div className = { classNames(styles.copyright)} >
					              <span className = { classNames(styles.label_copyright)} >
							         Copyright :
						          </span>
							      <br/>
					              <span className = { classNames(styles.value_copyright)} >
							         {video.copyright}
						          </span>
			                   </div>
			                </div>

				         </div>

					  </div>
					  
			       </div>
				   
                </div>

			 </div>
			 
		  </div>
			  
       </div>
			 
    </div>
           
   );
};


export default VideoSheet;
