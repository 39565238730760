import   React      ,  
       { useState   ,
	     useEffect  }  from "react";
		 
import   classNames    from 'classnames';

import   styles        from './date_selector.module.scss';

import { Select     }  from '../select/select';

export interface DateSelectorProps {
  value?:         string;                      // La valeur de l'input
  disabled?:      boolean | undefined;         // Modifiable ou non
  onChange?:      ( value: string) => void;    // Ajout d'une prop pour gérer le changement de l'input
}

export const DateSelector = ({ value         , 
                               disabled      ,
                               onChange      }: DateSelectorProps) => {

  const days:   string[] = [];
  const months: string[] = [];
  const years:  string[] = [];

  const numberToString = ( n:number ) : string => {
  
     if ( 0 <= n && n <= 9 )
           {
		    return "0" + n.toString ()
		   } 
  	  else {
	        return n.toString()
	       }
  }

  days.push   ( "" );
  months.push ( "" );
  years.push  ( "" );

  for (let i =    1; i <=   31; i++) {   days.push( numberToString (i) ); }
  for (let i =    1; i <=   12; i++) { months.push( numberToString (i) ); }
  for (let i = 1950; i <= 2020; i++) {  years.push( numberToString (i) ); }

  
  const handleDayChange = ( e: React.ChangeEvent<HTMLSelectElement> ) => {
  
    setDay ( e.target.value );
	updateDate ( e.target.value + "/" + month + "/" + year );
  }

  const handleMonthChange = ( e: React.ChangeEvent<HTMLSelectElement> ) => {
  
    setMonth ( e.target.value );
	updateDate ( day + "/" + e.target.value + "/" + year );
  }

  const handleYearChange = ( e: React.ChangeEvent<HTMLSelectElement> ) => {
  
    setYear ( e.target.value );
	updateDate ( day + "/" + month + "/" + e.target.value );
  }

  const updateDate = ( newDate: string ) => {
  
    console.log ("updateDate ", newDate)
  
	if ( typeof ( onChange ) !== "undefined" )
	 {
	  onChange ( newDate );
	 }
  }


    const [   dayFromValue , 
            monthFromValue , 
		     yearFromValue ] = ( typeof (value) !== "undefined" ? value.split ('/') : ["01","01","1950"] );

  const [ day         , setDay         ] = useState ( dayFromValue   );
  const [ month       , setMonth       ] = useState ( monthFromValue   );
  const [ year        , setYear        ] = useState ( yearFromValue );
  const [ initialized , setInitialized ] = useState ( false  );

  return (
  
           <div className={classNames(styles.date_selector)}>
       
	        <div className={classNames(styles.day_block)}>
			
              <Select choices  = { days            }
                      value    = { day             } 
					  onChange = { handleDayChange } />	  

            </div>
			
	        <div className={classNames(styles.month_block)}>

              <Select choices  = { months            }
                      value    = { month             } 
					  onChange = { handleMonthChange } />	  

            </div>

	        <div className={classNames(styles.year_block)}>

              <Select choices  = { years            }
                      value    = { year             } 
					  onChange = { handleYearChange } />	  

            </div>

           </div>
           
         );
};

export default DateSelector;
