import { Action       , 
         ActionType   , 
		 User         }  from './actionTypes';


interface State 
 {
  user: User;
 }

const maxDate = new Date();
maxDate.setTime(Number.MAX_VALUE);

export const initialUser: User =    {
    id:             0                ,
    email:          ""               ,
    newEmail:       ""               ,
    password:       ""               ,
    password2:      ""               ,
    firstname:      ""               ,
    lastname:       ""               ,
    sex:            "Aucune réponse" ,
    birthday:       "01/01/1950"     ,
    address:        ""               ,
    address2:       ""               ,
    zipCode:        ""               ,
    city:           ""               ,
    country:        "France"         ,
    phoneNumber:    ""               ,
    status:         ""               ,
    statusDeadline: maxDate          ,
    nextStatus:            ""  ,
    nextStatusRequestDate: null ,
	rgpdConsent:    false            ,
	verify:         false            ,
    state:          "new" ,
	payments:       []
}

const initialState: State =
 {
  user: initialUser
 }

const userManagmentReducer = (state: State = initialState, action: Action):State =>
 {
  switch(action.type) 
   {
    case ActionType.INIT_USER:                state.user = initialUser
										
                                              return state;

    case ActionType.SET_USER:                 state.user.id             = action.newId
                                              state.user.email          = action.newEmail
                                              state.user.newEmail       = action.newNewEmail
                                              state.user.password       = ""
                                              state.user.password2      = ""
                                              state.user.firstname      = action.newFirstname
                                              state.user.lastname       = action.newLastname
                                              state.user.sex            = action.newSex
                                              state.user.birthday       = action.newBirthday
                                              state.user.address        = action.newAddress
                                              state.user.address2       = action.newAddress2
                                              state.user.zipCode        = action.newZipCode
                                              state.user.city           = action.newCity
                                              state.user.country        = action.newCountry
                                              state.user.phoneNumber    = action.newPhoneNumber
                                              state.user.status         = action.newStatus
                                              state.user.statusDeadline = action.newStatusDeadline
                                              state.user.nextStatus         = action.newNextStatus
                                              state.user.nextStatusRequestDate = action.newNextStatusRequestDate
                                              state.user.rgpdConsent    = false
								     		  state.user.verify         = false
								     		  state.user.state          = action.newState
										
                                              return state;

    case ActionType.UPDATE_ID:                state.user.id             = action.newId

                                              return state;
										
    case ActionType.UPDATE_EMAIL:             state.user.email          = action.newEmail
 
                                              return state;
									 
    case ActionType.UPDATE_NEWEMAIL:          state.user.newEmail       = action.newNewEmail

                                              return state;

    case ActionType.UPDATE_PASSWORD:          state.user.password       = action.newPassword

                                              return state;
									 
    case ActionType.UPDATE_PASSWORD2:         state.user.password2      = action.newPassword2

                                              return state;
									 
    case ActionType.UPDATE_FIRSTNAME:         state.user.firstname      = action.newFirstname
	
	                                          return state;
									 
    case ActionType.UPDATE_LASTNAME:          state.user.lastname       = action.newLastname
									 
									          return state;
									 
    case ActionType.UPDATE_SEX:               state.user.sex            = action.newSex
									 
									          return state;
									 
    case ActionType.UPDATE_BIRTHDAY:          state.user.birthday       = action.newBirthday
									 
									          return state;
									 
    case ActionType.UPDATE_ADDRESS:           state.user.address        = action.newAddress
									 
									          return state;
									 
    case ActionType.UPDATE_ADDRESS2:          state.user.address2       = action.newAddress2
									 
									          return state;
									 
    case ActionType.UPDATE_ZIPCODE:           state.user.zipCode        = action.newZipCode
									 
									          return state;
									 
    case ActionType.UPDATE_CITY:              state.user.city           = action.newCity
									 
									          return state;
									 
    case ActionType.UPDATE_COUNTRY:           state.user.country        = action.newCountry

									          return state;

    case ActionType.UPDATE_PHONENUMBER:       state.user.phoneNumber    = action.newPhoneNumber
									 
									          return state;
									 
    case ActionType.UPDATE_STATUS:            state.user.status         = action.newStatus
									 
									          return state;
									 
    case ActionType.UPDATE_STATUS_DEADLINE:   state.user.statusDeadline = action.newStatusDeadline
									 
									          return state;
									 
    case ActionType.UPDATE_NEXT_STATUS:                state.user.nextStatus         = action.newNextStatus

									                  return state;

    case ActionType.UPDATE_NEXT_STATUS_REQUEST_DATE:   if ( typeof (action.newNextStatusRequestDate) !== "undefined" )
                                                        {
                                                           state.user.nextStatusRequestDate = action.newNextStatusRequestDate
                                                        }

									                  return state;

    case ActionType.UPDATE_RGPDCONSENT:       state.user.rgpdConsent    = action.newRgpdConsent;
									 
									          return state;
									 
    case ActionType.VERIFY_EMAIL:             state.user.verify         = action.newVerify;
									 
									          return state;
											  
    case ActionType.UPDATE_STATE:             state.user.state         = action.newState

									          return state;

	case ActionType.ADD_PAYMENT:              state.user.payments       = [...state.user.payments, action.newPayment];

									          return state;

	  
	case ActionType.INIT_PAYMENTS_SET:        state.user.payments       = [];

									          return state;
																				 
    default:                                  return state;
   }
 }


export default userManagmentReducer;
