import   React                ,
       { useEffect            ,
	     useState             }  from 'react';
		 
import   classNames              from 'classnames';

import { useLocation          }  from 'react-router-dom';

import { useDispatch          }  from 'react-redux';

import { Dispatch             }  from 'redux';

import   styles                  from './password_reset.module.scss';

import { Field                }  from '../../widget/field/field';
import { Trigger              }  from '../../widget/trigger/trigger';
import { WidgetFrame          }  from '../../widget/widget_frame/widget_frame';

import { updatePassword       ,
         updatePassword2      }  from '../../../datas/userManagment/actionCreators';

import { Validation           ,
         checkPassword        ,
         checkPassword2       }  from '../../../datas/userManagment/validators';	
		 
import { getUserPasswordReset }  from '../../../network/api_user';
		 
export const PasswordReset = () => {
  
  const location                = useLocation();
  const dispatch: Dispatch<any> = useDispatch()

  const [ token                 , setToken                ] = useState('');
  const [ password              , setPassword             ] = useState('');
  const [ password2             , setPassword2            ] = useState('');

  const [ messagePassword       , setMessagePassword      ] = useState('');
  const [ messagePassword2      , setMessagePassword2     ] = useState('');

  const [ showMessageToken      , setShowMessageToken     ] = useState(false);
  const [ showMessageError      , setShowMessageError     ] = useState(false);
  const [ showMessageAck        , setShowMessageAck       ] = useState(false);

  useEffect(() => {

    const temp = new URLSearchParams(location.search).get("token");

    if ( temp )
          {
           setToken ( temp );
          }
     else {
           setShowMessageToken (true);
          }

  },[location.search, token, showMessageToken]);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword (e.target.value)
    dispatch ( updatePassword (e.target.value) );
	
    const retour1: Validation = checkPassword ( e.target.value ) 
	setMessagePassword ( retour1.comment );
  };

  const handlePassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword2 (e.target.value)
    dispatch ( updatePassword2 (e.target.value) );
    
    const retour: Validation = checkPassword2 ( e.target.value , password )
	setMessagePassword2 ( retour.comment );
  };


  const handlePasswordUpdate = () => {
  
  	  console.log ( "handlePasswordUpdate" )

    makeReinitialization ()
  }

  const checkAll = (): boolean => {
  
    const response01 = checkPassword  ( password  ) 
    const response02 = checkPassword2 ( password2 , 
	                                  password  ) 

	setMessagePassword  ( response01.comment );
	setMessagePassword2 ( response02.comment );
	
	const globalResponse:boolean = ( response01.code === 1 ) && 
	                               ( response02.code === 1 );

    return globalResponse;												
  }

  const makeReinitialization = async () => {
 
   	  console.log ( "makeReinitialization" )

    const check = await checkAll ()
 
    if ( check )
	 {
	  const response = await getUserPasswordReset ( token    ,
	                                                password )
											  
	  console.log ( response )
											  
	  if ( response.code === 1 )
            {
             setShowMessageError ( false );
             setShowMessageAck   ( true  );
	        }
       else {
             setShowMessageError ( true  );
             setShowMessageAck   ( false );
	        }	   
     }
  }

  return (

        <>

          <div className={classNames(styles.root)}>

            <div className={classNames(styles.contain)}>

              <h1 className={classNames(styles.title)}>Réinitialisation du mot de passe</h1>

              <br/>

                  {
                    ( showMessageToken )
					&&
                    (
                      <>
                        <div>
				          <p className   = {classNames(styles.message_error)}>
					        Le token est absent. Vous pouvez cliquer sur le bouton ci-dessous pour vous rendre sur la page de connexion et faire une nouvelle demande de réinitialisation.
					      </p>
                        </div>

                        <br/>

                        <Trigger name            = "Page de connexion"
                                 link            = "/connexion"
                                 classNameNormal = {classNames(styles.button)} 
                        />

                      </>
                    )
				  }
				  
				  {
				  	( showMessageAck ) 
                    &&
                    (
                      <>
                        <div>
	  			          <p className   = {classNames(styles.message_ack)}>
					         Votre mot de passe a été modifié. Vous pouvez cliquer sur le bouton ci-dessous pour vous rendre sur la page de connexion.
					      </p>
                        </div>

                        <br/>

                        <Trigger name            = "Page de connexion"
                                 link            = "/connexion"
                                 classNameNormal = {classNames(styles.button)}  />
                      </>
                    )
				  }
				  
				  {
			        ( showMessageError ) 
				    &&
                    ( 
				      <>
                        <div>
				         <p className = {classNames(styles.message_error)}>
					       Le serveur a rencontré une erreur interne lors de la modification de votre mot de passe
					     </p>
                        </div>

                        <br/>

                        <Trigger name            = "Page de connexion"
                                 link            = "/connexion"
                                 classNameNormal = {classNames(styles.button)}  />
                      </>
					)
			      }

                  {
				    ( !showMessageToken && !showMessageAck && !showMessageError ) 
                    &&
                    (
                      <div>

                        <WidgetFrame name          = "Nouveau mot de passe"
                                     error         = {messagePassword}
   							         labelTextPosition  = "left"
						             labelBlockPosition = "top" 
									 classNameName      = {classNames(styles.text)} >

                          <Field     type          = "password"
                                     placeholder   = ""
								     value         = {password}
                                     onChange      = {handlePasswordChange} />

   				        </WidgetFrame>

                        <br/>

                        <WidgetFrame name          = "Confirmation du nouveau mot de passe"
                                     error         = {messagePassword2}
   							         labelTextPosition  = "left"
						             labelBlockPosition = "top" 
									 classNameName      = {classNames(styles.text)} >

                          <Field     type          = "password"
                                     placeholder   = ""
								     value         = {password2}
                                     onChange      = {handlePassword2Change} />

   				        </WidgetFrame>

                        <br/>

                        <Trigger name            = "Mettre à jour"
                                 onClick         = {handlePasswordUpdate}
                                 classNameNormal = {classNames(styles.button)} />

                      </div>
                    )
			      }


		    </div>

  	      </div>

        </>

         );

};

export default PasswordReset;
