import { Payment                          }  from '../paymentManagment/actionTypes';

import { ActionType                        ,
         actionInitUser                    ,
         actionSetUser                     ,
		 actionUpdateId                    ,
         actionUpdateEmail                 ,
         actionUpdateNewEmail              ,
         actionUpdatePassword              ,
         actionUpdatePassword2             ,
         actionUpdateFirstname             ,
         actionUpdateLastname              ,
         actionUpdateSex                   ,
         actionUpdateBirthday              ,
         actionUpdateAddress               ,
         actionUpdateAddress2              ,
         actionUpdateZipCode               ,
         actionUpdateCity                  ,
         actionUpdateCountry               ,
         actionUpdatePhoneNumber           ,
         actionUpdateStatus                ,
         actionUpdateStatusDeadline        ,
         actionUpdateNextStatus            ,
         actionUpdateNextStatusRequestDate ,
         actionUpdateRgpdConsent           ,
         actionVerifyEmail                 ,
		 actionUpdateState                 ,
         actionAddPayment                  ,
		 actionInitPaymentsSet             ,
		 DispatchTypeInitUser              ,
		 DispatchTypeSetUser               ,
         DispatchTypeUpdateId              ,
         DispatchTypeUpdateEmail           ,
         DispatchTypeUpdateNewEmail        ,
         DispatchTypeUpdatePassword        ,
         DispatchTypeUpdatePassword2       ,
         DispatchTypeUpdateFirstname       ,
         DispatchTypeUpdateLastname        ,
         DispatchTypeUpdateSex             ,
         DispatchTypeUpdateBirthday        ,
         DispatchTypeUpdateAddress         ,
         DispatchTypeUpdateAddress2        ,
         DispatchTypeUpdateZipCode         ,
         DispatchTypeUpdateCity            ,
         DispatchTypeUpdateCountry         ,
         DispatchTypeUpdateStatus          ,
         DispatchTypeUpdateStatusDeadline  ,
         DispatchTypeUpdateNextStatus         ,
         DispatchTypeUpdateNextStatusRequestDate ,
         DispatchTypeUpdatePhoneNumber     ,
		 DispatchTypeUpdateRgpdConsent     ,
		 DispatchTypeVerifyEmail           ,
         DispatchTypeUpdateState           ,
		 DispatchTypeAddPayment            ,
		 DispatchTypeInitPaymentsSet       }  from './actionTypes';

					 


export const initUser = () =>
 {
  const action: actionInitUser =
   {
    type:          ActionType.INIT_USER
   }

  return ( dispatch: DispatchTypeInitUser ) =>
   {
    dispatch (action);
   }
 } 

export const setUser         = ( newId:             number ,
                                 newEmail:          string ,
                                 newNewEmail:       string ,
								 newFirstname:      string ,
								 newLastname:       string ,
								 newSex:            string ,
								 newBirthday:       string ,
								 newAddress:        string ,
								 newAddress2:       string ,
								 newZipCode:        string ,
								 newCity:           string ,
								 newCountry:        string ,
								 newPhoneNumber:    string ,
								 newStatus:         string ,
								 newStatusDeadline: Date   ,
								 newNextStatus:            string ,
								 newNextStatusRequestDate: Date | null  ,
                                 newState:          string ,) =>
 {
  const action: actionSetUser =
   {
    type:              ActionType.SET_USER,
	newId:             newId,
	newEmail:          newEmail,
	newNewEmail:       newNewEmail,
	newFirstname:      newFirstname,
	newLastname:       newLastname,
	newSex:            newSex,
	newBirthday:       newBirthday,
	newAddress:        newAddress,
	newAddress2:       newAddress2,
	newZipCode:        newZipCode,
	newCity:           newCity,
	newCountry:        newCountry,
	newPhoneNumber:    newPhoneNumber,
    newStatus:         newStatus  ,
    newStatusDeadline: newStatusDeadline    ,
    newNextStatus:            newNextStatus,
	newNextStatusRequestDate: newNextStatusRequestDate ,
    newState:         newState  ,
   }

  return ( dispatch: DispatchTypeSetUser) =>
   {
    dispatch (action);
   }
 } 

export const updateId = ( newId: number ) => 
 {
  const action: actionUpdateId = 
   {
    type:          ActionType.UPDATE_ID,
	newId:         newId
   }

  return ( dispatch: DispatchTypeUpdateId ) => 
   {
    dispatch (action);
   }
 } 

export const updateEmail = ( newEmail: string ) => 
 {
  const action: actionUpdateEmail = 
   {
    type:          ActionType.UPDATE_EMAIL,
	newEmail:      newEmail
   }

  return ( dispatch: DispatchTypeUpdateEmail ) => 
   {
    dispatch (action);
   }
 } 

export const updateNewEmail = ( newNewEmail: string ) =>
 {
  const action: actionUpdateNewEmail =
   {
    type:          ActionType.UPDATE_NEWEMAIL,
	newNewEmail:   newNewEmail
   }

  return ( dispatch: DispatchTypeUpdateNewEmail ) =>
   {
    dispatch (action);
   }
 }

export const updatePassword = ( newPassword: string ) =>
 {
  const action: actionUpdatePassword =
   {
    type:          ActionType.UPDATE_PASSWORD,
	newPassword:   newPassword
   }
 
  return ( dispatch: DispatchTypeUpdatePassword ) => 
   {
    dispatch (action);
   }
 } 

export const updatePassword2 = ( newPassword2: string ) => 
 {
  const action: actionUpdatePassword2 =
   {
    type:          ActionType.UPDATE_PASSWORD2,
	newPassword2:  newPassword2
   }
 
  return ( dispatch: DispatchTypeUpdatePassword2 ) => 
   {
    dispatch (action);
   }
 } 

export const updateFirstname = ( newFirstname: string ) => 
 {
  const action: actionUpdateFirstname =
   {
    type:          ActionType.UPDATE_FIRSTNAME,
	newFirstname:  newFirstname
   }
   
  return ( dispatch: DispatchTypeUpdateFirstname ) => 
   {
    dispatch (action);
   }
 } 

export const updateLastname = ( newLastname: string ) => 
 {
  const action: actionUpdateLastname =
   {
    type:          ActionType.UPDATE_LASTNAME,
	newLastname:   newLastname
   }
   
  return ( dispatch: DispatchTypeUpdateLastname ) => 
   {
    dispatch (action);
   }
 } 

export const updateSex = ( newSex: string ) => 
 {
  const action: actionUpdateSex =
   {
    type:          ActionType.UPDATE_SEX,
	newSex:        newSex
   }
   
  return ( dispatch: DispatchTypeUpdateSex ) => 
   {
    dispatch (action);
   }
 } 

export const updateBirthday = ( newBirthday: string ) => 
 {
  const action: actionUpdateBirthday =
   {
    type:          ActionType.UPDATE_BIRTHDAY,
	newBirthday:   newBirthday
   }
   
  return ( dispatch: DispatchTypeUpdateBirthday ) => 
   {
    dispatch (action);
   }
 } 

export const updateAddress = ( newAddress: string ) => 
 {
  const action: actionUpdateAddress =
   {
    type:          ActionType.UPDATE_ADDRESS,
	newAddress:    newAddress
   }
  
  return ( dispatch: DispatchTypeUpdateAddress ) => 
   {
    dispatch (action);
   }
 } 

export const updateAddress2 = ( newAddress2: string ) => 
 {
  const action: actionUpdateAddress2 =
   {
    type:           ActionType.UPDATE_ADDRESS2,
	newAddress2:    newAddress2
   }
  
  return ( dispatch: DispatchTypeUpdateAddress2 ) => 
   {
    dispatch (action);
   }
 } 

export const updateZipCode = ( newZipCode: string ) => 
 {
  const action: actionUpdateZipCode =
   {
    type:           ActionType.UPDATE_ZIPCODE,
	newZipCode:     newZipCode
   }
  
  return ( dispatch: DispatchTypeUpdateZipCode ) => 
   {
    dispatch (action);
   }
 } 

export const updateCity = ( newCity: string ) => 
 {
  const action: actionUpdateCity =
   {
    type:           ActionType.UPDATE_CITY,
	newCity:        newCity
   }
  
  return ( dispatch: DispatchTypeUpdateCity ) => 
   {
    dispatch (action);
   }
 } 

export const updateCountry = ( newCountry: string ) =>
 {
  const action: actionUpdateCountry =
   {
    type:           ActionType.UPDATE_COUNTRY,
	newCountry:     newCountry
   }

  return ( dispatch: DispatchTypeUpdateCountry ) =>
   {
    dispatch (action);
   }
 }

export const updatePhoneNumber = ( newPhoneNumber: string ) =>
 {
  const action: actionUpdatePhoneNumber =
   {
    type:           ActionType.UPDATE_PHONENUMBER,
	newPhoneNumber: newPhoneNumber
   }
   
  return ( dispatch: DispatchTypeUpdatePhoneNumber ) => 
   {
    dispatch (action);
   }
 } 

export const updateStatus = ( newStatus: string ) =>
 {
  const action: actionUpdateStatus =
   {
    type:      ActionType.UPDATE_STATUS,
	newStatus: newStatus
   }
   
  return ( dispatch: DispatchTypeUpdateStatus ) => 
   {
    dispatch (action);
   }
 } 

export const updateStatusDeadline = ( newStatusDeadline: Date ) =>
 {
  const action: actionUpdateStatusDeadline =
   {
    type:              ActionType.UPDATE_STATUS_DEADLINE,
	newStatusDeadline: newStatusDeadline
   }
   
  return ( dispatch: DispatchTypeUpdateStatusDeadline ) => 
   {
    dispatch (action);
   }
 } 

export const updateNextStatus = ( newNextStatus: string ) =>
 {
  const action: actionUpdateNextStatus =
   {
    type:          ActionType.UPDATE_NEXT_STATUS,
	newNextStatus: newNextStatus
   }

  return ( dispatch: DispatchTypeUpdateNextStatus ) =>
   {
    dispatch (action);
   }
 }

export const updateNextStatusRequestDate = ( newNextStatusRequestDate?: Date | null ) =>
 {
  const action: actionUpdateNextStatusRequestDate =
   {
    type:                     ActionType.UPDATE_NEXT_STATUS_REQUEST_DATE,
	newNextStatusRequestDate: newNextStatusRequestDate
   }

  return ( dispatch: DispatchTypeUpdateNextStatusRequestDate ) =>
   {
    dispatch (action);
   }
 }

export const updateRgpdConsent = ( newRgpdConsent: boolean ) =>
 {
  const action: actionUpdateRgpdConsent =
   {
    type:           ActionType.UPDATE_RGPDCONSENT,
	newRgpdConsent: newRgpdConsent
   }
   
  return ( dispatch: DispatchTypeUpdateRgpdConsent ) => 
   {
    dispatch (action);
   }
 } 

export const verifyEmail = ( newVerify: boolean ) =>
 {
  const action: actionVerifyEmail =
   {
    type:       ActionType.VERIFY_EMAIL,
	newVerify:  newVerify
   }
   
  return ( dispatch: DispatchTypeVerifyEmail ) =>
   {
    dispatch (action);
   }
 }

export const updateState = ( newState: string ) =>
 {
  const action: actionUpdateState =
   {
    type:      ActionType.UPDATE_STATE,
	newState: newState
   }

  return ( dispatch: DispatchTypeUpdateState ) =>
   {
    dispatch (action);
   }
 }

export const addPayment = ( newPayment: Payment ) =>
 {
  const action: actionAddPayment =
   {
    type:       ActionType.ADD_PAYMENT,
	newPayment: newPayment
   }
   
  return ( dispatch: DispatchTypeAddPayment ) =>
   {
    dispatch (action);
   }
 }
 
export const initPaymentsSet = () => 
 {
  const action: actionInitPaymentsSet = 
   {
    type:          ActionType.INIT_PAYMENTS_SET
   }

  return ( dispatch: DispatchTypeInitPaymentsSet ) => 
   {
    dispatch (action);
   }
 } 
