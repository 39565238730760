import { ActionType                     ,
         Product                        ,
         ProductVariant                 ,
         ProductCategory                ,
         ProductCatalog                 ,
		 actionInitProductCatalog       ,
         actionSetProductCatalog        ,
         DispatchTypeInitProductCatalog ,
         DispatchTypeSetProductCatalog  }  from './actionTypes';

export const initProductCatalog = () => 
 {
  const action: actionInitProductCatalog = 
   {
    type:     ActionType.INIT_PRODUCT_CATALOG
   }

  return ( dispatch: DispatchTypeInitProductCatalog ) => 
   {
    dispatch (action);
   }
 } 

export const setProductCatalog = ( newCatalog: ProductCatalog ) => 
 {
  const action: actionSetProductCatalog = 
   {
    type:       ActionType.SET_PRODUCT_CATALOG,
	newCatalog: newCatalog
   }

  return ( dispatch: DispatchTypeSetProductCatalog ) => 
   {
    dispatch (action);
   }
 } 
