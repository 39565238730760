import   React                          ,  
       { useState                       ,
	     useEffect                      }  from 'react';

import { useNavigate                    }  from 'react-router-dom';

import { Dispatch                       }  from 'redux';
import { useDispatch                    }  from 'react-redux';

import { Rating                         }  from '@smastrom/react-rating'

import '@smastrom/react-rating/style.css'


import { LongText                       }  from '../../../../widget/long_text/long_text';

import   classNames                        from 'classnames';

import   styles                            from './product_variant_choice.module.scss';

import { Product                        }  from '../../../../../datas/productManagment/actionTypes';

import { initCart                       , 
         addItem                        , 
         removeItem                     , 
         changeQuantity                 }  from '../../../../../datas/cartManagment/actionCreators';

import { addItemToUserCart              ,
         removeItemFromUserCart         ,
         changeQuantityOfItemInUserCart }  from '../../../../../network/api_cart';
		 
import { useTypedSelector               }  from '../../../../../datas/useTypeSelector';

import { WWW_URLS                       , 
         INTERNAL_LINKS                 }  from '../../../../../network/urls';
 
import { postEvent                      }  from '../../../../../network/api_events_stats';

import { Select                         }  from '../../../../widget/select/select';
import { WidgetFrame                    }  from '../../../../widget/widget_frame/widget_frame';
import { Window                         }  from '../../../../widget/window/window';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export interface ProductVariantChoiceProps {
  product:    Product;
  hideChoice: () => void;
}

export const ProductVariantChoice = ({ product    ,
                                       hideChoice }: ProductVariantChoiceProps) => {

  const navigate  = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();

  const { authentication } = useTypedSelector((state) => state.authenticationManagment );
  const { cart           } = useTypedSelector((state) => state.cartManagment );

  const [ alertWindowVisible , setAlertWindowVisible ] = useState ( false );
  const [ currentVariant     , setCurrentVariant     ] = useState ( 0 );

  const searchImage = (): string => {
  
    let imgFile = "";
	
    for ( let c = 0; c < product.variants[currentVariant].characteristics.length; c++)
	 {
	  if ( product.variants[currentVariant].characteristics[c].kind === "Photo" )
	   {
	    imgFile = product.variants[currentVariant].characteristics[c].value;
		break;
	   }
	 }

    if ( imgFile === "" )
     {
	  for ( let c = 0; c < product.characteristics.length; c++)
	   {
	    if ( product.characteristics[c].kind === "Photo" )
	     {
	      imgFile = product.characteristics[c].value;
		  break;
	     }
	   }
     }
	 
    if ( imgFile === "" )
     {
  	  for ( let v = 0; v < product.variants.length; v++)
	   {
  	    for ( let c = 0; c < product.variants[v].characteristics.length; c++)
	     {
	      if ( product.variants[v].characteristics[c].kind === "Photo" )
	       {
	        imgFile = product.variants[v].characteristics[c].value;
		    break;
	       }
	     }
	  
        if ( imgFile !== "" )
         {
	      break;
	     }	  
	   }
	 }
	 
    return imgFile
  }
  
  const searchImages = (): string[] => {
  
    let imgFiles: string [] = [];
	
    for ( let c = 0; c < product.variants[currentVariant].characteristics.length; c++)
	 {
	  if ( product.variants[currentVariant].characteristics[c].kind === "Photo" )
	   {
	    imgFiles.push ( `${WWW_URLS.ProductThumbnail}${product.variants[currentVariant].characteristics[c].value}` );
	   }
	 }

    if ( imgFiles.length === 0 )
     {
	  for ( let c = 0; c < product.characteristics.length; c++)
	   {
	    if ( product.characteristics[c].kind === "Photo" )
	     {
	      imgFiles.push ( `${WWW_URLS.ProductThumbnail}${product.characteristics[c].value}` );
		  break;
	     }
	   }
     }
	 
    if ( imgFiles.length === 0 )
     {
  	  for ( let v = 0; v < product.variants.length; v++)
	   {
  	    for ( let c = 0; c < product.variants[v].characteristics.length; c++)
	     {
	      if ( product.variants[v].characteristics[c].kind === "Photo" )
	       {
	        imgFiles.push ( `${WWW_URLS.ProductThumbnail}${product.variants[v].characteristics[c].value}` );
		    break;
	       }
	     }
	  
        if ( imgFiles.length > 0 )
         {
	      break;
	     }	  
	   }
	 }
	 
    return imgFiles
  }

  const generateVariantNames = (): string[] => {
  
    let variantNames: string [] = [];
  
	for ( let v = 0; v < product.variants.length; v++)
	 {
	  variantNames.push ( product.variants[v].name );
	 }
	 
    return variantNames
  }


  let urlImage = `${WWW_URLS.ProductThumbnail}${searchImage()}`;
  let productVariantNames = generateVariantNames () 
  let urlsImage = searchImages();

  useEffect(() => {
	
  let urlImage = `${WWW_URLS.ProductThumbnail}${searchImage()}`;
  let productVariantNames = generateVariantNames () 
  let urlsImage = searchImages();

  console.log (urlsImage)

  }, [] );

  useEffect(() => {
	
  let urlImage = `${WWW_URLS.ProductThumbnail}${searchImage()}`;
  let productVariantNames = generateVariantNames () 
  let urlsImage = searchImages();

  console.log (urlsImage)

  }, [currentVariant] );

  const renderImages = (): JSX.Element => {
  
    switch ( urlsImage.length )
	 {
	  case 0 : return ( <></> )
	  
	  case 1 : return (
	  
	     <>
		   <div className = { classNames(styles.block_image)} >

		     <div className = { classNames(styles.block_image)} >
		       <img src       = { urlsImage[0] }  
			        className = { classNames(styles.image)} />
             </div>
			 
           </div>
	     </>

		)
		
	  default :  return (
	  
	     <>
		   <div className = { classNames(styles.block_image)} >

		     <Carousel showThumbs = {false} 
			            autoPlay  = {false} >
                {
				  urlsImage.map ( (url, index) => (
        
		            <div key={index}>
                       <img src={url} alt={`Photo ${index + 1}`} />
                       <p className="legend">Photo {index + 1}</p>
                    </div>
                  ))
				}
				
            </Carousel>
			
          </div>
	     </>

		)
	 }
  
  }



  const handleClosebutton = () => {
      
      hideChoice ();

  }

  const handleAddToCard = () => {
      
     addOneProductToCurrentCart ();

  }
 
 
  const addOneProductToCurrentCart = async () => {
  
	 // Si la personne est connecté, on regarde s'il y a un prix associé à son statut
	 
	 const productCode: string        = product.code;
	 const productVariantCode: string = product.variants[currentVariant].productVariantCode;
	 const priceCode: string          = product.variants[currentVariant].prices[0].code;
	 const quantity: number           = getQuantity ( productCode , productVariantCode ) + 1;
	 
	 console.log ( productCode, productVariantCode, priceCode,  quantity)
	 
	 if ( quantity === 1 )
	       {
	        dispatch ( addItem ( productCode        ,
                                 productVariantCode ,
					             priceCode          ,
                                 quantity           ) )
								 
			if ( authentication.login !== "" )
			 {
			  await addItemToUserCart ( authentication.login ,
			                            authentication.token ,
										cart.purchaseId      ,
			                            productCode          ,
                                        productVariantCode   ,
					                    priceCode            ,
                                        quantity             )
			 }
		   }
	  else {
	        dispatch ( changeQuantity ( productCode        ,
                                        productVariantCode ,
					                    quantity           ) )
										
			if ( authentication.login !== "" )
			 {
			  await changeQuantityOfItemInUserCart ( authentication.login ,
			                                         authentication.token ,
								    		         cart.purchaseId      ,
			                                         productCode          ,
                                                     productVariantCode   ,
					                                 quantity             )
			 }
										
		
		   }
	 
	 	 setAlertWindowVisible (true)

  }

  const getQuantity = ( productCode:        string ,
	                    productVariantCode: string ): number => {
	
	let quantity: number = 0;
	
    for ( let i=0 ; i<cart.items.length; i++)
	 {
	  if ( cart.items[i].productCode === productCode && cart.items[i].productVariantCode === productVariantCode )
       {
	    quantity = cart.items[i].quantity
	   }	  
 	 }
	
	return quantity
  }




  const closeAlertWindowWithCancelButton = () => {
  
    setAlertWindowVisible ( false );
  }

  const closeAlertWindowWithValidButton = () => {
  
  
    setAlertWindowVisible ( false );
	
    navigate ( `${INTERNAL_LINKS.Cart}`)
  }

  const handleVariantChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    console.log ( e.target.value )
	setCurrentVariant ( e.target.selectedIndex )
  };

  return (
            
    <div className    = { classNames(styles.root)} >

              {
	           alertWindowVisible &&
	           ( < Window message        = { <><span className = {classNames(styles.bold_text)}>Votre produit a été ajouté à votre panier.</span><br/><br/>Voir le panier ?<br/></> }
                   messageType           = "normal"
                   onClickOnCancelButton = {closeAlertWindowWithCancelButton}
                   onClickOnValidButton  = {closeAlertWindowWithValidButton}
                   cancelButtonName      = "NON"
                   validButtonName       = "OUI"   /> )
	          }


      <button
        style={{
          position:   'absolute',
          top:        '10px',
          right:      '10px',
          zIndex:     50, // Une valeur supérieure à celle de l'image
          background: 'transparent',
          border:     'none',
          cursor:     'pointer',
		  color:      'white'
        }}
        onClick={handleClosebutton}   >
        <img src="https://lovelight.tv/images/icons/close.png"
		     alt="croix" />
      </button>


				
	   <div className = { classNames(styles.grid_1)} >
			
	      <div className = { classNames(styles.grid_1_line_1)} >
		  
		  { renderImages () }
			
          </div>
			
			 
		  <div className = { classNames(styles.grid_1_line_2,styles.bg_gradient)} >
			 
		     <div className = { classNames(styles.grid_2)} >
			 
			    <div className = { classNames(styles.grid_2_line_1)} >

                  <p className = { classNames(styles.title)} >
					<div dangerouslySetInnerHTML={{ __html: product.name }} /> 
				  </p>

                </div>
				
			    <div className = { classNames(styles.grid_2_line_2)} >
				
				{/*
                  <WidgetFrame name               = "Variant"
   							   labelTextPosition  = "left"
							   labelBlockPosition = "top" >
								
                    <Select choices       = { productVariantNames }
                            value         = { productVariantNames[currentVariant] }
                            onChange      = { handleVariantChange }	/>

				  </WidgetFrame>
                */}

                  <div className = { classNames(styles.variant_selector)} >
                    <Select choices       = { productVariantNames }
                            value         = { productVariantNames[currentVariant] }
                            onChange      = { handleVariantChange }	/>
				  </div>
				
                </div>
				
			    <div className = { classNames(styles.grid_2_line_3)} >

				  <div className={classNames(styles.btn)}>
           
		            <button className = "relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50" 
		                    onClick   = {handleAddToCard} >
			 
			          <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
                      <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-[#FFFF00] px-3 py-1 text-xl font-medium text-black backdrop-blur-3xl">
                         Ajouter au panier
                      </span>
          
    		        </button>
          
		          </div>

			    </div>
			   
			    {/*
			    <div className = { classNames(styles.grid_2_line_4)} >
				
			      <div className = { classNames(styles.description)} >
					<div dangerouslySetInnerHTML={{ __html: product.description }} /> 
			      </div>
				   
                </div>
                */}
				
			 </div>
			 
		  </div>
			  
       </div>
			 
    </div>
           
   );
};


export default ProductVariantChoice;
