import   axios                    , 
       { AxiosError               }  from 'axios';
	   
import { format                   ,
         parse                    ,
		 parseISO                 }  from 'date-fns';

import { User                     }  from '../datas/userManagment/actionTypes';

import { initialUser              }  from '../datas/userManagment/reducer';

import { getUserAgentInformations ,
         getAppInformations       }  from './utils';
		 
import { API_URLS                 }  from './urls';


export interface PostResult {
  code:    number,
  comment: string
}

export const postUser = async ( 
                                email:       string ,
                                password:    string ,
								firstname:   string ,
								lastname:    string ,
                                sex:         string ,
                                rawBirthday: string ): Promise<PostResult> => {

	const birthday              = format ( parse ( rawBirthday, 'dd/MM/yyyy', new Date() ) , 'yyyy-MM-dd' );

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json',
	  'User-Agent-Informations': `${userAgentInformations}`,
	  'App-Informations': `${appInformations}`,
    };
	
	var result:PostResult = {
      code:    0,
      comment: ""
    };

    try {

        const response = await axios.post   (
        
                                               `${API_URLS.postUser}`,
                              
                                               { 
                                                 email     ,
                                                 password  ,
                                                 sex       ,
                                                 birthday  ,
                                                 firstname ,
                                                 lastname												 
                                               },
                                            
                                               {
                                                 headers: headers
                                               }
                                            );
											
		if ( response.status === 201 )
		      { 
			   result = {
		         code:    1,
		         comment: "Utilisateur créé"
		       };
			  }
		 else {
			   result = {
		         code:    -101,
		         comment: `Erreur lors de la création : ${response.status}`
		       };
			  }

    } 
    catch ( error ) {

        result = {
		  code:    -201,
		  comment: "Erreur réseau"
		};    
		
		
	}

	return result;
};

export interface PatchUserInformationsResult {
  code:    number,
  comment: string
}

export const patchUserInformations = async ( token:       string ,
											 id:          number ,
                                             firstname:   string ,
                                             lastname:    string ,
                                             sex:         string ,
                                             rawBirthday: string ,
                                             address:     string ,
                                             address2:    string ,
                                             zipCode:     string ,
                                             city:        string ,
                                             country:     string ,
                                             phoneNumber: string ): Promise<PatchUserInformationsResult> => {

    const baseUrl =  `${API_URLS.patchUserInformations}`; 

    const url = baseUrl.replace("{id}", `${id}`);

	const birthday              = format ( parse ( rawBirthday, 'dd/MM/yyyy', new Date() ) , 'yyyy-MM-dd' );
	
	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${token}`,
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

	var result:PatchUserInformationsResult = {
      code:    0,
      comment: ""
    };
	
    try {

        const response = await axios.patch (
                                                 url,
                              
                                               { 
                                                 firstname             ,
                                                 lastname              ,
                                                 sex                   ,
                                                 birthday              ,
                                                 address               ,
                                                 address2              ,
                                                 zipCode               ,
                                                 city                  ,
                                                 country               ,
                                                 phoneNumber           												 
                                               },
                                            
                                               {
                                                 headers: headers
                                               }
                                           );
										
        switch ( response.status )
	     {
	      case 200 : result = {
		                        code:    1,
		                        comment: "Utilisateur modifié"
		                      };
			  
			         break;
				   
          default :  result = {
		                        code:    -101 ,
		                        comment: `Erreur lors de la modification de l'utilisateur : ${response.status}`
		                      };
	     }

    } 
    catch ( err ) {

        const error = err as AxiosError;

        if ( error.response && error.response.status )
	          {
               switch ( error.response.status )
		        {
		         case 401 : result = {
		                               code:    -102,
		                               comment: "Session expirée"
		                             };
							
				            break;

                 default :  result = {
		                               code:    -201,
		                               comment: "Erreur réseau"
		                             };
                }
              }
         else {
	           result = {
		                  code:    -201,
		                  comment: "Erreur réseau"
		                };
	          }	   
    }

	return result;
};

export interface PatchUserCurrentOfferTerminationResult {
  code:                        number,
  comment:                     string,
  nextStatus?:                 string | null,
  nextStatusRequestDate?:      Date | null,
  nextStatusRequestIpAddress?: string | null,
  status?:                     string | null,
  statusDeadline?:             Date | null
}

export const patchUserCurrentOfferTermination = async ( token:       string ,
                                                        id:          number ): Promise<PatchUserCurrentOfferTerminationResult> => {

    const baseUrl =  `${API_URLS.patchUserCurrentOfferTermination}`;

    const url = baseUrl.replace("{id}", `${id}`);

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${token}`,
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

	var result:PatchUserCurrentOfferTerminationResult = {
      code:                       0,
      comment:                    "",
      nextStatus:                 null,
      nextStatusRequestDate:      null,
      nextStatusRequestIpAddress: null,
      status:                     null,
      statusDeadline:             null
    };

    try {

        const response = await axios.patch (
                                                 url,

                                               {},

                                               {
                                                 headers: headers
                                               }
                                           );


        switch ( response.status )
	     {
	      case 200 : result = {
		                        code:                       1,
		                        comment:                    "Demande de résiliation de l'offre actuelle à l'échéance enregistrée",
                                nextStatus:                 response.data.nextStatus,
                                nextStatusRequestDate:      response.data.nextStatusRequestDate,
                                nextStatusRequestIpAddress: response.data.nextStatusRequestIpAddress,
                                status:                     response.data.status,
                                statusDeadline:             response.data.statusDeadline
		                      };

			         break;

          default :  result = {
		                        code:                       -101 ,
		                        comment:                    `Erreur lors de la demande de résiliation de l'offre actuelle : ${response.status}`,
                                nextStatus:                 null,
                                nextStatusRequestDate:      null,
                                nextStatusRequestIpAddress: null,
                                status:                     null,
                                statusDeadline:             null
		                      };
	     }

    }
    catch ( err ) {

        const error = err as AxiosError;

        if ( error.response && error.response.status )
	          {
               switch ( error.response.status )
		        {
		         case 401 : result = {
		                               code:                       -102,
		                               comment:                    "Session expirée",
                                       nextStatus:                 null,
                                       nextStatusRequestDate:      null,
                                       nextStatusRequestIpAddress: null,
                                       status:                     null,
                                       statusDeadline:             null
		                             };

				            break;

                 default :  result = {
		                               code:                       -201,
		                               comment:                    "Erreur réseau",
                                       nextStatus:                 null,
                                       nextStatusRequestDate:      null,
                                       nextStatusRequestIpAddress: null,
                                       status:                     null,
                                       statusDeadline:             null
		                             };
                }
              }
         else {
	           result = {
		                  code:                       -201,
		                  comment:                    "Erreur réseau",
                          nextStatus:                 null,
                          nextStatusRequestDate:      null,
                          nextStatusRequestIpAddress: null,
                          status:                     null,
                          statusDeadline:             null
		                };
	          }
    }

	return result;
};


export interface PatchUserCurrentOfferTerminationCancellationResult {
  code:                        number,
  comment:                     string,
  nextStatus?:                 string | null,
  nextStatusRequestDate?:      Date | null,
  nextStatusRequestIpAddress?: string | null,
  status?:                     string | null,
  statusDeadline?:             Date | null
}

export const patchUserCurrentOfferTerminationCancellation = async ( token:       string ,
                                                                    id:          number ): Promise<PatchUserCurrentOfferTerminationCancellationResult> => {

    const baseUrl =  `${API_URLS.patchUserCurrentOfferTerminationCancellation}`;

    const url = baseUrl.replace("{id}", `${id}`);

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${token}`,
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

	var result:PatchUserCurrentOfferTerminationCancellationResult = {
      code:                       0,
      comment:                    "",
      nextStatus:                 null,
      nextStatusRequestDate:      null,
      nextStatusRequestIpAddress: null,
      status:                     null,
      statusDeadline:             null
    };

    try {

        const response = await axios.patch (
                                                 url,

                                               {},

                                               {
                                                 headers: headers
                                               }
                                           );


        switch ( response.status )
	     {
	      case 200 : result = {
		                        code:                       1,
		                        comment:                    "Demande de résiliation de l'offre actuelle à l'échéance enregistrée",
                                nextStatus:                 response.data.nextStatus,
                                nextStatusRequestDate:      response.data.nextStatusRequestDate,
                                nextStatusRequestIpAddress: response.data.nextStatusRequestIpAddress,
                                status:                     response.data.status,
                                statusDeadline:             response.data.statusDeadline
		                      };

			         break;

          default :  result = {
		                        code:                       -101 ,
		                        comment:                    `Erreur lors de la demande de résiliation de l'offre actuelle : ${response.status}`,
                                nextStatus:                 null,
                                nextStatusRequestDate:      null,
                                nextStatusRequestIpAddress: null,
                                status:                     null,
                                statusDeadline:             null
		                      };
	     }

    }
    catch ( err ) {

        const error = err as AxiosError;

        if ( error.response && error.response.status )
	          {
               switch ( error.response.status )
		        {
		         case 401 : result = {
		                               code:                       -102,
		                               comment:                    "Session expirée",
                                       nextStatus:                 null,
                                       nextStatusRequestDate:      null,
                                       nextStatusRequestIpAddress: null,
                                       status:                     null,
                                       statusDeadline:             null
		                             };

				            break;

                 default :  result = {
		                               code:                       -201,
		                               comment:                    "Erreur réseau",
                                       nextStatus:                 null,
                                       nextStatusRequestDate:      null,
                                       nextStatusRequestIpAddress: null,
                                       status:                     null,
                                       statusDeadline:             null
		                             };
                }
              }
         else {
	           result = {
		                  code:                       -201,
		                  comment:                    "Erreur réseau",
                          nextStatus:                 null,
                          nextStatusRequestDate:      null,
                          nextStatusRequestIpAddress: null,
                          status:                     null,
                          statusDeadline:             null
		                };
	          }
    }

	return result;
};


export interface PatchUserDiscoverOfferChoiceAtCreationResult {
  code:            number,
  comment:         string,
  status?:         string | null,
  statusDeadline?: Date | null
}

export const patchUserDiscoverOfferChoiceAtCreation = async ( token: string ,
                                                              id:    number ): Promise<PatchUserDiscoverOfferChoiceAtCreationResult> => {

    const baseUrl =  `${API_URLS.patchUserDiscoverOfferChoiceAtCreation}`;

    const url = baseUrl.replace("{id}", `${id}`);

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${token}`,
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

	var result:PatchUserDiscoverOfferChoiceAtCreationResult = {
      code:           0,
      comment:        "",
      status:         null,
      statusDeadline: null
    };

    try {

        const response = await axios.patch (
                                               url,

                                               {},

                                               {
                                                 headers: headers
                                               }
                                           );

        console.log ("patchUserDiscoverOfferChoiceAtCreation ", response)

        switch ( response.status )
	     {
	      case 200 : result = {
		                        code:                       1,
		                        comment:                    "Choix de l'offre Découverte à la création du compte",
                                status:                     response.data.status,
                                statusDeadline:             response.data.statusDeadline
		                      };

			         break;

          default :  result = {
		                        code:                       -101 ,
		                        comment:                    `Erreur lors du hoix de l'offre Découverte à la création du compte : ${response.status}`,
                                status:                     null,
                                statusDeadline:             null
		                      };
	     }

    }
    catch ( err ) {

        const error = err as AxiosError;

        console.log ("patchUserDiscoverOfferChoiceAtCreation ", error)

        if ( error.response && error.response.status )
	          {
               switch ( error.response.status )
		        {
		         case 401 : result = {
		                               code:                       -102,
		                               comment:                    "Session expirée",
                                       status:                     null,
                                       statusDeadline:             null
		                             };

				            break;

                 default :  result = {
		                               code:                       -201,
		                               comment:                    "Erreur réseau",
                                       status:                     null,
                                       statusDeadline:             null
		                             };
                }
              }
         else {
	           result = {
		                  code:                       -201,
		                  comment:                    "Erreur réseau",
                          status:                     null,
                          statusDeadline:             null
		                };
	          }
    }

	return result;
};





export interface PatchUserAccountClosureResult {
  code:    number,
  comment: string
}

export const patchUserAccountClosure = async ( token:       string ,
                                               id:          number ): Promise<PatchUserAccountClosureResult> => {

    const baseUrl =  `${API_URLS.patchUserAccountClosure}`;

    const url = baseUrl.replace("{id}", `${id}`);

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${token}`,
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

	var result:PatchUserAccountClosureResult = {
      code:    0,
      comment: ""
    };

    try {

        const response = await axios.patch (
                                                 url,

                                               {},

                                               {
                                                 headers: headers
                                               }
                                           );


        switch ( response.status )
	     {
	      case 200 : result = {
		                        code:    1,
		                        comment: "Demande de suppression du compte enregistrée"
		                      };

			         break;

          default :  result = {
		                        code:    -101 ,
		                        comment: `Erreur lors de la demande de suppression du compte : ${response.status}`
		                      };
	     }

    }
    catch ( err ) {

        const error = err as AxiosError;

        if ( error.response && error.response.status )
	          {
               switch ( error.response.status )
		        {
		         case 401 : result = {
		                               code:    -102,
		                               comment: "Session expirée"
		                             };

				            break;

                 default :  result = {
		                               code:    -201,
		                               comment: "Erreur réseau"
		                             };
                }
              }
         else {
	           result = {
		                  code:    -201,
		                  comment: "Erreur réseau"
		                };
	          }
    }

	return result;
};



export interface UserExistenceCheckResult {
  code:    number,
  comment: string
}

export const getUserExistenceCheck = async ( rawField: string             , 
                                             rawValue: string             ,
											 email?:   string | undefined ): Promise<UserExistenceCheckResult> => {

    const baseUrl  =  `${API_URLS.getUserExistenceCheck}?`;

    const name1  = "field="; 
    const name2  = "value=";
    const value1 = encodeURIComponent (rawField);      
    const value2 = encodeURIComponent (rawValue); 

    var url = `${baseUrl}${name1}${value1}&${name2}${value2}`;

    if ( typeof ( email ) !== "undefined" )
	 {
      url = url + `&email=${email}`;
	 }
		  
	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };
		  
    var result:UserExistenceCheckResult = {
      code:    0,
      comment: ""
    };

    try {

		const response = await axios.get ( url , { headers: headers } );
		
		const statut   = response.status;
		const exist    = response.data['hydra:member'][0].exist;

		if ( statut === 200 )
		      {
			   if (exist === "true" ) 
		             {
                      result = {
                        code:    1,
                        comment: "Utilisateur trouvé"
					   }
                     }
                else {
                      result = {
                        code:    -1,
                        comment: "Utilisateur non trouvé"
                      };        
				     }			   
		      }
		 else {
               result = {
                 code:    -101,
                 comment: `Erreur lors de la recherche de l'utilisateur : ${response.status}`
               };        
		      }
    } 
    catch (err) {
	
        result = {
		  code:    -201,
		  comment: "Erreur réseau"
		};           
    }

    return result;
};

export interface UserEmailValidationResult {
  code:      number,
  comment:   string,
  checkDate: string
}

export const getUserEmailValidation = async (token: string): Promise<UserEmailValidationResult> => {

    const baseUrl  = `${API_URLS.getUserEmailValidation}?`;
    const name1  = "token=";
    const value1 = encodeURIComponent ( token );
	
    const url = `${baseUrl}${name1}${value1}`;

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

    var result:UserEmailValidationResult = {
      code:      0  ,
      comment:   "" ,
	  checkDate: ""
    };

    try {

      const response  = await axios.get(url , { headers: headers } );
      const statut    = response.status;

      if ( statut === 200 )
	        {
             const code      = response.data['hydra:member'][0].code;
             const checkDate = response.data['hydra:member'][0].checkDate;
	  
	         if ( code > 0 )
                   {
                    if ( checkDate !== undefined )
                          {
                           result = {
                             code:      2  ,
				             comment:   "L'adresse email a déjà été vérifiée" ,
                             checkDate: format ( parseISO (checkDate), 'dd/MM/yyyy' )
                           };
                          }
                     else {
                           result = {
                             code:      1  ,
				             comment:   "L'adresse email a été vérifiée" ,
                             checkDate: ""
                           };
                          }
                   }
	          else {
	                result = {
                      code:      -1 ,
			     	  comment:   "L'adresse email n'a pas pu être vérifiée" ,
                      checkDate: ""
                    };
				   }
			}
	   else {
             result = {
               code:    -101,
               comment: `Erreur lors de la validation de l'email : ${response.status}`,
			   checkDate: ""
             };        
		    }
	   
    }
    catch (err) {
	
        result = {
		  code:      -201,
		  comment:   "Erreur réseau",
		  checkDate: ""
		};   
    }

   return result;
  };

export interface UserEmailValidationCheckResult {
  code:      number,
  comment:   string,
  checkDate: string
}

export const getUserEmailValidationCheck = async (rawEmail: string,
                                                  token:    string): Promise<UserEmailValidationCheckResult> => {

    const baseUrl = `${API_URLS.getUserEmailValidationCheck}?email=`;
    const email   =  encodeURIComponent (rawEmail);

    const url = `${baseUrl}${email}`;

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/ld+json',
		'Content-Type': 'application/ld+json',
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

    var result: UserEmailValidationCheckResult = {
      code:      0  ,
      comment:   "" ,
	  checkDate: ""
    };

    try {

        const response  = await axios.get(url , { headers: headers } );
        const statut    = response.status;

        switch ( statut )
	     {
	      case 200 : const code      = response.data['hydra:member'][0].code;
                     const checkDate = response.data['hydra:member'][0].checkDate;
	  
			         if ( code > 0 )
                           {
                            result = {
                                       code:      1         ,
  			                           comment:   "L'adresse email a bien été vérifiée"        ,
                                       checkDate: checkDate
                                     };
                           }
	                  else {
                            result = {
                                       code:      -1 ,
  			                           comment:   "L'adresse email n'a pas été vérifiée" ,
                                       checkDate: ""
                                     };
	                       }
			  
  			         break;

          default :  result = {
                                code:    -101,
                                comment: `Erreur lors de la vérification de la validation de l'email : ${response.status}`,
 	   		                    checkDate: ""
                              };        	   
	     }
    } 

    catch ( err ) {

        const error = err as AxiosError;

        if ( error.response && error.response.status )
	          {
               switch ( error.response.status )
		        {
		         case 401 : result = {
		                               code:    -102,
		                               comment: "Session expirée",
 	   		                           checkDate: ""
		                             };
							
				            break;

                 default :  result = {
		                               code:    -201,
		                               comment: "Erreur réseau",
 	   		                           checkDate: ""
		                             };
                }
              }
         else {
	           result = {
		                  code:    -201,
		                  comment: "Erreur réseau",
 	   		              checkDate: ""
		                };
	          }	   
    }

   return result;
  };

export interface AuthenticationResult {
  code:    number,
  comment: string
  token:   string
}

export const postUserAuthentication = async ( email:    string ,
                                              password: string ): Promise<AuthenticationResult>  => {

    var result: AuthenticationResult = {
      code:     0 ,
	  comment: "" ,
      token:   ""
    };

    try {

      const response = await axios.post (

                                          `${API_URLS.postUserAuthentication}`,

                                          {
                                            email    ,
                                            password              
                                          }

                                         );

      if ( response.status === 200 )
            {
             result = {
               code:    1,
 	   		   comment: "Authentification réussie",
               token:   response.data.token
             };
			 
			 postAuthenticationResult ( email    ,
                                        "Succès" );
            }
       else {
             result = {
               code:    -1,
 	   		   comment: "Authentification échouée",
               token:   ""
             };
			 
			 postAuthenticationResult ( email   ,
                                        "Echec" );
            }
    }
    catch (err) {

      const error = err as AxiosError;

        result = {
		  code:    -201,
		  comment: "Erreur réseau",
          token:   ""
		};    
		
		postAuthenticationResult ( email              ,
                                   "Erreur réseau"    );

    }
	
   return result;
  };

export interface UserInfos {
  code:    number,
  comment: string,
  infos:   User
}

export const getUserInfos = async ( email: string ,
                                    token: string ) : Promise<UserInfos>  => {

    const baseUrl = `${API_URLS.getUserInfosByEmail}?`;
    const name    = "email=";
    const value   = encodeURIComponent (email);

    const url = `${baseUrl}${name}${value}`;

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
        'Authorization': `Bearer ${token}`,
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

    const maxDate = new Date();
    maxDate.setTime(Number.MAX_VALUE);

    var result:UserInfos = {
      code:     0 ,
	  comment: "" ,
      infos:   initialUser
    };

    try {

      const response = await axios.get(url, { headers: headers });
      const statut = response.status;

      switch ( statut  )
	   {
	    case 200 : result = {
                              code:    1,
	                          comment: "Informations trouvées",
                              infos: {
                                       id:             response.data.id,
                                       email:          response.data.email,
                                       newEmail:       "" ,
                                       password:       "",
                                       password2:      "",
                                       firstname:      response.data.firstname,
                                       lastname:       response.data.lastname,
                                       sex:            response.data.sex,
                                       birthday:       format ( parseISO (response.data.birthday.substring (0,10)), 'dd/MM/yyyy' ) ,
                                       address:        response.data.address,
                                       address2:       response.data.address2,
                                       zipCode:        response.data.zipCode,
                                       city:           response.data.city,
                                       country:        response.data.country,
                                       phoneNumber:    response.data.phoneNumber,
                                       status:         response.data.status,
                                       statusDeadline: response.data.statusDeadline,
									   nextStatus:            response.data.nextStatus,
									   nextStatusRequestDate: response.data.nextStatusRequestDate,
                                       state:          response.data.state,
                                       rgpdConsent:    true,
                                       verify:         (response.data.state === "Verified"),
		                               payments:       []
                                     }
                            }

                   break;
			 
		default :  result = {
                              code:    -101,
	                          comment: `Erreur lors de la récupération des informations de l'utilisateur : ${response.status}`,
                              infos:   initialUser
                            };
       }

    }
    catch ( err ) {

        const error = err as AxiosError;

        if ( error.response && error.response.status )
	          {
               switch ( error.response.status )
		        {
		         case 401 : result = {
		                        code:    -102,
		                        comment: "Session expirée",
                                infos:   initialUser
		                      };
							
				            break;

                 default :  result = {
                                code:    -201,
 	                            comment: "Erreur réseau",
                                infos:   initialUser
                                };
                }
              }
         else {
	           result = {
                                code:    -201,
 	                            comment: "Erreur réseau",
                                infos:   initialUser
                        };
	          }	   
    }

    return result;
  };

export interface UserPasswordResetRequestResult {
  code:    number,
  comment: string
}

export const getUserPasswordResetRequest = async ( email: string ): Promise<UserPasswordResetRequestResult>   => {

    const baseUrl = `${API_URLS.getUserPasswordResetRequest}?`;
    const name1   = "email=";
    const value1  = encodeURIComponent ( email     );

    const url = `${baseUrl}${name1}${value1}`;

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

    var result: UserPasswordResetRequestResult = {
      code:     0 ,
      comment: ""
    };

    try {

      const response = await axios.get(url , { headers: headers } );
      const statut   = response.status;

      if ( statut === 200 )
	        {
             const code = response.data['hydra:member'][0].code;
	  
			 if ( code === 1 )
                  {
                   result = {
                     code:    1,
                     comment: "Le mail, contenant le lien pour réinitialiser le mot de passe, a été envoyé"
                   };
                  }
	         else {
                   result = {
                     code:    -1,
                     comment: "L'API n'a pas répondu positivement"
                   };
				  }
	        }
	   else {
             result = {
               code:    -101,
               comment: `Erreur lors de la demande de réinitialisation du mot de passe : ${response.status}`
             };        	   	   
	        }
    }
    catch (error) {

     result = {
        code:    -201,
        comment: "Erreur réseau"
      };
    }

    return result;
  };

export interface UserEmailResetRequestResult {
  code:    number,
  comment: string
}

export const getUserEmailResetRequest = async ( email: string ,
                                                token: string ): Promise<UserEmailResetRequestResult>   => {

    const baseUrl = `${API_URLS.getUserEmailResetRequest}?`;
    const name1   = "email=";
    const value1  = encodeURIComponent ( email     );

    const url = `${baseUrl}${name1}${value1}`;

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
        'Authorization': `Bearer ${token}`,
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

    var result: UserEmailResetRequestResult = {
      code:     0 ,
      comment: ""
    };

    try {

      const response = await axios.get(url , { headers: headers } );

      switch ( response.status )
	   {
	    case 200 : const code = response.data['hydra:member'][0].code;
			 
			       if ( code === 1 )
                         {
                          result = {
                                     code:    1,
                                     comment: "Le mail, contenant le lien pour réinitialiser l'adresse email, a été envoyé"
                             };
                         }
	                else {
                          result = {
                                     code:    -1,
                                     comment: "L'API n'a pas répondu positivement"
                                   };
	                     }
						 
				   break;
				   
        default :  result = {
                              code:    -101,
                              comment: `Erreur lors de la demande de réinitialisation de l'adresse email : ${response.status}`
                            };        	   	   
	   }
    }
    catch ( err ) {

        const error = err as AxiosError;

        if ( error.response && error.response.status )
	          {
               switch ( error.response.status )
		        {
		         case 401 : result = {
		                               code:    -102,
		                               comment: "Session expirée"
		                             };
							
				            break;

                 default :  result = {
		                               code:    -201,
		                               comment: "Erreur réseau"
		                             };
                }
              }
         else {
	           result = {
		                  code:    -201,
		                  comment: "Erreur réseau"
		                };
	          }	   
    }
	
    return result;
  };

export interface UserPasswordResetResult {
  code:    number,
  comment: string
}

export const getUserPasswordReset = async ( token:    string ,
                                            password: string ): Promise<UserPasswordResetResult>   => {

    const baseUrl = `${API_URLS.getUserPasswordReset}?`;
    const name1   = "token=";
    const name2   = "password=";
    const value1  = encodeURIComponent ( token     );
    const value2  = encodeURIComponent ( password  );

    const url = `${baseUrl}${name1}${value1}&${name2}${value2}`;

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

    var result: UserPasswordResetResult = {
      code:     0 ,
      comment: ""
    };

    try {

      const response = await axios.get(url , { headers: headers } );
      const statut   = response.status;

      if ( statut === 200 )
	        {
             const code = response.data['hydra:member'][0].code;

			 if ( code === 1 )
			      {
                   result = {
                      code:    1,
                      comment: "Le mot de passe a été modifié"
                   };
                  }
             else {
                   result = {
                     code:    -1,
                     comment: "L'API n'a pas répondu positivement"
                   };
                  }
		    }
	   else {
             result = {
               code:    -101,
               comment: `Erreur lors de la modification du mot de passe : ${response.status}`
             };        	   	   
	        }
    }
    catch (error) {

      result = {
        code:    -201,
        comment: "Erreur réseau"
      };
    }
	
   return result;
  };

export interface UserEmailResetResult {
  code:    number,
  comment: string
}

export const getUserEmailReset = async ( token:    string ,
                                         newEmail: string ): Promise<UserEmailResetResult>   => {

    const baseUrl = `${API_URLS.getUserEmailReset}?`;
    const name1   = "token=";
    const name2   = "newEmail=";
    const value1  = encodeURIComponent ( token     );
    const value2  = encodeURIComponent ( newEmail  );

    const url = `${baseUrl}${name1}${value1}&${name2}${value2}`;
    
	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

    const headers = {
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };

    var result: UserPasswordResetResult = {
      code:     0 ,
      comment: ""
    };
    
    try {

      const response = await axios.get(url , { headers: headers } );
      const statut   = response.status;

      if ( statut === 200 )
	        {
             const code = response.data['hydra:member'][0].code;
	  
			 if ( code === 1 )
                   {
                    result = {
                      code:    1,
                      comment: "L'adresse email a été modifiée"
                    };
                   }
              else {
                    result = {
                      code:    -1,
                      comment: "L'API n'a pas répondu positivement"
                    };
                   }
		    }
	   else {
             result = {
               code:    -101,
               comment: `Erreur lors de la modification de l'adresse email : ${response.status}`
             };        	   	   
	        }
    }
    catch (error) {

      result = {
        code:    -201,
        comment: "Erreur réseau"
      };
    }

   return result;
  };

export interface PostResult {
  code:    number,
  comment: string
}

export const postAuthenticationResult = async ( email:  string ,
                                                result: string ): Promise<PostResult> => {

	const userAgentInformations = getUserAgentInformations ();
	const appInformations       = getAppInformations ();

	const headers = {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json',
	  'User-Agent-Informations': `${userAgentInformations}`,
      'App-Informations': `${appInformations}`,
    };
	
	var apiResult:PostResult = {
      code:    0,
      comment: ""
    };

    try {

        const response = await axios.post (
        
                                               `${API_URLS.postAuthenticationResult}`,
                              
                                               { 
                                                 email    ,
                                                 result 
                                               },
                                            
                                               {
                                                 headers: headers
                                               }
                                              );
						
		if ( response.status === 201 )
		      { 
			   apiResult = {
		         code:    1 ,
		         comment: "Notification du résultat de l'authentification"
		       };
			  }
		 else {
			   apiResult = {
		         code:    -101 ,
		         comment: `Erreur lors de la notification du résultat de l'authentification ${response.status}`
		       };
			  }

    } 
    catch ( error ) {

        apiResult = {
		  code:    -201 ,
		  comment: "Erreur réseau"
		};    
		
		
	}

	return apiResult;
};
