import   React                     , 
       { useEffect                 }  from 'react';

import   classNames                   from 'classnames';

import   styles                       from './mandatory_informations.module.scss';

import { LeafletCookiesInformation }  from './blocks/leaflet_cookies_information/leaflet_cookies_information';

import { GlobalMenu                }  from '../../menu/global/global';
import { TopShifter                }  from '../../menu/top_shifter/top_shifter';
import { CookiesManager            }  from '../../cookie/manager';
import { Footer                    }  from '../../menu/footer/footer';

import { useTypedSelector          }  from '../../../datas/useTypeSelector';

import { postEvent                 }  from '../../../network/api_events_stats';

export const CookiesInformation = () => {

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );

  useEffect(() => {

	postEvent ( "Ouverture de /notice-d-information-sur-les-cookies" , authentication.login );

  }, [] );

  return (

        <>

          <GlobalMenu />
          <CookiesManager />
          <TopShifter />

          <div className={classNames(styles.root)}>

            <div className={classNames(styles.shift)}></div>

            <div className={classNames(styles.contain)}>

                <LeafletCookiesInformation/>
        
            </div>
            
          </div>

          <Footer />
        
        </>

            
  );
};

export default CookiesInformation;
