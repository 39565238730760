import { Payment } from '../paymentManagment/actionTypes';

export enum ActionType
 {
  INIT_USER                       = 'INIT_USER'      ,
  SET_USER                        = 'SET_USER'       ,
  UPDATE_ID                       = 'UPDATE_ID'              ,
  UPDATE_EMAIL                    = 'UPDATE_EMAIL'           ,
  UPDATE_NEWEMAIL                 = 'UPDATE_NEWEMAIL'        ,
  UPDATE_PASSWORD                 = 'UPDATE_PASSWORD'        ,
  UPDATE_PASSWORD2                = 'UPDATE_PASSWORD2'       ,
  UPDATE_FIRSTNAME                = 'UPDATE_FIRSTNAME'       ,
  UPDATE_LASTNAME                 = 'UPDATE_LASTNAME'        ,
  UPDATE_SEX                      = 'UPDATE_SEX'             ,
  UPDATE_BIRTHDAY                 = 'UPDATE_BIRTHDAY'        ,
  UPDATE_ADDRESS                  = 'UPDATE_ADDRESS'         ,
  UPDATE_ADDRESS2                 = 'UPDATE_ADDRESS2'        ,
  UPDATE_ZIPCODE                  = 'UPDATE_ZIPCODE'         ,
  UPDATE_CITY                     = 'UPDATE_CITY'            ,
  UPDATE_COUNTRY                  = 'UPDATE_COUNTRY'         ,
  UPDATE_PHONENUMBER              = 'UPDATE_PHONENUMBER'     ,
  UPDATE_STATUS                   = 'UPDATE_STATUS'          ,
  UPDATE_STATUS_DEADLINE          = 'UPDATE_STATUS_DEADLINE' ,
  UPDATE_NEXT_STATUS              = 'UPDATE_NEXT_STATUS'          ,
  UPDATE_NEXT_STATUS_REQUEST_DATE = 'UPDATE_NEXT_STATUS_REQUEST_DATE' ,
  UPDATE_RGPDCONSENT              = 'UPDATE_RGPDCONSENT'     ,
  VERIFY_EMAIL                    = 'VERIFY_EMAIL'    ,
  UPDATE_STATE                    = 'UPDATE_STATE'    ,
  INIT_PAYMENTS_SET               = 'INIT_PAYMENTS_SET'      ,
  ADD_PAYMENT                     = 'ADD_PAYMENT'            ,
 }

export interface User
 {
  id:                    number  ,
  email:                 string  ,
  newEmail:              string  ,
  password:              string  ,
  password2:             string  ,
  firstname:             string  ,
  lastname:              string  ,
  sex:                   string  ,
  birthday:              string  ,
  address:               string  ,
  address2:              string  ,
  zipCode:               string  ,
  city:                  string  ,
  country:               string  ,
  phoneNumber:           string  ,
  status:                string  ,
  statusDeadline:        Date    ,
  nextStatus:            string  ,
  nextStatusRequestDate: Date | null ,
  rgpdConsent:           boolean ,
  verify:                boolean ,
  state:                 string  ,
  payments:              Payment[]
 }

export interface actionInitUser
 {
  type:                   ActionType.INIT_USER;
 }

export interface actionSetUser
 {
  type:                   ActionType.SET_USER;
  newId:                  number;
  newEmail:               string;
  newNewEmail:            string;
  newFirstname:           string;
  newLastname:            string;
  newSex:                 string;
  newBirthday:            string;
  newAddress:             string;
  newAddress2:            string;
  newZipCode:             string;
  newCity:                string;
  newCountry:             string;
  newPhoneNumber:         string;
  newStatus:              string;
  newStatusDeadline:      Date;
  newNextStatus:                 string;
  newNextStatusRequestDate:      Date | null;
  newState:               string;
 }

export interface actionUpdateId
 {
  type:                   ActionType.UPDATE_ID
  newId:                  number;
 }

export interface actionUpdateEmail
 {
  type:                   ActionType.UPDATE_EMAIL
  newEmail:               string;
 }

export interface actionUpdateNewEmail
 {
  type:                   ActionType.UPDATE_NEWEMAIL
  newNewEmail:            string;
 }

export interface actionUpdatePassword
 {
  type:                   ActionType.UPDATE_PASSWORD;
  newPassword:            string;
 }

export interface actionUpdatePassword2
 {
  type:                   ActionType.UPDATE_PASSWORD2;
  newPassword2:           string;
 }

export interface actionUpdateFirstname
 {
  type:                   ActionType.UPDATE_FIRSTNAME;
  newFirstname:           string;
 }

export interface actionUpdateLastname
 {
  type:                   ActionType.UPDATE_LASTNAME;
  newLastname:            string;
 }

export interface actionUpdateSex
 {
  type:                   ActionType.UPDATE_SEX;
  newSex:                 string;
 }

export interface actionUpdateBirthday
 {
  type:                   ActionType.UPDATE_BIRTHDAY;
  newBirthday:            string;
 }

export interface actionUpdateAddress
 {
  type:                   ActionType.UPDATE_ADDRESS;
  newAddress:             string;
 }

export interface actionUpdateAddress2
 {
  type:                   ActionType.UPDATE_ADDRESS2;
  newAddress2:            string;
 }

export interface actionUpdateZipCode
 {
  type:                   ActionType.UPDATE_ZIPCODE;
  newZipCode:             string;
 }

export interface actionUpdateCity
 {
  type:                   ActionType.UPDATE_CITY;
  newCity:                string;
 }

export interface actionUpdateCountry
 {
  type:                   ActionType.UPDATE_COUNTRY;
  newCountry:             string;
 }

export interface actionUpdatePhoneNumber
 {
  type:                   ActionType.UPDATE_PHONENUMBER;
  newPhoneNumber:         string;
 }

export interface actionUpdateStatus
 {
  type:                   ActionType.UPDATE_STATUS;
  newStatus:              string;
 }

export interface actionUpdateStatusDeadline
 {
  type:                   ActionType.UPDATE_STATUS_DEADLINE;
  newStatusDeadline:      Date;
 }

export interface actionUpdateNextStatus
 {
  type:                   ActionType.UPDATE_NEXT_STATUS;
  newNextStatus:          string;
 }

export interface actionUpdateNextStatusRequestDate
 {
  type:                     ActionType.UPDATE_NEXT_STATUS_REQUEST_DATE;
  newNextStatusRequestDate?: Date | null
 }

export interface actionUpdateRgpdConsent
 {
  type:                   ActionType.UPDATE_RGPDCONSENT;
  newRgpdConsent:         boolean;
 }

export interface actionVerifyEmail
 {
  type:                   ActionType.VERIFY_EMAIL;
  newVerify:              boolean;
 }

export interface actionUpdateState
 {
  type:                   ActionType.UPDATE_STATE;
  newState:               string;
 }

export interface actionAddPayment
 {
  type:                   ActionType.ADD_PAYMENT;
  newPayment:             Payment;
 }

export interface actionInitPaymentsSet
 {
  type:                   ActionType.INIT_PAYMENTS_SET;
 }

export type Action = actionInitUser                |
                     actionSetUser                 |
					 actionUpdateId                        | 
                     actionUpdateEmail                     | 
                     actionUpdateNewEmail                  |
                     actionUpdatePassword                  |
                     actionUpdatePassword2                 | 
                     actionUpdateFirstname                 | 
					 actionUpdateLastname                  |
					 actionUpdateSex                       |
					 actionUpdateBirthday                  |
					 actionUpdateAddress                   |
                     actionUpdateAddress2                  |
                     actionUpdateZipCode                   |
                     actionUpdateCity                      |
                     actionUpdateCountry                   |
                     actionUpdatePhoneNumber               |
					 actionUpdateStatus                    |
					 actionUpdateStatusDeadline            |
					 actionUpdateNextStatus                |
					 actionUpdateNextStatusRequestDate     |
					 actionUpdateRgpdConsent               |
					 actionVerifyEmail                     |
					 actionUpdateState                     |
					 actionAddPayment                      |
					 actionInitPaymentsSet;


export type DispatchTypeInitUser                       = ( args: actionInitUser                       ) => actionInitUser
export type DispatchTypeSetUser                        = ( args: actionSetUser                        ) => actionSetUser
export type DispatchTypeUpdateId                       = ( args: actionUpdateId                       ) => actionUpdateId
export type DispatchTypeUpdateEmail                    = ( args: actionUpdateEmail                    ) => actionUpdateEmail
export type DispatchTypeUpdateNewEmail                 = ( args: actionUpdateNewEmail                 ) => actionUpdateNewEmail
export type DispatchTypeUpdatePassword                 = ( args: actionUpdatePassword                 ) => actionUpdatePassword
export type DispatchTypeUpdatePassword2                = ( args: actionUpdatePassword2                ) => actionUpdatePassword2
export type DispatchTypeUpdateFirstname                = ( args: actionUpdateFirstname                ) => actionUpdateFirstname
export type DispatchTypeUpdateLastname                 = ( args: actionUpdateLastname                 ) => actionUpdateLastname
export type DispatchTypeUpdateSex                      = ( args: actionUpdateSex                      ) => actionUpdateSex
export type DispatchTypeUpdateBirthday                 = ( args: actionUpdateBirthday                 ) => actionUpdateBirthday
export type DispatchTypeUpdateAddress                  = ( args: actionUpdateAddress                  ) => actionUpdateAddress
export type DispatchTypeUpdateAddress2                 = ( args: actionUpdateAddress2                 ) => actionUpdateAddress2
export type DispatchTypeUpdateZipCode                  = ( args: actionUpdateZipCode                  ) => actionUpdateZipCode
export type DispatchTypeUpdateCity                     = ( args: actionUpdateCity                     ) => actionUpdateCity
export type DispatchTypeUpdateCountry                  = ( args: actionUpdateCountry                  ) => actionUpdateCountry
export type DispatchTypeUpdatePhoneNumber              = ( args: actionUpdatePhoneNumber              ) => actionUpdatePhoneNumber
export type DispatchTypeUpdateStatus                   = ( args: actionUpdateStatus                   ) => actionUpdateStatus
export type DispatchTypeUpdateStatusDeadline           = ( args: actionUpdateStatusDeadline           ) => actionUpdateStatusDeadline
export type DispatchTypeUpdateNextStatus               = ( args: actionUpdateNextStatus               ) => actionUpdateNextStatus
export type DispatchTypeUpdateNextStatusRequestDate    = ( args: actionUpdateNextStatusRequestDate    ) => actionUpdateNextStatusRequestDate
export type DispatchTypeUpdateRgpdConsent              = ( args: actionUpdateRgpdConsent              ) => actionUpdateRgpdConsent
export type DispatchTypeVerifyEmail                    = ( args: actionVerifyEmail                    ) => actionVerifyEmail
export type DispatchTypeUpdateState                    = ( args: actionUpdateState                    ) => actionUpdateState
export type DispatchTypeAddPayment                     = ( args: actionAddPayment                     ) => actionAddPayment
export type DispatchTypeInitPaymentsSet                = ( args: actionInitPaymentsSet                ) => actionInitPaymentsSet

					 
