import   React                  ,
       { useState               ,
         useEffect              }  from 'react';

import { format                 ,
         differenceInDays       }  from 'date-fns';


import   classNames                from 'classnames';
  
import   styles                    from './product_choice4.module.scss';
 
import { Window                 }  from '../../widget/window/window';
import { WindowWithConfirmation }  from '../../widget/window/window_with_confirmation';

import   Slider                    from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



export interface ProductChoice4Props {

  currentOfferNumber:              number,
  currentOfferDeadline:            Date | null,
  nextOfferNumber:                 number,
  offerModificationRequestDate:    Date | null,
  onActionChange:                  ( id: number , price: number) => void ,
  onValidButtonClick:              () => void,
  onValidButtonClickOnDiscovert?:  ( () => void ) | undefined ,
  onOfferTerminationCancellation?: ( () => void ) | undefined ,
}


interface Offer {
  title:    JSX.Element;
  subtitle: JSX.Element;
  features: number[];
}


export const ProductChoice4 = ({ currentOfferNumber             ,
                                 currentOfferDeadline     ,
                                 nextOfferNumber                ,
                                 offerModificationRequestDate   ,
                                 onActionChange                 ,
                                 onValidButtonClick             ,
                                 onValidButtonClickOnDiscovert  ,
                                 onOfferTerminationCancellation }: ProductChoice4Props ) => {


  let originalPriceForStandardOffer : number =  69
  let originalPriceForPremiumOffer  : number = 119

  let priceForStandardOffer         : number = originalPriceForStandardOffer
  let priceForPremiumOffer          : number = originalPriceForPremiumOffer

  const computeReduction = (): number => {

    let ratio: number = 1
	
    if ( currentOfferDeadline !== null && currentOfferDeadline instanceof Date )
	 {
      const now = new Date();
      const daysRemaining = differenceInDays(currentOfferDeadline, now);
     
 	  ratio = daysRemaining / 365;
     }
	 
    switch (currentOfferNumber) 
	 {
      case 0  : return 0;

      case 1  : return Math.ceil( originalPriceForStandardOffer * ratio );

      case 2  : return Math.ceil( originalPriceForPremiumOffer  * ratio );

      default : return 0;
     }
  }


  const computePriceLabel = (offerNumber: number): string => {

    const reduction: number = computeReduction ();

    switch (offerNumber) 
	 {
      case 0  : return "Gratuit";

      case 1  : return `${originalPriceForStandardOffer}€/an`;

      case 2  : if ( currentOfferNumber === offerNumber || reduction === 0 ) 
	                  {
                       return `${originalPriceForPremiumOffer}€/an`;
                      } 
			     else {
				       priceForPremiumOffer = originalPriceForPremiumOffer - reduction

 				       return `${priceForPremiumOffer}€ puis ${originalPriceForPremiumOffer}€/an`;
					  }

      default : return "";
     }
  }

  const offers: Offer[] = [
    {
      title:    <>Offre découverte</>,
      subtitle: <>{computePriceLabel(0)}</>,
      features: [
                  1, // <>Vidéo sans coupure pub</>                                                                                  V
                  0, // <>Accès aux vidéos avant la sortie sur Youtube</>                                                            F
                  0, // <>Accès aux lives et découvrez des programmes exclusifs</>                                                   F
                  0, // <>Accès aux hotline</>                                                                                       F
                  0, // <>Accès aux offres &laquo;&nbsp;MasterClasses Lovelight Urban Tour&nbsp;&raquo;</>                           F
                  0, // <>Invitation aux événements Lovelight</>                                                                     F
                ],
    },
    {
      title:    <>Offre standard</>,
      subtitle: <>{computePriceLabel(1)}</>,
      features: [
                  1, // <>Vidéo sans coupure pub</>                                                                                  V
                  1, // <>Accès aux vidéos avant la sortie sur Youtube</>                                                            V
                  1, // <>Accès aux lives et découvrez des programmes exclusifs</>                                                   V
                  1, // <>Accès aux hotline</>                                                                                       V
                  0, // <>Accès aux offres &laquo;&nbsp;MasterClasses Lovelight Urban Tour&nbsp;&raquo;</>                           F
                  0, // <>Invitation aux événements Lovelight</>                                                                     F
                ],
    },
    {
      title:    <>Offre premium</>,
      subtitle: <>{computePriceLabel(2)}</>,
      features: [
                  1, // <>Vidéo sans coupure pub</>                                                                                  V
                  1, // <>Accès aux vidéos avant la sortie sur Youtube</>                                                            V
                  1, // <>Accès aux lives et découvrez des programmes exclusifs</>                                                   V
                  1, // <>Accès aux hotline</>                                                                                       V
                  1, // <>Accès aux offres &laquo;&nbsp;MasterClasses Lovelight Urban Tour&nbsp;&raquo;</>                           F
                  1, // <>Invitation aux événements Lovelight</>                                                                     F
                ]
    }
  ];
  
  const features: JSX.Element [] = [

    <>Vidéo sans coupure pub</> ,
    <>Vidéos avant la sortie sur Youtube</> ,
    <>Lives et programmes exclusifs</> ,
    <>Poser vos questions pendant les lives hotline</> ,
    <>&laquo;&nbsp;MasterClasses Lovelight Urban Tour&nbsp;&raquo;</> ,
    <>Invitation aux événements Lovelight</>
  ]

  const [ showWindow     , setShowWindow     ] = useState ( false );
  const [ windowMessage  , setWindowMessage  ] = useState ( <></> );

  const [ showWindow2    , setShowWindow2    ] = useState ( false );
  const [ windowMessage2 , setWindowMessage2 ] = useState ( <></> );

  const [ showWindow3    , setShowWindow3    ] = useState ( false );
  const [ windowMessage3 , setWindowMessage3 ] = useState ( <></> );

  const [ showWindow4    , setShowWindow4    ] = useState ( false );
  const [ windowMessage4 , setWindowMessage4 ] = useState ( <></> );

  const [ selectedAction , setSelectedAction ] = useState ( 0 );

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  const element: HTMLElement | null = document.getElementById('globalMenu');
  const elementStyle                = element !== null ? window.getComputedStyle(element) : null;

  const menuWidthLimit              = 1050; // parseInt( menuWidthLimitStr );

  const handleResize = () => {
      setIsSmallScreen ( window.innerWidth <= menuWidthLimit );
    };

  useEffect(() => {

  }, [isSmallScreen]);

  useEffect(() => {

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);


  const renderFeature = ( num: number ) : JSX.Element => {

    switch ( num )
     {
      case 0  : return <><img src ="/images/icons/no2.png"  className = {classNames(styles.icon)} alt = "no"  /></>
      case 1  : return <><img src ="/images/icons/yes2.png" className = {classNames(styles.icon)} alt = "yes" /></>
      default : return <>&nbsp;</>
     }
  }

  const render_currentOfferLabel = () : JSX.Element => {

    let message: string = "En cours"

    if ( currentOfferDeadline !== null && currentOfferDeadline instanceof Date )
     {
      message = `En cours jusqu'au ${format(currentOfferDeadline as Date, 'dd/MM/yyyy')}`
     }

    return (
             <>
              <span className = "text-center text-base font-bold"
                    style    = {{ color: '#FFFF00' }} >
                {message}
              </span>
             </>
           )
  }

  const render_unavailableOfferLabel = () : JSX.Element => {

    return (
             <>
              <span className = "text-center text-base font-bold"
                    style    = {{ color: '#FFFFFF' }} >
               Indisponible
              </span>
             </>
           )
  }


  const render_chooseOfferButton = (id:number, price:number) : JSX.Element => {

    return (
             <>
              <button className="relative inline-flex justify-left h-8 w-24 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
                      onClick={() => handleChooseOffer(id,price*100)}>
               <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
               <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 text-base font-medium text-white backdrop-blur-3xl">
                Choisir
               </span>
              </button>
             </>
           )
  }

  const render_cancelButton = () : JSX.Element => {

    return (
             <>
              <button className="relative inline-flex justify-left h-8 w-24 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
                      onClick={() => handleCancelOffer()}>
               <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
               <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-slate-950 text-base font-medium text-white backdrop-blur-3xl">
                Annuler
               </span>
              </button>
             </>
           )
  }

  const renderAction = ( numOffer : number ) : JSX.Element => {

    switch ( currentOfferNumber )
     {
      case 0 : // L'offre en cours est "Découverte"

               switch ( selectedAction )
                {
                 case 0 :  // Aucune action

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_currentOfferLabel ()

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_chooseOfferButton (2, priceForStandardOffer)
                                             }
                                        else {
                                              return <></>
                                             }

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_chooseOfferButton (3, priceForPremiumOffer)
                                             }
                                        else {
                                              return <></>
                                             }

                             default : return <></>
                            }

                 case 2 :  // On a appuyé sur "Choisir Standard"

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_currentOfferLabel ()

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_cancelButton ()
                                             }
                                        else {
                                              return <></>
                                             }

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_chooseOfferButton (3, priceForPremiumOffer)
                                             }
                                        else {
                                              return <></>
                                             }

                             default : return <></>
                            }

                 case 3 :  // On a appuyé sur "Choisir Premium"

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_currentOfferLabel ()

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_chooseOfferButton (2, priceForStandardOffer)
                                             }
                                        else {
                                              return <></>
                                             }

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_cancelButton ()
                                             }
                                        else {
                                              return <></>
                                             }

                             default : return <></>
                            }

                 default : return <></>
                }

      case 1 : // L'offre en cours est "Standard"

               switch ( selectedAction )
                {
                 case 0 :  // Aucune action

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_unavailableOfferLabel () // render_chooseOfferButton (1)

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       return render_currentOfferLabel ()

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_chooseOfferButton (3, priceForPremiumOffer)
                                             }
                                        else {
                                              return <></>
                                             }

                             default : return <></>

                            }

                 case 1 :  // On a appuyé sur "Choisir Découverte"

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_unavailableOfferLabel ()  // render_cancelButton ()

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       return render_currentOfferLabel ()

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_chooseOfferButton (3, priceForPremiumOffer)
                                             }
                                        else {
                                              return <></>
                                             }

                             default : return <></>

                            }

                 case 3 :  // On a appuyé sur "Choisir Premium"

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_unavailableOfferLabel ()  // render_chooseOfferButton (1)

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       return render_currentOfferLabel ()

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_cancelButton ()
                                             }
                                        else {
                                              return <></>
                                             }

                             default : return <></>

                            }

                 case -2 :  // On a appuyé sur "Résilier Standard"

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_unavailableOfferLabel ()  // render_chooseOfferButton (1)

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       return render_currentOfferLabel ()

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_chooseOfferButton (3, priceForPremiumOffer)
                                             }
                                        else {
                                              return <></>
                                             }

                             default : return <></>

                            }

                 default : return <></>
                }

      case 2 : // L'offre en cours est "Premium"

               switch ( selectedAction )
                {
                 case 0 :  // Aucune action

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_unavailableOfferLabel ()  // render_chooseOfferButton (1)

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_chooseOfferButton (2, priceForStandardOffer)
                                             }
                                        else {
                                              return <></>
                                             }

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       return render_currentOfferLabel ()

                             default : return <></>

                            }

                 case 1 :  // On a appuyé sur "Choisir Découverte"

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_unavailableOfferLabel ()  // render_cancelButton ()

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_chooseOfferButton (2, priceForStandardOffer)
                                             }
                                        else {
                                              return <></>
                                             }

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       return render_currentOfferLabel () 

                             default : return <></>

                            }

                 case 2 :  // On a appuyé sur "Choisir Standard"

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_unavailableOfferLabel ()  // render_chooseOfferButton (1)

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_cancelButton ()
                                             }
                                        else {
                                              return <></>
                                             }

                             case 2 : // On rend le bouton de la colonne "Premium"

                                      return render_currentOfferLabel ()

                             default : return <></>

                            }

                 case -3 :  // On a appuyé sur "Résilier Premium"

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_unavailableOfferLabel ()  // render_chooseOfferButton (1)

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       if ( nextOfferNumber === -1 )
                                             {
                                              return render_chooseOfferButton (2, priceForStandardOffer)
                                             }
                                        else {
                                              return <></>
                                             }

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       return render_currentOfferLabel ()

                             default : return <></>

                            }

                 default : return <></>

                }

      case 3 : // L'offre en cours est "Frez"

               switch ( selectedAction )
                {
                 case 0 :  // Aucune action

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_chooseOfferButton (1,0)

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       return render_chooseOfferButton (2, priceForStandardOffer)

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       return render_chooseOfferButton (3, priceForPremiumOffer)

                             default : return <></>

                            }

                 case 1 :  // On a appuyé sur "Choisir Découverte"

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_cancelButton ()

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       return render_chooseOfferButton (2, priceForStandardOffer)

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       return render_chooseOfferButton (3, priceForPremiumOffer)

                             default : return <></>

                            }

                 case 2 :  // On a appuyé sur "Choisir Standard"

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_chooseOfferButton (1,0)

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       return render_cancelButton ()

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       return render_chooseOfferButton (3, priceForPremiumOffer)

                             default : return <></>

                            }

                 case 3 :  // On a appuyé sur "Choisir Premium"

                           switch ( numOffer )
                            {
                             case 0 :  // On rend le bouton de la colonne "Découverte"

                                       return render_chooseOfferButton (1,0)

                             case 1 :  // On rend le bouton de la colonne "Standard"

                                       return render_chooseOfferButton (2, priceForStandardOffer)

                             case 2 :  // On rend le bouton de la colonne "Premium"

                                       return render_cancelButton ()

                             default : return <></>

                            }

                 default : return <></>
                }


      default : return <></>
    }

  }

  const determineDecoClassName = ( num: number ): string => {

    if ( currentOfferNumber !== num )
          {
           if ( nextOfferNumber === -1 )
                 {
                  if ( selectedAction === (num+1) || selectedAction === -(num+1) )
                        {
                         return styles.deco_choosen_offer
                        }
                   else {
                         return styles.deco_offer
                        }
                 }
            else {
                  return styles.deco_offer_no_hover
                 }
          }
     else {
           return styles.deco_current_offer
          }
  
  }

  const render_offerColumn_v1 = ( num: number ) => {


    return (

       <div className = { classNames ( num === 0 ? styles.discover_offer_column : ( num === 1 ? styles.standard_offer_column : styles.premium_offer_column ) , determineDecoClassName(num) ) } >

          <div className = { classNames ( styles.choices_offer_title ) } >

             { offers[num].title }
             <br/>
             { offers[num].subtitle }

            </div>

            <div className = { classNames ( styles.choices_offer_content ) } >

               {
                 offers[num].features.map ( (feature, n) => (

                   <>
                     <div className = { classNames ( styles.choices_features_grid_row ) } >
                       {renderFeature (feature)}
                     </div>
                   </>

                 ) )
               }

            </div>

            <div className = { classNames ( styles.choices_offer_state_action)}>

                <div className={classNames(styles.infos)}>
                  { renderAction (num) }
                </div>

            </div>

       </div>

    )
  }

  const render_offerColumn_v2 = ( num: number ) => {

    return (

       <div className = { classNames ( num === 0 ? styles.discover_offer_column : ( num === 1 ? styles.standard_offer_column : styles.premium_offer_column ) , determineDecoClassName (num) ) } >

          <div className = { classNames ( styles.choices_offer_title ) } >

             { offers[num].title }
             <br/>
             { offers[num].subtitle }

            </div>

            <div className = { classNames ( styles.choices_offer_content ) } >

               {
                 offers[num].features.map ( (feature, n) => (

                   <>
                     <div className = { classNames ( styles.choices_features_grid_row_v2 ) } >
                       <div className = { classNames ( styles.choices_features_grid_row_v2_col_1 ) } >
                        {renderFeature (feature)}
                       </div>
                       <div className = { classNames ( styles.choices_features_grid_row_v2_col_2 ) } >
                        {features[n]}
                       </div>
                     </div>
                   </>

                 ) )
               }

            </div>

            <div className = { classNames ( styles.choices_offer_state_action)}>

                <div className={classNames(styles.infos)}>
                  { renderAction (num) }
                </div>

            </div>

       </div>

    )
  }


  const render_offers_v1 = () => {

    return (

      <>
      <div className={classNames(styles.choices)}>

         <div className={classNames(styles.features_column)}>

           <div className={classNames(styles.top_blank)}>
           </div>

           <div className={classNames(styles.choices_features_grid)}>

               {
                features.map ( (feature, num) => (

                  <>
                  <div className={classNames(styles.choices_features_grid_row)}>
                     {feature}
                     </div>
                     </>
                ) )
               }

           </div>

           <div className={classNames(styles.bottom_blank)}>
           </div>

         </div>

         { render_offerColumn_v1 (0) }
         { render_offerColumn_v1 (1) }
         { render_offerColumn_v1 (2) }

      </div>
      </>
    )
  }

  const render_offers_v2 = () => {

    let settings = {
       infinite:       false,
       arrows:         true,
       speed:          500,
       slidesToShow:   1,
       slidesToScroll: 1,
       initialSlide:   currentOfferNumber
      };

    let tab: JSX.Element [] = [

      render_offerColumn_v2 (0) ,
      render_offerColumn_v2 (1) ,
      render_offerColumn_v2 (2) ,
    ]

    return (

      <>
      <div className={classNames(styles.choices_v2)}>

        <Slider {...settings}>
          {tab}
        </Slider>


      </div>
      </>
    )
  }


  const render_nextOfferMessage = () => {
   
     switch ( nextOfferNumber )
      {
       case 0 :  return (
                         <>
                          <p className={classNames(styles.infos_red)}>Vous avez choisi de résilier votre offre en cours</p>
                          <br/>
                           <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                                 onClick = {handleCancelOfferTermination} >
                             Annuler la demande de résiliation
                           </button>
                           <br/>
                           <br/>
                          </>
                         )

       case 1 :  return (
                         <>
                          <p className={classNames(styles.infos)}>Vous avez choisi changer votre abonnnement en cours en abonnement standard</p>
                          <br/>
                         </>
                        )
  
       case 2 :  return (
                         <>
                          <p className={classNames(styles.infos)}>Vous avez choisi changer votre abonnnement en cours en abonnement premium</p>
                          <br/>
                         </>
                        )
       default : return <></>
      }
  }

  const handleChooseOffer = ( id: number , price: number ) => {

    setSelectedAction ( id )
    onActionChange    ( id , price )
  }

  const handleCancelOffer = () => {

    setSelectedAction ( 0 )
    onActionChange    ( 0 , 0 )
  }

  const handleTerminateOffer = ( id: number ) => {

    setSelectedAction ( -id )
    onActionChange    ( -id , 0 )
  }

  ///////////

  const handleCancelOfferTermination = () => {

    setWindowMessage (<>Confirmer l'annulation de la demande de résiliation ?</>);
    setShowWindow	 (true)
  }

  const handleTerminateCurrentOffer = () => {

    setSelectedAction (-(currentOfferNumber + 1))
    onActionChange    (-(currentOfferNumber + 1),0)

    setWindowMessage2 (<>Confirmer la demande de résiliation ?</>);
    setShowWindow2	 (true)
  }

  const handleValidateButtonClick = () => {

	 switch ( selectedAction )
     {
      case 1  : setWindowMessage3 (<></>);
                setWindowMessage4 (<>Confirmer le choix de l'offre Découverte ?</>);
                setShowWindow3    (false)
                setShowWindow4    (true)
                break;

      case 2  : setWindowMessage3 (<>Vous allez être redirigé vers la page de paiement</>);
                setWindowMessage4 (<></>);
                setShowWindow3    (true)
                setShowWindow4    (false)
                break;

      case 3  : setWindowMessage3 (<>Vous allez être redirigé vers la page de paiement</>);
                setWindowMessage4 (<></>);
                setShowWindow3    (true)
                setShowWindow4    (false)
                break;

      default : setWindowMessage3 (<></>);
                setWindowMessage4 (<></>);
                setShowWindow3    (false)
                setShowWindow4    (false)
     }
     	 
  }

  /////////

  const handleValidButtonClickOnWindow = () => {

    setWindowMessage (<></>);
    setShowWindow	 (false)

    if ( typeof (onOfferTerminationCancellation) !== 'undefined' )
     {
      onOfferTerminationCancellation ()
     }
  }

  const handleCancelButtonClickOnWindow = () => {

    setWindowMessage (<></>);
    setShowWindow	 (false)
  }

  const handleValidButtonClickOnWindow2 = () => {

    setWindowMessage2 (<></>);
    setShowWindow2	  (false)

    onValidButtonClick ()
  }

  const handleCancelButtonClickOnWindow2 = () => {

    setWindowMessage2 (<></>);
    setShowWindow2	  (false)
  }

  const handleValidButtonClickOnWindow3 = () => {

    setWindowMessage3 (<></>);
    setShowWindow3	  (false)

    onValidButtonClick ()
  }

  const handleCancelButtonClickOnWindow3 = () => {

    setWindowMessage3 (<></>);
    setShowWindow3	  (false)
  }

  const handleValidButtonClickOnWindow4 = () => {

    console.log ("handleValidButtonClickOnWindow4")

    setWindowMessage4 (<></>);
    setShowWindow4	  (false)

    if ( typeof (onValidButtonClickOnDiscovert) !== "undefined" )
     {
      onValidButtonClickOnDiscovert ()
     }
  }

  const handleCancelButtonClickOnWindow4 = () => {

    setWindowMessage4 (<></>);
    setShowWindow4	  (false)
  }

  return (
       
    <>

      { render_nextOfferMessage () }

      {
        (
          isSmallScreen
        )
        ?
        (
          <>
            { render_offers_v2 () }
          </>
        )
        :
        (
          <>
            { render_offers_v1 () }
          </>
        )
      }

      {
        selectedAction > 0 &&
        (
          <>
           <br/>
           <div className={classNames(styles.centered)}>
             <button className = "px-4 py-2 rounded-full bg-[#FFFFFF] font-bold text-xs text-black tracking-widest uppercase transform border border-black hover:scale-105 hover:bg-[#E8FF33] hover:text-black hover:border-yellow-500 transition-colors duration-200"
                     onClick   = {handleValidateButtonClick}>
              Valider
             </button>
           </div>
          </>
        )
      }

      {/* render_terminateCurrentOfferMessage () */}

      {
		showWindow
		&&
        (
		 < Window message               = { windowMessage          }
		 	      onClickOnValidButton  = { handleValidButtonClickOnWindow }
		 	      onClickOnCancelButton = { handleCancelButtonClickOnWindow }
		 	      cancelButtonName      = {"NON"}
                  validButtonName       = {"OUI"}
			      opaqueBackgroundColor = "#333333"/>
		)
	  }

      {
		showWindow2
		&&
        (
		 < Window message               = { windowMessage2          }
		 	      onClickOnValidButton  = { handleValidButtonClickOnWindow2 }
		 	      onClickOnCancelButton = { handleCancelButtonClickOnWindow2 }
		 	      cancelButtonName      = {"NON"}
                  validButtonName       = {"OUI"}
			      opaqueBackgroundColor = "#333333"/>
		)
	  }

      {
		showWindow3
		&&
        (
		 < WindowWithConfirmation message               = { windowMessage3          }
		 	                      confirmationMessage   = {<>Je déclare renoncer à ma période de rétractation de 14 jours</>}
								  onClickOnValidButton  = { handleValidButtonClickOnWindow3 }
		 	                      onClickOnCancelButton = { handleCancelButtonClickOnWindow3 }
		 	                      cancelButtonName      = {"Annuler"}
                                  validButtonName       = {"Ok"}
			                      opaqueBackgroundColor = "#333333"/>
		)
	  }

      {
		showWindow4
		&&
        (
		 < Window message               = { windowMessage4          }
		 	      onClickOnValidButton  = { handleValidButtonClickOnWindow4 }
		 	      onClickOnCancelButton = { handleCancelButtonClickOnWindow4 }
		 	      cancelButtonName      = {"NON"}
                  validButtonName       = {"OUI"}
			      opaqueBackgroundColor = "#333333"/>
		)
	  }

    </>
  );

};


export default ProductChoice4;

