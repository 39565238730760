import   React                   ,
       { useState                ,
	     useEffect               }  from 'react';


import { useNavigate             }  from 'react-router-dom';

import { Dispatch                }  from 'redux';
import { useDispatch             }  from 'react-redux';

import   classNames                 from 'classnames';

import   styles                     from './offer_choice.module.scss';

import { Window                  }  from '../../../../widget/window/window';

import { ProductChoice4          }  from '../../../../block/product/product_choice4';

import { useTypedSelector        }  from '../../../../../datas/useTypeSelector';
  
import { Product                 }  from '../../../../../datas/productManagment/actionTypes';

import { initUser                ,
         setUser                 }  from '../../../../../datas/userManagment/actionCreators';

import { initAuthentication      }  from '../../../../../datas/authenticationManagment/actionCreators';

import { getProductInfos         }  from '../../../../../network/api_product';
  
import { postSubscription        }  from '../../../../../network/api_payment';
import { patchUserDiscoverOfferChoiceAtCreation }  from '../../../../../network/api_user';


import { INTERNAL_LINKS          }  from '../../../../../network/urls';

export const OfferChoice = () => {

  const { authentication } = useTypedSelector((state) => state.authenticationManagment);
  const { user           } = useTypedSelector((state) => state.userManagment);

  const dispatch: Dispatch<any> = useDispatch()
  const navigate = useNavigate();

  const empty:Product [] = []

  const [ first           , setFirst           ] = useState(false);
  const [ selectedProduct , setSelectedProduct ] = useState(0);
  const [ selectedVariant , setSelectedVariant ] = useState(0);
  const [ products        , setProducts        ] = useState(empty);

  const [ action , setAction ] = useState(0);
  const [ price  , setPrice  ] = useState(0);
  
  const [ showWindow            , setShowWindow         ] = useState ( false );
  const [ windowMessage         , setWindowMessage      ] = useState ( <></> );
  const [ expiredSession        , setExpiredSession     ] = useState ( false );

  useEffect(() => {
  
    if (!first) 
	 {
      init();
      setFirst(true);
     }
	 
  }, [first, selectedProduct, selectedVariant, products]);

  const init = async () => {
  
    let results = await getProductInfos ( "Offres découverte, standard et premium" )
	
    setProducts ( results.products )
  };
  
  const handleValidationButtonClick = () => {
  
    navigate ( `${INTERNAL_LINKS.AccountCreationSuccess}` );
  }

  const handleActionChange = (id: number, price: number) => {

    setAction (id)
    setPrice  (price)
  }

  const handleValidButtonClick = () => {

    makeSubscription ()
  }

  const handleValidButtonClickOnDiscover = () => {
   
   makeDiscoverOfferChoice ()
  }

  const makeDiscoverOfferChoice = async () => {
  
   console.log ( "makeDiscoverOfferChoice " , authentication.token , user.id )

   const result = await patchUserDiscoverOfferChoiceAtCreation ( authentication.token ,
                                                                 user.id              )

   if ( result.code === 1 )
         {
          dispatch ( initUser   () );
          dispatch ( initAuthentication (true) );

          navigate ( `${INTERNAL_LINKS.AccountCreationSuccess}` );
	     }
	else {
	      console.log ("ERREUR")
	     }
  }

  const handleCloseButtonClick = () => {

    setWindowMessage (<></>);
    setShowWindow	 (false)

    if ( expiredSession )
     {
	  dispatch ( initUser   () );
	  dispatch ( initAuthentication (true) );
      navigate ( `${INTERNAL_LINKS.Home}` );
	 }
  }

  const makeSubscription = async () => {

           // Les processeurs de ces 2 offres font la distinction entre achat et modif en fonction du status actuel (discover ou non)

           const response = await postSubscription (
                                                     price                                                                   , // Montant en centimes d'euros
                                                     0                                                                       , // Pas de réduction car c'est une première inscription'
                                                     "TV"                                                                    , // Code du business unit "Lovelight TV"
                                                     action === 2 ? "1" : "3"                                                , // Code des product vatiant "Offre standard annuel" / "ffre premium annuel"
                                                     "001"                                                                   , // Code du prix appliquée à cette vente
                                                     "01"                                                                    , // Code de bank "BNP - Axepta"
                                                     authentication.login                                                    , // Email du user
                                                     "Souscription de l'offre " + ( action === 2 ?  "Standard" : "Premium" ) , // Commentaire
                                                     authentication.token                                                      // Token JWT
                                                   )

           switch ( response.code )
	            {
                 case 1    : window.location.href = response.url;  // Amène à la page de paiement

			                 break;

	             case -102 : setWindowMessage (<>Votre sessions a expiré, veuillez vous reconnecter</>);
	                         setShowWindow    (true)
                             setExpiredSession (true);

				             break;

                 default :   setWindowMessage (<>Une erreur réseau est survenue lors de la génération de la demande de paiement</>);
                             setShowWindow    (true)
	            }
  }

  return (
  
    <div className={classNames(styles.root)}>
      
	  <div className={classNames(styles.form)}>
        
		<div className={classNames(styles.line_1)}>
          <p className={classNames(styles.subtitle)}>Étape 2 : Choix de l'offre</p>
        </div>
        
		<div className={classNames(styles.line_2)}>
		
		  {/* currentOfferNumber = { 3 } = Offre Free pour gérer le choix de Discover, en plus de Standard et Premium */}

		  <ProductChoice4 onActionChange                = { handleActionChange }
                          onValidButtonClick            = { handleValidButtonClick }
		                  onValidButtonClickOnDiscovert = { handleValidButtonClickOnDiscover }
                          currentOfferNumber            = { 3 }
				          currentOfferDeadline          = { null }
						  nextOfferNumber               = { -1 }
						  offerModificationRequestDate  = { null } />

        </div>

        {
		 showWindow
		 &&
         (
		  < Window message               = { windowMessage          }
			       onClickOnCloseButton  = { handleCloseButtonClick }
			       opaqueBackgroundColor = "#333333"/>
		 )
		}

      </div>

    </div>
	
  );
};

export default OfferChoice;
