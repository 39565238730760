import { Action     , 
         ActionType , 
		 Payment    } from './actionTypes';


interface State 
 {
  payment: Payment;
 }

const initialState = 
 {
  payment: 
   {
    description:     "" ,
    amount:          0  ,
	date:            "" ,
    meansOfPayment:  "" ,
    status:          "" ,
	transactionId:   ""
   } 
 }

const PaymentManagmentReducer = (state: State = initialState, action: Action):State => 
 {
  switch(action.type) 
   {
    case ActionType.INIT_PAYMENT:             state.payment.description     = ""
                                              state.payment.amount          = 0
                                              state.payment.date            = ""
                                              state.payment.meansOfPayment  = ""
                                              state.payment.status          = ""
                                              state.payment.transactionId   = ""
										      
                                              return state;

    case ActionType.SET_PAYMENT:              state.payment.description     = action.newDescription
                                              state.payment.amount          = action.newAmount
                                              state.payment.date            = action.newDate
                                              state.payment.meansOfPayment  = action.newMeansOfPayment
                                              state.payment.status          = action.newStatus
                                              state.payment.transactionId   = action.newTransactionId
										      
                                              return state;

    case ActionType.UPDATE_DESCRIPTION:       state.payment.description     = action.newDescription

                                              return state;
									 
    case ActionType.UPDATE_AMOUNT:            state.payment.amount          = action.newAmount
 
                                              return state;
									 
    case ActionType.UPDATE_DATE:              state.payment.date            = action.newDate
 
                                              return state;
									 
    case ActionType.UPDATE_MEANSOFPAYMENT:    state.payment.meansOfPayment  = action.newMeansOfPayment
 
                                              return state;
									 
    case ActionType.UPDATE_STATUS:            state.payment.status          = action.newStatus
 
                                              return state;
									 
    case ActionType.UPDATE_TRANSACTIONID:     state.payment.transactionId   = action.newTransactionId
 
                                              return state;

    default:                                  return state;
   }
 }


export default PaymentManagmentReducer;
