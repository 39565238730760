import   UAParser   ,
       { IResult    }  from 'ua-parser-js';

import { store      }  from '../datas/store';

import { buildDate  ,
         appState   }  from './build_infos';
	   
const path = "/";

interface ScreenInfo {
  width: number;
  height: number;
}

export interface ExtendedResult extends IResult {

  screen: ScreenInfo;
}
	
export const getUserAgentInformations = (): string => {

  const userAgentInformationsFromStore = store.getState().clientManagment.client.userAgentInformations;

  let userAgentInformations = "";

  if ( true ) //userAgentInformationsFromStore === "" ) 
        {
         userAgentInformations = JSON.stringify ( retrieveUserAgentInformations() );

         store.dispatch ( { type:                     'SET_UAINFOS'         , 
							newUserAgentInformations: userAgentInformations } );
        } 
   else {
         userAgentInformations = userAgentInformationsFromStore;
        }
		
  return userAgentInformations;
}

export const getAllCookiesConsent = (): number => {

  const allCookiesConsentFromStore = store.getState().clientManagment.client.allCookiesConsent;

  let allCookiesConsent = -100;

  if ( allCookiesConsentFromStore === -100 ) 
        {
         allCookiesConsent = retrieveCookieConsent("allCookiesConsent");

         store.dispatch ( { type:                 'SET_ALLCOOKIESCONSENT' , 
							newAllCookiesConsent: allCookiesConsent       } );
        } 
   else {
         allCookiesConsent = allCookiesConsentFromStore;
        }
		
  console.log ( "getAllCookiesConsent = " , allCookiesConsent );
		
  return allCookiesConsent;
} 
 
export const getFunctionalCookiesConsent = (): number => {

  const functionalCookiesConsentFromStore = store.getState().clientManagment.client.functionalCookiesConsent;

  let functionalCookiesConsent = -100;

  if ( functionalCookiesConsentFromStore === -100 ) 
        {
         functionalCookiesConsent = retrieveCookieConsent("functionalCookiesConsent");

         store.dispatch ( { type:                        'SET_FUNCTIONALCOOKIESCONSENT' , 
							newFunctionalCookiesConsent: functionalCookiesConsent       } );
        } 
   else {
         functionalCookiesConsent = functionalCookiesConsentFromStore;
        }
		
  console.log ( "getFunctionalCookiesConsent = " , functionalCookiesConsent );
		
  return functionalCookiesConsent;
} 
 
export const getAnalysisCookiesConsent = (): number => {

  const analysisCookiesConsentFromStore = store.getState().clientManagment.client.analysisCookiesConsent;

  let analysisCookiesConsent = -100;

  if ( analysisCookiesConsentFromStore === -100 ) 
        {
         analysisCookiesConsent = retrieveCookieConsent("analysisCookiesConsent");

         store.dispatch ( { type:                      'SET_ANALYSISCOOKIESCONSENT' , 
							newAnalysisCookiesConsent: analysisCookiesConsent       } );
        } 
   else {
         analysisCookiesConsent = analysisCookiesConsentFromStore;
        }
		
  console.log ( "getAnalysisCookiesConsent = " , analysisCookiesConsent );
		
  return analysisCookiesConsent;
} 
 
export const getMarketingCookiesConsent = (): number => {

  const marketingCookiesConsentFromStore = store.getState().clientManagment.client.marketingCookiesConsent;

  let marketingCookiesConsent = -100;

  if ( marketingCookiesConsentFromStore === -100 ) 
        {
         marketingCookiesConsent = retrieveCookieConsent("marketingCookiesConsent");

         store.dispatch ( { type:                     'SET_MARKETINGCOOKIESCONSENT' , 
							newMarketingCookiesConsent: marketingCookiesConsent     } );
        } 
   else {
         marketingCookiesConsent = marketingCookiesConsentFromStore;
        }

  console.log ( "getMarketingCookiesConsent = " , marketingCookiesConsent );
		
  return marketingCookiesConsent;
} 

export const updateAllCookiesConsent = (newAllCookiesConsent: number): void => {

  console.log ( "updateAllCookiesConsent = " , newAllCookiesConsent );

  store.dispatch ( { type:                 'SET_ALLCOOKIESCONSENT' , 
	  			     newAllCookiesConsent: newAllCookiesConsent    } );
					 
  updateCookieConsent("allCookiesConsent", newAllCookiesConsent);
  
  if ( newAllCookiesConsent === 1 )
   {
    updateFunctionalCookiesConsent ( 1 );
	updateAnalysisCookiesConsent   ( 1 );
	updateMarketingCookiesConsent  ( 1 );
   }
   
  if ( newAllCookiesConsent === -1 )
   {
    updateFunctionalCookiesConsent (  1 );  // Toujours à 1 en théorie ???
	updateAnalysisCookiesConsent   ( -1 );
	updateMarketingCookiesConsent  ( -1 );
   }
}

export const updateAllCookiesConsentFromCookiesValues = (): number => {

  console.log ( "updateAllCookiesConsentFromCookiesValues " );

  const test1 = ( getAnalysisCookiesConsent () ===  1 ) && ( getMarketingCookiesConsent () ===  1 );
  const test2 = ( getAnalysisCookiesConsent () === -1 ) && ( getMarketingCookiesConsent () === -1 );

  let newAllCookiesConsent:number = 0;

  if ( !test1 && !test2 ) { newAllCookiesConsent =  0 }
  if (  test1 && !test2 ) { newAllCookiesConsent =  1 }
  if ( !test1 &&  test2 ) { newAllCookiesConsent = -1 }

  store.dispatch ( { type:                 'SET_ALLCOOKIESCONSENT' , 
	  			     newAllCookiesConsent: newAllCookiesConsent    } );
					 
  updateCookieConsent("allCookiesConsent", newAllCookiesConsent);
  
  return newAllCookiesConsent;
}

export const updateFunctionalCookiesConsent = (newFunctionalCookiesConsent: number): void => {

  console.log ( "updateFunctionalCookiesConsent = " , newFunctionalCookiesConsent );

  store.dispatch ( { type:                        'SET_FUNCTIONALCOOKIESCONSENT' , 
				     newFunctionalCookiesConsent: newFunctionalCookiesConsent    } );

  updateCookieConsent("functionalCookiesConsent", newFunctionalCookiesConsent);
}

export const updateAnalysisCookiesConsent = (newAnalysisCookiesConsent: number): void => {

  console.log ( "updateAnalysisCookiesConsent = " , newAnalysisCookiesConsent );

  store.dispatch ( { type:                      'SET_ANALYSISCOOKIESCONSENT' , 
					 newAnalysisCookiesConsent: newAnalysisCookiesConsent       } );

  updateCookieConsent("analysisCookiesConsent", newAnalysisCookiesConsent);
}

export const updateMarketingCookiesConsent = (newMarketingCookiesConsent: number): void => {

  console.log ( "updateMarketingCookiesConsent = " , newMarketingCookiesConsent );

  store.dispatch ( { type:                       'SET_MARKETINGCOOKIESCONSENT' , 
					 newMarketingCookiesConsent: newMarketingCookiesConsent       } );

  updateCookieConsent("marketingCookiesConsent", newMarketingCookiesConsent);
}

export const checkCookiesExistence = (): boolean => {

  const test = checkCookieExistence ( "allCookiesConsent"        ) &&
               checkCookieExistence ( "functionalCookiesConsent" ) &&
			   checkCookieExistence ( "analysisCookiesConsent"   ) &&
			   checkCookieExistence ( "marketingCookiesConsent"  );
			   
  return test;
}

export const getAppState = () => {

//  0    En mode normal      ( / correspond home et le reste est actif )
//  1 :  En mode test        ( / correspond à la page de maintenance, /hometest correspond à home et le reste est désactivé )
// -1 :  En mode maintenance ( / correspond à la page de maintenance et le reste est désactivé )

  return appState;
}

export const getAppInformations = () => {

  const mode    = ( appState === 1 ? "test" : ( appState === -1 ? "maintenance" : "normal" ) );
  
  return `Version du ${buildDate} en mode ${mode}`;
}

export const retrieveUserAgentInformations = (): ExtendedResult => {

    const parser = new UAParser();
	
    const parserResults = parser.getResult();
	
    let extendedResults: ExtendedResult = {
  
       ...parserResults,
       screen: {
          width: window.screen.width,
          height: window.screen.height,
       },
    };
	
	return extendedResults;
}

const retrieveCookieConsent = ( name: string ): number => {

  const consentCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith(name + '='));
  
  if (consentCookie)
        {
         const consentValue = parseInt(consentCookie.split('=')[1]);
		 
         return consentValue;
        }
   else {
         return -1;
        }
}

const updateCookieConsent = (name: string, value: number): void => {

  const existingCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith(name + '='));

  var date           = new Date();
  var expirationDays = 365;
  
  date.setTime ( date.getTime() + (expirationDays * 24 * 60 * 60 * 1000) );
  
  var expires = date.toUTCString();

  if (existingCookie) 
        {
         const cookieParts = existingCookie.split('=');
         const cookieName = cookieParts[0].trim();
         const cookieValue = value.toString();

         document.cookie = `${cookieName}=${cookieValue}; expires=${expires}; path=${path};`;
        } 
   else {
         document.cookie = `${name}=${value}; expires=${expires}; path=${path};`;
        }
}

const checkCookieExistence = ( name: string ): boolean => {

  const consentCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith(name + '='));
  
  if (consentCookie)
        {
         return true;
        }
   else {
         return false;
        }
}


// https://developer.mozilla.org/fr/docs/Web/HTTP/Browser_detection_using_the_user_agent
// https://www.institut-pandore.com/hacking/comprendre-analyser-user-agent/
// https://github.com/faisalman/ua-parser-js
// https://dev.maxmind.com/geoip/geolite2-free-geolocation-datas
// https://analyticahouse.com/blog/how-to-integrate-google-analytics-4-to-react

