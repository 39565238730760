import   axios , { AxiosError }                      from 'axios';

import { format , parse } from 'date-fns';

import { Cart ,
         Item }  from '../datas/cartManagment/actionTypes';

import { getUserAgentInformations   ,
         getAppInformations         }  from './utils';	   

import { API_URLS                   }  from './urls';


export interface MergeWithUserCartResult {
  code:    number,
  comment: string
}

// On récupère le status du user pour éventuellement changer de tarif
// On appelle le endpoint de merge sur le serveu

export const mergeWithUserCart = async ( userEmail: string ,
                                         token:     string ,
									     cart:      Cart   ): Promise<MergeWithUserCartResult> => {

  console.log ("mergeWithUserCart");

  const userAgentInformations = getUserAgentInformations ();
  const appInformations       = getAppInformations ();
	
  const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json',
        'Authorization': `Bearer ${token}`,
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };
	
  var result: MergeWithUserCartResult = {
     code:    0,
     comment: ""
  }
  
  const cartStr: string = cartToJsonString (cart)
  
  console.log (cartStr)
		
  try {

        const response = await axios.post (
        
                                               `${API_URLS.postMergeWithUserCart}`,
                              
                                               { 
                                                 userEmail   ,
												 cartStr               											 
                                               },
                                            
                                               {
                                                 headers: headers
                                               }
                                            );

	    console.log ( "mergeWithUserCart",response);

        if ( response.status === 201 )
		      {
		        result = {
                  code:    1,
                  comment: "Fusion avec le panier du user²"
                };
		      }
		 else {
		        result = {
                  code:    -101,
                  comment: "Erreur lors de la fusion avec le panier du user²"
                };
		      }

    }  
    catch ( err ) {

       const error = err as AxiosError;

       console.log ( "mergeWithUserCart",error);

       result = {
                  code:    -201,
                  comment: "Erreur réseau"
                };
    }
  
  return result;
}

export interface GetCartResult {
  code:    number,
  comment: string,
  cart:    Cart
}

// On récupère le status du user pour éventuellement changer de tarif
// On appelle le endpoint de merge sur le serveu

export const getCart = async ( email: string ,
                               token: string ): Promise<GetCartResult> => {

  const baseUrl  =  `${API_URLS.getPurchaseInfosByEmail}?`;

  const name1  = "email="; 
  const name2  = "type=";
  const value1 = encodeURIComponent (email);      
  const value2 = encodeURIComponent ("unpaid"); 

  var url = `${baseUrl}${name1}${value1}&${name2}${value2}`;

        console.log ("RECUP", url)

  const userAgentInformations = getUserAgentInformations ();
  const appInformations       = getAppInformations ();

  const headers = {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/ld+json',
		'Content-Type': 'application/ld+json',
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };
  
  let result: GetCartResult = {
     code:    1,
     comment: "",
	 cart: {
       email: "",
	   purchaseId: 0,
       lastUpdate: null,
       items: []
       }
  }
  
    try {

        const response  = await axios.get(url , { headers: headers } );
        const statut    = response.status;

        console.log (response)

        switch ( statut )
	     {
	      case 200 :        const cart = response.data[0]
		  
		                    result = {
                                       code:      1         ,
  			                           comment:   "Récupération du panier"        ,
                                       cart: {
                                           email: email,
										   purchaseId: cart.id,
                                           lastUpdate: parse ( cart.creationDate, 'yyyy-MM-dd hh:mm:ss', new Date()),
                                           items: []
                                       }
                                     };
                     
					        for ( let i=0; i<cart.soldItems.length; i++ )
							 {
							  const newItem: Item = {
							  
							      productCode:        cart.soldItems[i].productCode ,
                                  productVariantCode: cart.soldItems[i].productVariantCode ,
                                  priceCode:          cart.soldItems[i].priceCode ,
                                  quantity:           cart.soldItems[i].quantity 
							  }
							  
							  result.cart.items.push (newItem)
							 }
					 
  			         break;

          default :  result = {
                                code:    -101,
                                comment: `Erreur lors de la récupération du panier : ${response.status}`,
 	   		                    cart: {
                                    email: "",
	                                purchaseId: 0,
                                    lastUpdate: null,
                                    items: []
                                }
                              };        	   
	     }
    } 

    catch ( err ) {

        const error = err as AxiosError;

        console.log (error)

        if ( error.response && error.response.status )
	          {
               switch ( error.response.status )
		        {
		         case 401 : result = {
		                               code:    -102,
		                               comment: "Session expirée",
 	   		                           cart: {
                                           email: "",
 	                                       purchaseId: 0,
                                           lastUpdate: null,
                                           items: []
                                       }
		                             };
							
				            break;

                 default :  result = {
		                               code:    -201,
		                               comment: "Erreur réseau",
 	   		                           cart: {
                                           email: "",
  								   	       purchaseId: 0,
                                           lastUpdate: null,
                                           items: []
                                       }
		                             };
                }
              }
         else {
	           result = {
		                  code:    -201,
		                  comment: "Erreur réseau",
 	   		              cart: {
                              email: "",
	                          purchaseId: 0,
                              lastUpdate: null,
                              items: []
                          }
		                };
	          }	   
    }
  
  return result;
}

export interface AddItemToUserCartResult {
  code:    number,
  comment: string
}

export const addItemToUserCart = async( email:              string ,
                                        token:              string ,
										purchaseId:         number ,
								        productCode:        string ,
                                        productVariantCode: string , 
	                                    priceCode:          string ,
                                        quantity:           number ): Promise<AddItemToUserCartResult> => {

  const baseUrl =  `${API_URLS.patchAddItemToUserCart}`; 

  const url = baseUrl.replace("{id}", `${purchaseId}`);

  const userAgentInformations = getUserAgentInformations ();
  const appInformations       = getAppInformations ();
	
  const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${token}`,
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };
	
  var result: AddItemToUserCartResult = {
     code:    0,
     comment: ""
  }

  const quantityStr: string = `${quantity}`
  		
  try {

        const response = await axios.patch (
        
                                               url,
                              
                                               { 
                                                 productCode   ,
												 productVariantCode ,
												 priceCode ,
                                                 quantityStr												 
                                               },
                                            
                                               {
                                                 headers: headers
                                               }
                                            );

	    console.log ( "addItemToUserCart",response);

      }
    catch ( err ) {

       const error = err as AxiosError;

       result = {
                  code:    -201,
                  comment: "Erreur réseau"
                };
    }
  
  return result;
}
export interface RemoveItemFromUserCartResult {
  code:    number,
  comment: string
}

export const removeItemFromUserCart = async( email:              string ,
                                             token:              string ,
								             purchaseId:         number ,
								             productCode:        string ,
                                             productVariantCode: string ): Promise<RemoveItemFromUserCartResult> => {

  const baseUrl =  `${API_URLS.patchRemoveItemToUserCart}`; 

  const url = baseUrl.replace("{id}", `${purchaseId}`);

  console.log ("removeItemFromUserCart", url);

  const userAgentInformations = getUserAgentInformations ();
  const appInformations       = getAppInformations ();
	
  const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${token}`,
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };
	
  var result: RemoveItemFromUserCartResult = {
     code:    0,
     comment: ""
  }
  
  try {

        const response = await axios.patch (
        
                                               url,
                              
                                               { 
                                                 productCode   ,
												 productVariantCode 									 
                                               },
                                            
                                               {
                                                 headers: headers
                                               }
                                            );

	    console.log ( "removeItemFromUserCart",response);

      }
    catch ( err ) {

       const error = err as AxiosError;

       console.log ( "removeItemFromUserCart",error);

       result = {
                  code:    -201,
                  comment: "Erreur réseau"
                };
    }
  
  return result;
}

export interface ChangeQuantityOfItemInUserCartResult {
  code:    number,
  comment: string
}

export const changeQuantityOfItemInUserCart = async( email:              string ,
                                                     token:              string ,
								                     purchaseId:         number ,
								                     productCode:        string ,
                                                     productVariantCode: string , 
                                                     quantity:           number ): Promise<ChangeQuantityOfItemInUserCartResult> => {

  const baseUrl =  `${API_URLS.patchChangeQuantityOfItemInUserCart}`; 

  const url = baseUrl.replace("{id}", `${purchaseId}`);

  console.log ("changeQuantityOfItemInUserCart", url);

  const userAgentInformations = getUserAgentInformations ();
  const appInformations       = getAppInformations ();
	
  const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${token}`,
	    'User-Agent-Informations': `${userAgentInformations}`,
	    'App-Informations': `${appInformations}`,
    };
	
  var result: ChangeQuantityOfItemInUserCartResult = {
     code:    0,
     comment: ""
  }
  
  const quantityStr: string = `${quantity}`
  
  try {

        const response = await axios.patch (
        
                                               url,
                              
                                               { 
                                                 productCode        ,
												 productVariantCode ,
                                                 quantityStr												 
                                               },
                                            
                                               {
                                                 headers: headers
                                               }
                                            );

	    console.log ( "removeItemFromUserCart",response);

      }
    catch ( err ) {

       const error = err as AxiosError;

       console.log ( "removeItemFromUserCart",error);

       result = {
                  code:    -201,
                  comment: "Erreur réseau"
                };
    }
  
  return result;

}


export const cartToJsonString = (cart: Cart): string => {

  const cartWithFormattedDate = {
    ...cart,
    lastUpdate: cart.lastUpdate ? format(cart.lastUpdate, 'yyyy-MM-dd HH:mm:ss') : null,
  };

  return JSON.stringify(cartWithFormattedDate);
};
